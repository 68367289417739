export const getOlxAccountStatus = (olxAccounts) => {
  const connectedAccountsCount = olxAccounts.length;
  return `${connectedAccountsCount} Connected`;
};

export const getDefaultStatus = (matchedChannel, channel) => {
  return matchedChannel.is_on
    ? "Connected"
    : channel.value === "WpWidget"
    ? "Downloaded"
    : "Off";
};

export const getStatusColor = (matchedChannel, olxAccounts, channel) => {
  if (matchedChannel) {
    return matchedChannel.is_on ||
      (channel.value === "OLXAccount" && !!olxAccounts.length)
      ? "#6cce88"
      : "#d3d407";
  } else {
    return "#3588e9";
  }
};

export const getTelegramAccountStatus = (telegramAccounts) => {
  const connectedAccountsCount = telegramAccounts.length;
  return `${connectedAccountsCount} Connected`;
};
