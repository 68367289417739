import Popup from "reactjs-popup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import supabase from "../../../supabase";
import { clearAltegioToken } from "../../../store/slices/integrationSlice.js";
import { deleteUserAccountByID } from "../../../service/mychatbot.js";
import { ROUTES } from "../../../constants/routes";
import { contentStyle } from "../../../constants/popups.js";
import dangerIcon from "../../../assets/img/danger.png";
import "../../../assets/scss/modal.scss";

export const DeleteAccount = ({ isOpen, setIsOpen, userId }) => {
  const navigate = useNavigate();

  const closeModal = () => {
    setIsOpen(false);
  };

  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      dispatch(clearAltegioToken());
      navigate(ROUTES.loginPage);
    } catch (e) {
      toast.error("Error signing out:", e.message);
    }
  };

  const deleteUserAccount = async () => {
    try {
      await deleteUserAccountByID(userId);
      dispatch(clearAltegioToken());
      await handleLogout();
      localStorage.removeItem("sb-iuaacuguiphtgltstwdm-auth-token");
    } catch (error) {
      console.log("error ", error);
      toast.error(error);
    } finally {
      closeModal();
    }
  };

  return (
    <Popup
      open={isOpen}
      closeOnDocumentClick
      onClose={closeModal}
      {...{
        contentStyle,
      }}
    >
      <div className="modal">
        <span className="close" onClick={closeModal}>
          &times;
        </span>
        <img src={dangerIcon} alt="danger icon" className="dangerIcon" />
        <h2 className="modalTitle">
          Are you sure you want to delete your profile?
        </h2>
        <p className="modalSubtitle">
          This action is irreversible. All your data will be permanently deleted
        </p>
        <div className="btns">
          <button
            type="button"
            className="modalBtn saveBtn"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="modalBtn btnDanger"
            onClick={deleteUserAccount}
          >
            Yes, Delete
          </button>
        </div>
      </div>
    </Popup>
  );
};
