export const KnowledgeBase = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 17.8707V19.5C15.5 21.1569 14.1569 22.5 12.5 22.5H11.5C9.84315 22.5 8.5 21.1569 8.5 19.5V17.8707M15.5 17.8707C15.5 16.4428 16.3338 15.1789 17.2733 14.1037C18.3486 12.873 19 11.2626 19 9.5C19 5.63401 15.866 2.5 12 2.5C8.13401 2.5 5 5.63401 5 9.5C5 11.2626 5.65144 12.873 6.72669 14.1037C7.66618 15.1789 8.5 16.4428 8.5 17.8707M15.5 17.8707H8.5M8 9.5C8 7.29086 9.79086 5.5 12 5.5"
        stroke="#384157"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
