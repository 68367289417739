import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Title } from "../../../../components/Title";
import classNames from "classnames";
import { PersonalInfoBlock } from "../PersonalInfoBlock";
import styles from "./Operators.module.scss";
import profileStyles from "../../Profile.module.scss";
import { FaRegEdit } from "react-icons/fa";
import { isValidPhoneNumber } from "react-phone-number-input";
import {
  addOperator,
  setSelectedOperatorIndex,
  fetchOperators,
} from "../../../../store/slices/operatorsSlice";

export const AddNewOperator = ({ onCancel, accountId, adminUserId }) => {
  const dispatch = useDispatch();

  // form fields
  const [fullName, setFullName] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [emailValue, setEmailValue] = useState("");

  const [formErrors, setFormErrors] = useState({
    fullName: "",
    phone: "",
    email: "",
  });

  const operators = useSelector((state) => state.operators.operators);

  const handleSaveOperator = async () => {
    let errors = { fullName: "", phone: "", email: "" };
    let hasError = false;

    if (!fullName?.trim()) {
      errors.fullName = "Full name is required.";
      hasError = true;
    }

    if (!isValidPhoneNumber(phoneValue)) {
      errors.phone = "Please enter a valid phone number.";
      hasError = true;
    }

    if (!emailValue?.trim()) {
      errors.email = "Email is required.";
      hasError = true;
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(emailValue)) {
        errors.email = "Invalid email format.";
        hasError = true;
      }
    }

    setFormErrors(errors);

    if (hasError) {
      return;
    }

    // Creating an operator
    try {
      await dispatch(
        addOperator({
          accountId,
          adminUserId,
          full_name: fullName,
          phone: phoneValue,
          email: emailValue,
        })
      ).unwrap();

      toast.success("Operator (moderator) created successfully!");
      const newIndex = operators.length;
      dispatch(setSelectedOperatorIndex(newIndex - 1));

      await dispatch(fetchOperators({ accountId, adminUserId }));
      onCancel();
    } catch (error) {
      toast.error(`Failed to create operator: ${error}`);
    }
  };

  return (
    <>
      <div className={profileStyles.titleWrapper}>
        <Title title="Add New Operator" />
      </div>

      <div className={styles.formContainer}>
        <div className={profileStyles.title}>
          <FaRegEdit />
          Personal Information
        </div>
        {/* Information entry fields */}
        <PersonalInfoBlock
          fullName={fullName}
          setFullName={setFullName}
          phoneValue={phoneValue}
          setPhoneValue={setPhoneValue}
          emailValue={emailValue}
          setEmailValue={setEmailValue}
          isEditMode={true}
          isChangePassword={false} // No need to change password when creating
          isOperator={true}
          role="moderator" // Fixed role
          formErrors={formErrors}
          isAdding={true}
        />
      </div>

      <div
        className={classNames(profileStyles.field, profileStyles.btnWrapper)}
      >
        <button className={profileStyles.borderBtn} onClick={onCancel}>
          Cancel
        </button>
        <button
          className={profileStyles.uploadBtn}
          onClick={handleSaveOperator}
        >
          Save changes
        </button>
      </div>
    </>
  );
};
