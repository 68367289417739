import React from "react";
import { components } from "react-select";
import { FaTimes } from "react-icons/fa";
import styles from "./CustomStyles.module.scss";

export const SelectedValueWithRemoveCross = (props) => (
  <components.MultiValue {...props}>
    <span className={styles.selectedItem}>{props.data.label}</span>
  </components.MultiValue>
);
