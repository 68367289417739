import React, { useCallback, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Switch from "react-switch";
import { Typography } from "../../../../components";
import { Loader } from "../../../../components";
import {
  clearAltegioToken,
  selectAvailableIntegrations,
  selectSelectedAssistant,
  selectSelectedIntegrationsIdsByType,
  selectSelectedIntegrationType,
  setSelectedIntegrationType,
  updateIntegration,
} from "../../../../store/slices/integrationSlice";
import styles from "./IntegrationSettings.module.scss";
import {
  AuthConnection,
  KeyConnection,
  ConnectToExistingIntegration,
} from "./components";
import useUpdateAssistantIntegrations from "../../useUpdateAssistantIntegrations";
import { useLoadingContext } from "../../useLoadingContext";
import {
  INTEGRATION_NAMES,
  INTEGRATION_TYPES,
  INTEGRATIONS,
} from "../../../../constants/integrations";
import { useIntegrationChecks } from "./components/useIntegrationChecks";
import { handleCopyLink } from "../../../../helper";
import { MdOutlineContentCopy } from "react-icons/md";
import { useBitrixCodeExchange } from "./components/KeyConnection/useBitrixCodeExchange";
import { ROUTES } from "../../../../constants/routes";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const IntegrationSettings = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const integrationParam = searchParams.get("integration");

  const isKeyCRMIntegrationPage =
    location.pathname === ROUTES.integrationPage &&
    integrationParam === INTEGRATION_NAMES.KEYCRM;

  const user = useSelector((state) => state.auth.user);
  const selectedAssistant = useSelector(selectSelectedAssistant);
  const selectedIntegrationType = useSelector(selectSelectedIntegrationType);
  const availableIntegrations = useSelector(selectAvailableIntegrations);
  const selectedIntegrationsIdsByType = useSelector(
    selectSelectedIntegrationsIdsByType
  );
  const { isBitrix, isAltegio } = useIntegrationChecks();
  const { isLoading, setIsLoading } = useLoadingContext();
  const { removeAssistantIntegration } = useUpdateAssistantIntegrations();

  const keyCrmIntegration = availableIntegrations.find(
    ({ integration }) => integration === INTEGRATION_NAMES.KEYCRM
  );

  // Read integrationName from URL
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const integrationName = params.get("integration");

    if (integrationName) {
      const integrationType = INTEGRATIONS.find(
        (type) => type.name.toLowerCase() === integrationName.toLowerCase()
      );

      if (integrationType) {
        dispatch(setSelectedIntegrationType(integrationType));
      }
    }
  }, [location.search, dispatch]);

  // Update URL when selectedIntegrationType changes
  useEffect(() => {
    if (selectedIntegrationType) {
      const integrationName = selectedIntegrationType.name;
      const params = new URLSearchParams(location.search);
      params.set("integration", integrationName);
      navigate(`${location.pathname}?${params.toString()}`, {
        replace: true,
      });
    }
  }, [selectedIntegrationType, navigate, location.pathname, location.search]);

  // Update the URL when selectedAssistant changes
  useEffect(() => {
    if (selectedAssistant) {
      const params = new URLSearchParams(location.search);

      navigate(`${location.pathname}?${params.toString()}`, { replace: true });
    }
  }, [selectedAssistant, navigate, location.pathname, location.search]);

  // Handle Bitrix code exchange
  useBitrixCodeExchange(user, selectedAssistant);

  const currentTypeAssistantIntegrationId = useMemo(() => {
    return selectedAssistant?.integrations?.find((id) =>
      selectedIntegrationsIdsByType.includes(id)
    );
  }, [selectedAssistant, selectedIntegrationsIdsByType]);

  const isAssistantConnected = useMemo(() => {
    if (
      !selectedAssistant ||
      !selectedIntegrationType ||
      !selectedAssistant?.integrations
    ) {
      return false;
    }

    let isConnected = selectedAssistant?.integrations?.some((integrationId) =>
      selectedIntegrationsIdsByType.includes(integrationId)
    );

    if (isConnected && selectedIntegrationType.name === "Spreadsheet Orders") {
      const matchedIntegration = availableIntegrations.find(
        (integration) =>
          integration.integration === selectedIntegrationType.name &&
          selectedAssistant.integrations.includes(integration.id)
      );

      if (matchedIntegration) {
        isConnected = isConnected && matchedIntegration.sheet_id != null;
      } else {
        isConnected = false;
      }
    }

    return isConnected;
  }, [
    selectedAssistant,
    selectedIntegrationType,
    availableIntegrations,
    selectedIntegrationsIdsByType,
  ]);

  const placeholderText = useMemo(() => {
    if (!selectedAssistant) {
      return "Select assistant to connect";
    }
    if (!selectedIntegrationType) {
      return "Select integration to connect";
    }
    return "";
  }, [selectedAssistant, selectedIntegrationType]);

  const connectionContentByType = useMemo(() => {
    switch (selectedIntegrationType?.authType) {
      case INTEGRATION_TYPES.OAUTH:
        return <AuthConnection />;
      case INTEGRATION_TYPES.API_KEY:
      case INTEGRATION_TYPES.API_REFRESH_TOKEN:
        return <KeyConnection />;
      default:
        return null;
    }
  }, [selectedIntegrationType]);

  const onRemoveAssistantIntegration = useCallback(async () => {
    if (isAssistantConnected) {
      const assistantId = selectedAssistant?.id;
      const integrationId = currentTypeAssistantIntegrationId;

      if (!assistantId || !integrationId) {
        console.error("Invalid assistant or integration ID");
        return;
      }

      setIsLoading(true);
      await removeAssistantIntegration(integrationId, assistantId);
      if (isAltegio) {
        dispatch(clearAltegioToken());
      }
      setIsLoading(false);
    }
  }, [
    isAssistantConnected,
    currentTypeAssistantIntegrationId,
    selectedAssistant,
  ]);

  const handleUpdateIntegrationMetadata = useCallback(
    async (key, value) => {
      if (!keyCrmIntegration || !isKeyCRMIntegrationPage) return;

      try {
        const response = await dispatch(
          updateIntegration({
            ...keyCrmIntegration,
            metadata: {
              ...keyCrmIntegration.metadata,
              [key]: value,
            },
          })
        ).then(unwrapResult);

        if (response?.id) {
          toast.success("Connection updated successfully!");
        }
      } catch (error) {
        toast.error(error.message);
        console.error(error);
      }
    },
    [dispatch, isKeyCRMIntegrationPage, keyCrmIntegration]
  );

  if (isLoading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader height={40} width={40} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {selectedIntegrationType && selectedAssistant ? (
        <div className={styles.contentWrapper}>
          <div className={styles.switchWrapper}>
            <Switch
              onChange={onRemoveAssistantIntegration}
              checked={isAssistantConnected}
              uncheckedIcon={false}
              checkedIcon={false}
              height={27}
              offColor={"#E3E6EE"}
              onColor={"#3588E9"}
              disabled={!isAssistantConnected}
            />
            <Typography variant="caption2">
              Connect assistant to this channel
            </Typography>
          </div>
          {isKeyCRMIntegrationPage && (
            <>
              <div className={styles.switchWrapper}>
                <Switch
                  onChange={() =>
                    handleUpdateIntegrationMetadata(
                      "use_funnels",
                      !Boolean(keyCrmIntegration?.metadata?.use_funnels)
                    )
                  }
                  checked={keyCrmIntegration?.metadata?.use_funnels}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={27}
                  offColor={"#E3E6EE"}
                  onColor={"#3588E9"}
                  disabled={!isAssistantConnected}
                />
                <Typography variant="caption2">
                  Connect funnels to this channel
                </Typography>
              </div>
              <div className={styles.switchWrapper}>
                <Switch
                  onChange={() =>
                    handleUpdateIntegrationMetadata(
                      "use_product_base",
                      !Boolean(keyCrmIntegration?.metadata?.use_product_base)
                    )
                  }
                  checked={keyCrmIntegration?.metadata?.use_product_base}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={27}
                  offColor={"#E3E6EE"}
                  onColor={"#3588E9"}
                  disabled={!isAssistantConnected}
                />
                <Typography variant="caption2">
                  Connect product base to this channel
                </Typography>
              </div>
            </>
          )}
          <div className={styles.settingsConnection}>
            <div className={styles.reconnect}>
              <Typography variant="caption2" className={styles.subtitle}>
                "{selectedIntegrationType.name}" integration
              </Typography>
              {isBitrix && (
                <Typography variant="caption2" className={styles.infoText}>
                  Please create an app at{" "}
                  <a
                    href="https://vendors.bitrix24.com/app/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    vendor.bitrix24.com/app
                  </a>{" "}
                  and set application URL to{" "}
                  <code>
                    https://app.mychatbot.app/integration?integration=Bitrix
                  </code>
                  <MdOutlineContentCopy
                    onClick={() =>
                      handleCopyLink(
                        "https://app.mychatbot.app/integration?integration=Bitrix"
                      )
                    }
                    size={15}
                  />
                  and install your app to your Bitrix portal.
                </Typography>
              )}
              <ConnectToExistingIntegration />
            </div>

            {connectionContentByType}
          </div>
        </div>
      ) : (
        <div className={styles.placeholder}>
          <Typography variant="title">{placeholderText}</Typography>
        </div>
      )}
    </div>
  );
};

export default IntegrationSettings;
