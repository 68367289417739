import styles from "../Operators.module.scss";

export const OperatorMainInfo = ({ selectedOperator }) => {
  const { phone, email, created_at, password } = selectedOperator;

  return (
    <div className={styles.operatorMainInfo}>
      {phone && (
        <div className={styles.infoWrapper}>
          <label>Phone Number</label>
          <div className={styles.info}>{phone}</div>
        </div>
      )}

      {email && (
        <div className={styles.infoWrapper}>
          <label>Email</label>
          <div className={styles.info}>{email}</div>
        </div>
      )}

      {password && (
        <div className={styles.infoWrapper}>
          <label>Password</label>
          <div className={styles.info}>{password}</div>
        </div>
      )}

      {created_at && (
        <div className={styles.infoWrapper}>
          <label>Added On</label>
          <div className={styles.info}>
            {new Date(created_at).toLocaleDateString()}
          </div>
        </div>
      )}
    </div>
  );
};
