import { CHANNELS } from "../constants/channels";

export const formatDate = (dateString) => {
  const date = new Date(dateString);

  const dateOptions = {
    weekday: "short",
    day: "2-digit",
    month: "short",
    year: "numeric",
  };

  const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: false };

  const formattedDate = date.toLocaleString("en-US", dateOptions);
  const formattedTime = date.toLocaleString("en-US", timeOptions);

  return `${formattedDate} ${formattedTime}`;
};

export const getChannelIcon = (value) => {
  const channel = CHANNELS.find((channel) => channel.value === value);
  return channel ? channel.icon : null;
};

export const openInNewTab = (url) => {
  window.open(url, "_blank", "noreferrer");
};

export function formatNumber(num, maxNum) {
  if (num > maxNum) {
    let sum = (num / maxNum).toFixed(1);
    return sum[sum.length - 1] === "0"
      ? sum.slice(0, sum.length - 2) + "m"
      : sum + "m";
  }
  return num.toString();
}

// Remove all spaces
export const sanitizeValue = (value) => {
  return value.replace(/\s+/g, "");
};
