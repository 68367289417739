export const Subscription = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 5.5H19C20.1046 5.5 21 6.39543 21 7.5V10.5M21 10.5V17.5C21 18.6046 20.1046 19.5 19 19.5H10M21 10.5H3M10 19.5L12 21.5M10 19.5L12 17.5M6 19.5H5C3.89543 19.5 3 18.6046 3 17.5V10.5M3 10.5V7.5C3 6.39543 3.89543 5.5 5 5.5H14M14 5.5L12 7.5M14 5.5L12 3.5"
        stroke="#384157"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
