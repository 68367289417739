import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import classNames from "classnames";
import { Title } from "../../components/Title";
import exit from "../../assets/img/exit.png";
import { RenderOperators } from "./components/OperatorsComponents/RenderOperators";
import { RenderMyProfile } from "./components/RenderMyProfile";
import { FaRegUser } from "react-icons/fa";
import { Operator } from "../../assets/icons";
import styles from "./Profile.module.scss";
import { LogOutPopup } from "../../components/popups/LogOut";

import {
  setIsDeleteAccountPopupOpen,
  setIsLogoutPopupOpen,
  setAvatarUrl,
} from "../../store/slices/profileSlice";

export const ProfilePage = () => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const isLogoutPopupOpen = useSelector(
    (state) => state.profile.isLogoutPopupOpen
  );

  const [activeTab, setActiveTab] = useState("myProfile");

  // ref to download file (avatar)
  const fileInputRef = useRef(null);

  const handleLogoutClick = () => {
    dispatch(setIsLogoutPopupOpen(true));
  };

  // Call the Delete Account pop-up
  const showDeleteAccountPopup = () => {
    dispatch(setIsDeleteAccountPopupOpen(true));
  };

  // Upload file (avatar)
  const handleFileChange = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (file.size > 2 * 1024 * 1024) {
      toast.error("File size should be less than 2MB");
      return;
    }
    if (!["image/png", "image/jpeg", "image/jpg"].includes(file.type)) {
      toast.error("Only PNG or JPG allowed");
      return;
    }

    const previewUrl = URL.createObjectURL(file);
    dispatch(setAvatarUrl(previewUrl));
    toast.success("Avatar updated successfully!");
  };

  const renderBlocks = () => {
    switch (activeTab) {
      case "myProfile":
        return (
          <RenderMyProfile
            fileInputRef={fileInputRef}
            showDeleteAccountPopup={showDeleteAccountPopup}
          />
        );
      case "operators":
        return <RenderOperators />;
      default:
        return null;
    }
  };

  return (
    <div className={styles.container}>
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />

      <div className={styles.wrapper}>
        {/* Left panel (tabs) */}
        <div className={styles.profileTab}>
          <div className={styles.profileTitle}>
            <Title title="Account Settings" />
          </div>

          <div className={styles.tabsWrapper}>
            <button
              className={classNames(styles.profileItem, {
                [styles.activeTab]: activeTab === "myProfile",
              })}
              onClick={() => setActiveTab("myProfile")}
            >
              <FaRegUser />
              My Profile
            </button>
            {user?.role !== "moderator" && (
              <button
                className={classNames(styles.profileItem, {
                  [styles.activeTab]: activeTab === "operators",
                })}
                onClick={() => setActiveTab("operators")}
              >
                <Operator />
                Operators
              </button>
            )}
            <button
              className={styles.logout}
              onClick={handleLogoutClick}
              aria-label="Log out"
            >
              <img src={exit} alt="Log Out Icon" />
              Log out
            </button>
          </div>
        </div>

        {/* Right panel (tab content) */}
        <div className={styles.profile}>{renderBlocks()}</div>
      </div>
      <LogOutPopup
        isOpen={isLogoutPopupOpen}
        setIsOpen={(val) => dispatch(setIsLogoutPopupOpen(val))}
      />
    </div>
  );
};

export default ProfilePage;
