import React, { useEffect } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDisableBody,
  toggleDisableBody,
} from "../store/slices/disableBodySlice";

import supabase from "../supabase";
import { loading, login } from "../store/slices/authSlice";
import { setOnboarding } from "../store/slices/onboardingSlice";
import {
  setAvatarUrl,
  setFullName,
  setPhoneValue,
} from "../store/slices/profileSlice";

import { MobileNavigator } from "./components/MobileNavigator";
import { Navigation } from "./components/Navigation";

import styles from "./PrivateRoutes.module.scss";
import { Loader } from "../components/Loader";

import { ROUTES } from "../constants/routes";
import profileWoman from "../assets/img/profile.png";

export const PrivateRoutes = () => {
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.auth.isLogin);
  const step = useSelector((state) => state.onboarding.step);
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();

  // disable body while popup open
  const bodyDisable = useSelector(selectDisableBody);
  useEffect(() => {
    if (bodyDisable) {
      document.body.classList.add("disableBody");
    } else {
      document.body.classList.remove("disableBody");
    }
    const handlePopState = () => {
      dispatch(toggleDisableBody(false));
      document.body.classList.remove("disableBody");
    };
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [bodyDisable, dispatch]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        dispatch(loading(true));
        const {
          data: { user: authUser },
        } = await supabase.auth.getUser();

        if (authUser) {
          const { data: userData, error } = await supabase
            .from("users")
            .select("account_id, role, full_name, phone, avatar_url")
            .eq("id", authUser.id)
            .single();

          if (error) {
            throw error;
          }

          if (!userData) {
            await supabase.from("accounts").insert([{ id: authUser.id }]);
            await supabase
              .from("onboardings")
              .insert([{ account_id: authUser.id, step: 1 }]);

            const newUserObj = {
              ...authUser,
              account_id: authUser.id,
              role: null,
              avatar_url: "",
              full_name: "",
              phone: "",
            };

            if (authUser?.app_metadata?.provider === "email") {
              await supabase.from("users").insert([
                {
                  id: authUser.id,
                  account_id: authUser.id,
                  avatar_url: "",
                  full_name: "",
                  phone: "",
                },
              ]);
            } else {
              await supabase.from("users").insert([
                {
                  id: authUser.id,
                  account_id: authUser.id,
                  avatar_url: authUser.user_metadata.avatar_url || "",
                  full_name: "",
                  phone: "",
                },
              ]);
            }

            // After creating the user, we will get userData again
            const { data: newUserData, error: newUserError } = await supabase
              .from("users")
              .select("account_id, role, avatar_url, full_name, phone")
              .eq("id", authUser.id)
              .single();

            if (newUserError) {
              throw newUserError;
            }

            // merge data authUser + userData
            newUserObj.account_id = newUserData.account_id;
            newUserObj.role = newUserData.role;
            newUserObj.avatar_url = newUserData.avatar_url || "";
            newUserObj.full_name = newUserData.full_name || "";
            newUserObj.phone = newUserData.phone || "";

            dispatch(
              login({
                isLogin: true,
                user: newUserObj,
              })
            );

            // update fields в profileSlice
            dispatch(setAvatarUrl(newUserObj.avatar_url || profileWoman));
            dispatch(setFullName(newUserObj.full_name || ""));
            dispatch(setPhoneValue(newUserObj.phone || ""));
          } else {
            // If the user exists
            const { data: onboarding_user, error: onboardingError } =
              await supabase
                .from("onboardings")
                .select("*")
                .eq("account_id", authUser.id)
                .single();

            if (onboarding_user) {
              dispatch(setOnboarding(onboarding_user.step));
            }

            const fullUserObj = {
              ...authUser,
              account_id: userData.account_id,
              role: userData.role,
              avatar_url: userData.avatar_url || "",
              full_name: userData.full_name || "",
              phone: userData.phone || "",
            };

            dispatch(
              login({
                isLogin: true,
                user: fullUserObj,
              })
            );

            // update fields profileSlice
            dispatch(setAvatarUrl(fullUserObj.avatar_url || profileWoman));
            dispatch(setFullName(fullUserObj.full_name));
            dispatch(setPhoneValue(fullUserObj.phone));
          }
        }
      } catch (error) {
        dispatch(login({ isLogin: false, user: {} }));
        dispatch(loading(false));
      } finally {
        dispatch(loading(false));
      }
    };

    fetchUser();
  }, [dispatch]);

  const isLoading = useSelector((state) => state.auth.isLoading);

  if (isLoading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader height={80} width={80} />
      </div>
    );
  }

  if (user && user.role === "moderator") {
    const isAllowed =
      location.pathname === "/profile" ||
      location.pathname === "/notifications" ||
      location.pathname.startsWith("/chats");

    if (!isAllowed) {
      return <Navigate to="/chats" replace />;
    }
  }

  return isLogin ? (
    <div className={styles.container}>
      <MobileNavigator />
      <Navigation />
      <div className={styles.wrapper}>
        {/* {step !== 6 && <QuickSetUp />} */}
        <Outlet />
      </div>
    </div>
  ) : (
    <Navigate to={ROUTES.loginPage} />
  );
};
