import React from "react";
import { Input, Button, Typography } from "../../../../../components";
import { INTEGRATION_NAMES } from "../../../../../constants/integrations";
import { NoConnection } from "../../../../../components/NoConnection";
import styles from "../IntegrationSettings.module.scss";

const RenderIntegrationService = ({
  selectedIntegrationName,
  fields,
  handleInputChange,
  isAuthTypeApiRefreshToken,
  isLoginDisabled,
  handleLogin,
  isIntegrationConnected,
  filteredIntegration,
}) => {
  switch (selectedIntegrationName) {
    case INTEGRATION_NAMES.KEYCRM:
      return (
        <>
          <Input
            placeholder="API Key"
            inputTitle="API Key"
            value={fields.apiKey || ""}
            onChange={handleInputChange("apiKey")}
          />

          <Input
            placeholder="CRM Pipeline Id"
            inputTitle="CRM Pipeline Id"
            type="number"
            value={fields.crmPipelineId || ""}
            onChange={handleInputChange("crmPipelineId")}
          />
        </>
      );

    case INTEGRATION_NAMES.ALTEGIO:
      return (
        <>
          {!fields.userAccessToken && (
            <Button
              variant="contained"
              title="Login to Altegio"
              onClick={handleLogin}
              disabled={isLoginDisabled()}
              className={styles.actionBtn}
            />
          )}
          <Input
            inputTitle="Partner Token"
            placeholder="Partner Token"
            value={fields.partnerToken || ""}
            onChange={handleInputChange("partnerToken")}
          />

          {/* it should be rendered as Branch ID but field is company_id */}
          <Input
            inputTitle="Branch ID"
            placeholder="Branch ID"
            value={fields.companyId || ""}
            onChange={handleInputChange("companyId")}
          />

          <Input
            inputTitle="Chain ID"
            placeholder="Chain ID"
            value={fields.chainId || ""}
            onChange={handleInputChange("chainId")}
          />

          {/* show userAccessToken & userName after success login */}
          {fields.userAccessToken && (
            <>
              <Input
                inputTitle="User Access Token"
                placeholder="User Access Token"
                value={fields.userAccessToken}
                disabled
              />

              <Input
                inputTitle="Altegio User Name"
                placeholder="User Name"
                value={fields.userName}
                disabled
              />
            </>
          )}
        </>
      );

    case INTEGRATION_NAMES.BITRIX:
      return (
        <>
          <Input
            placeholder="Domain"
            inputTitle="Domain"
            value={fields.domain || ""}
            onChange={handleInputChange("domain")}
            disabled={isIntegrationConnected}
          />

          <Input
            placeholder="Client ID"
            inputTitle="Client ID"
            value={fields.clientId || ""}
            onChange={handleInputChange("clientId")}
            disabled={isIntegrationConnected}
          />

          <Input
            placeholder="Client Secret"
            inputTitle="Client Secret"
            value={fields.clientSecret || ""}
            onChange={handleInputChange("clientSecret")}
            disabled={isIntegrationConnected}
          />

          {fields.accessTokenBitrix &&
            fields.accessTokenBitrix !== "placeholder_access_token" && (
              <Input
                inputTitle="Access Token"
                placeholder="Access Token"
                value={fields.accessTokenBitrix}
                disabled
              />
            )}

          {fields.refreshTokenBitrix &&
            fields.refreshTokenBitrix !== "placeholder_refresh_token" && (
              <Input
                inputTitle="Refresh Token"
                placeholder="Refresh Token"
                value={fields.refreshTokenBitrix}
                disabled
              />
            )}
        </>
      );

    case INTEGRATION_NAMES.WLAUNCH:
      return (
        <>
          <Input
            inputTitle="API Key"
            placeholder="API Key"
            value={fields.apiKey || ""}
            onChange={handleInputChange("apiKey")}
            disabled={filteredIntegration?.refresh_token}
          />
        </>
      );

    case INTEGRATION_NAMES.KOMMO:
      return (
        <>
          <Input
            inputTitle="API Key"
            placeholder="Enter your Kommo API Key"
            value={fields.apiKey || ""}
            onChange={handleInputChange("apiKey")}
            disabled={filteredIntegration?.access_token}
          />
          <Input
            placeholder="Domain"
            inputTitle="Domain"
            value={fields.domain || ""}
            onChange={handleInputChange("domain")}
            disabled={filteredIntegration?.access_token}
          />
        </>
      );

    // add another integrations

    default:
      return (
        <>
          <Input
            inputTitle={isAuthTypeApiRefreshToken ? "Partner Token" : "API Key"}
            placeholder={
              isAuthTypeApiRefreshToken ? "Partner Token" : "API Key"
            }
            value={
              isAuthTypeApiRefreshToken
                ? fields.partnerToken || ""
                : fields.apiKey || ""
            }
            onChange={handleInputChange(
              isAuthTypeApiRefreshToken ? "partnerToken" : "apiKey"
            )}
          />
          {isAuthTypeApiRefreshToken && (
            <>
              <Input
                inputTitle="Refresh Tokenet"
                placeholder="Refresh Token"
                value={fields.refreshToken || ""}
                onChange={handleInputChange("refreshToken")}
              />
            </>
          )}
        </>
      );
  }
};

export default RenderIntegrationService;
