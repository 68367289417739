import React, { useState, useEffect } from "react";
import { Input } from "../../Input";
import { Button } from "../../Button";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { FiEyeOff, FiEye } from "react-icons/fi";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import styles from "./TelegramAccountForm.module.scss";
import { initiateTelegramAuth } from "../../../service/telegramService";
import { Loader } from "../../Loader";
import { sanitizeValue } from "../../../utils";

export const TelegramAccountForm = ({
  assistantId,
  account,
  onWaitCodeNeeded,
}) => {
  const [tgAccountName, setTgAccountName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [iconSize, setIconSize] = useState(window.innerWidth <= 1023 ? 14 : 18);
  const [formDisabled, setFormDisabled] = useState(false);

  const user = useSelector((state) => state.auth.user);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const handleResize = () => {
      setIconSize(window.innerWidth <= 1023 ? 15 : 18);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    setFormDisabled(true);
    try {
      // Call without 'await'
      initiateTelegramAuth({
        tg_account_name: tgAccountName.trim(),
        phone: phone.trim(),
        password: password.trim() || "",
        assistant_id: assistantId,
        account_id: user.account_id,
      });

      // Proceed immediately to polling
      onWaitCodeNeeded({ tgAccountName, phone, password });
    } catch (error) {
      console.error("Error submitting Telegram account:", error);
      toast.error("Error initiating Telegram account authentication.");
      setFormDisabled(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className={styles.loaderContainer}>
          <Loader height={40} width={40} />
        </div>
      ) : (
        <form className={styles.telegramConnection}>
          <div className={styles.inputWrapper}>
            <label htmlFor="accountName">Enter Account Name</label>
            <Input
              label="Account Name"
              value={tgAccountName}
              onChange={(e) => setTgAccountName(sanitizeValue(e.target.value))}
              placeholder="Enter Account Name (no spaces)"
              disabled={formDisabled}
            />
          </div>
          <div className={styles.inputWrapper}>
            <label htmlFor="phone">Enter Phone Number</label>
            <PhoneInput
              international
              defaultCountry="UA"
              value={phone}
              placeholder="Enter Phone Number"
              onChange={(phone) => setPhone(phone)}
              className={styles.phoneInput}
              disabled={formDisabled}
              error={
                phone
                  ? isValidPhoneNumber(phone)
                    ? undefined
                    : "Invalid phone number"
                  : "Phone number required"
              }
            />
          </div>
          <div className={styles.inputWrapper}>
            <label htmlFor="password">Password</label>
            <div
              style={{ position: "relative" }}
              className={styles.inputWrapper}
            >
              <Input
                type={showPassword ? "text" : "password"}
                placeholder={"Enter Password"}
                value={password}
                onChange={(e) => setPassword(sanitizeValue(e.target.value))}
                disabled={formDisabled}
              />
              <i
                onClick={togglePasswordVisibility}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "58%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              >
                {showPassword ? (
                  <FiEye size={iconSize} />
                ) : (
                  <FiEyeOff size={iconSize} />
                )}
              </i>
            </div>
          </div>
          <Button
            variant="contained"
            className={styles.connectButton}
            onClick={handleSubmit}
            disabled={loading || formDisabled}
            title={loading ? "Loading..." : "Continue"}
          />
        </form>
      )}
    </>
  );
};
