import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useSearchParams } from "react-router-dom";
import debounce from "lodash.debounce";
import { toast } from "react-toastify";
import classNames from "classnames";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import supabase from "../../../../../supabase";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../../../store/slices/authSlice";
import {
  fetchInstructions,
  setSelectedAssistants,
  _selectedAssistantId,
  _updateListOfAssistants,
} from "../../../../../store/slices/assistantSlice";
import { useChatContext } from "../../context/ChatContextProvider";
import { Typography } from "../../../../../components";
import styles from "./ChatList.styles.module.scss";
import { Title } from "../../../../../components/Title";
import { SearchBar } from "../../../../../components/SearchBar/SearchBar";
import { ChatTabs } from "../../../../../components/ChatTabs/ChatTabs";
import { ChatListItems } from "./ChatListItems/ChatListItems";
import { FaChevronRight } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa6";
import { FiltersPanel } from "./FiltersPanel/FiltersPanel";
import filterToggle from "../../../../../assets/icons/FilterToggle.png";
import { useScrollRestoration } from "../../../../../hooks/useScrollRestoration";
import { CHANNELS } from "../../../../../constants/channels";

const chatsPerPage = 50;

const ChatList = ({
  isShowClientCard,
  setIsShowClientCard,
  onHasChatsChange,
  onIsCurrentChatInListChange,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { clientId, pageId } = useParams();
  const normalizedPageId = pageId && pageId !== "null" ? pageId : null;
  const { activeTab, setActiveTab } = useChatContext();

  const chatListRef = useRef(null);
  const hasRestoredScroll = useRef(false);
  const isRestoringRef = useRef(false);
  const [didRestore, setDidRestore] = useState(false);

  const channelOptions = useMemo(
    () =>
      CHANNELS.map((channel) => ({
        value: channel.value,
        label: channel.name,
        icon: channel.icon,
        isDisabled: channel.isDisabled || false,
      })),
    []
  );

  // Redux state
  const user = useSelector(selectUser);
  const selectedAssistants = useSelector(
    (state) => state.assistant.selectedAssistants
  );
  const selectedAssistantId = useSelector(_selectedAssistantId);
  const updateListOfAssistants = useSelector(_updateListOfAssistants);

  // Local states
  const [searchValue, setSearchValue] = useState("");
  const [appliedSearchValue, setAppliedSearchValue] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const debouncedSetSearchValue = useCallback(
    debounce((value) => {
      setAppliedSearchValue(value);
    }, 700),
    []
  );

  const handleSearchChange = (value) => {
    setSearchValue(value);
    debouncedSetSearchValue(value);
  };

  useEffect(() => {
    return () => {
      debouncedSetSearchValue.cancel();
    };
  }, [debouncedSetSearchValue]);

  // Channels state
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [appliedChannels, setAppliedChannels] = useState([]);

  // Assistants state
  const [appliedAssistants, setAppliedAssistants] = useState([]);

  // Funnel status state
  const [funnelStatuses, setFunnelStatuses] = useState([]);
  const [selectedFunnelStatuses, setSelectedFunnelStatuses] = useState([]);
  const [appliedFunnelStatuses, setAppliedFunnelStatuses] = useState([]);

  // Time range state
  const [selectedTimeRange, setSelectedTimeRange] = useState(null);
  const [appliedTimeRange, setAppliedTimeRange] = useState(null);

  // Chats state
  const [chats, setChats] = useState([]);
  const [totalChatCount, setTotalChatCount] = useState(0);
  const [unreadCount, setUnreadCount] = useState(0);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  // Additional state
  const [clientFunnelStatusMap, setClientFunnelStatusMap] = useState({});
  const [isShowChatsList, setIsShowChatsList] = useState(false);
  const [shouldNavigateToFirstChat, setShouldNavigateToFirstChat] =
    useState(false);

  const firstRender = useRef(true);

  // Scrolling
  const { restoreScrollPosition, saveScrollPosition } = useScrollRestoration(
    chatListRef,
    "chatScrollPosition"
  );

  const activeCleanAll =
    (appliedAssistants && appliedAssistants.length > 0) ||
    (appliedChannels && appliedChannels.length > 0) ||
    (appliedFunnelStatuses && appliedFunnelStatuses.length > 0) ||
    appliedTimeRange !== null;

  // States for dropdown menus
  const [isAssistantMenuOpen, setIsAssistantMenuOpen] = useState(false);
  const [isFunnelMenuOpen, setIsFunnelMenuOpen] = useState(false);
  const [isTimeRangeMenuOpen, setIsTimeRangeMenuOpen] = useState(false);

  const [isFiltersVisible, setIsFiltersVisible] = useState(false);

  // Creating a ref to save the current state of chats
  const chatsRef = useRef(chats);

  // Handler for toggling filter visibility
  const toggleFilters = () => {
    setIsFiltersVisible((prev) => !prev);
  };

  useEffect(() => {
    if (isRestoringRef.current) return;
    updateFiltersInQuery();
  }, [
    appliedAssistants,
    appliedChannels,
    appliedFunnelStatuses,
    appliedTimeRange,
    appliedSearchValue,
  ]);

  useEffect(() => {
    if (didRestore) return;
    try {
      const assistantsParam = searchParams.get("assistants");
      if (assistantsParam) {
        const aIds = assistantsParam.split(",");
        const newAssts = updateListOfAssistants.filter((a) =>
          aIds.includes(String(a.value))
        );
        setAppliedAssistants(newAssts);
        dispatch(setSelectedAssistants(newAssts));
      } else {
        setAppliedAssistants([]);
        dispatch(setSelectedAssistants([]));
      }

      const channelsParam = searchParams.get("channels");
      if (channelsParam) {
        const cVals = channelsParam.split(",");
        const selected = channelOptions.filter((ch) =>
          cVals.includes(ch.value)
        );
        setAppliedChannels(selected);
        setSelectedChannels(selected);
      } else {
        setAppliedChannels([]);
        setSelectedChannels([]);
      }

      const funnelParam = searchParams.get("funnel");
      if (funnelParam) {
        const fVals = funnelParam.split(",");
        const mapped = fVals.map((fv) => ({ value: fv, label: fv }));
        setAppliedFunnelStatuses(mapped);
        setSelectedFunnelStatuses(mapped);
      } else {
        setAppliedFunnelStatuses([]);
        setSelectedFunnelStatuses([]);
      }

      const timeRangeParam = searchParams.get("timeRange");
      if (timeRangeParam) {
        if (timeRangeParam.startsWith("custom:")) {
          const [, range] = timeRangeParam.split(":");
          const [startDate, endDate] = range.split("_");
          const newTimeRange = { value: "custom", startDate, endDate };
          setAppliedTimeRange(newTimeRange);
          setSelectedTimeRange(newTimeRange);
        } else if (timeRangeParam.endsWith("days")) {
          const days = timeRangeParam.replace("days", "");
          const newTimeRange = { value: days, type: "active" };
          setAppliedTimeRange(newTimeRange);
          setSelectedTimeRange(newTimeRange);
        }
      } else {
        setAppliedTimeRange(null);
        setSelectedTimeRange(null);
      }

      const searchParam = searchParams.get("search");
      if (searchParam) {
        setSearchValue(searchParam);
        setAppliedSearchValue(searchParam);
      } else {
        setSearchValue("");
        setAppliedSearchValue("");
      }
    } finally {
      isRestoringRef.current = false;
    }
  }, []);

  // Assistants Handlers
  const handleApplyAssistants = () => {
    if (isRestoringRef.current) return;

    setAppliedAssistants(selectedAssistants);
    setShouldNavigateToFirstChat(true);
  };

  const handleCleanAssistants = () => {
    dispatch(setSelectedAssistants([]));
    setAppliedAssistants([]);
    setChats([]);
    setPage(1);

    loadChats();
  };

  // Channels Handlers
  const handleSelectChannel = (selectedOptions) => {
    setSelectedChannels(selectedOptions);
  };

  const handleApplyChannels = (allSelected) => {
    if (allSelected === "all") {
      setAppliedChannels([]);
    } else {
      setAppliedChannels(selectedChannels);
    }
  };

  const handleCleanChannels = () => {
    setSelectedChannels([]);
    setAppliedChannels([]);
  };

  // Funnel Status Handlers
  const handleApplyFunnelStatus = () => {
    setAppliedFunnelStatuses(selectedFunnelStatuses);
  };

  const handleCleanFunnelStatuses = () => {
    setSelectedFunnelStatuses([]);
    setAppliedFunnelStatuses([]);
  };

  // Time Range Handlers
  const handleApplyTimeRange = () => {
    if (selectedTimeRange && selectedTimeRange.value !== "custom") {
      setAppliedTimeRange(selectedTimeRange);
    }
  };

  const handleCleanTimeRange = () => {
    setSelectedTimeRange(null);
    setAppliedTimeRange(null);
  };

  // Clean all filters
  const handleCleanAll = () => {
    dispatch(setSelectedAssistants([]));
    setAppliedAssistants([]);

    setSelectedChannels([]);
    setAppliedChannels([]);

    setSelectedFunnelStatuses([]);
    setAppliedFunnelStatuses([]);

    setSelectedTimeRange(null);
    setAppliedTimeRange(null);

    setSearchValue("");
    setShouldNavigateToFirstChat(true);

    setChats([]);
    setPage(1);
    setSearchParams({});
    loadChats();
  };

  useEffect(() => {
    chatsRef.current = chats;
  }, [chats]);

  // Update the existing updateFiltersInQuery function
  function updateFiltersInQuery() {
    const params = new URLSearchParams();

    // Assistants
    if (appliedAssistants.length > 0) {
      const ids = appliedAssistants.map((a) => a.value).join(",");
      params.set("assistants", ids);
    } else {
      params.delete("assistants");
    }

    // Channels
    if (appliedChannels.length > 0) {
      const channels = appliedChannels.map((c) => c.value).join(",");
      params.set("channels", channels);
    } else {
      params.delete("channels");
    }

    // Funnel statuses
    if (appliedFunnelStatuses.length > 0) {
      const statuses = appliedFunnelStatuses.map((f) => f.value).join(",");
      params.set("funnel", statuses);
    } else {
      params.delete("funnel");
    }

    // Time range
    if (appliedTimeRange) {
      if (appliedTimeRange.value === "custom") {
        const { startDate, endDate } = appliedTimeRange;
        params.set("timeRange", `custom:${startDate}_${endDate}`);
      } else {
        params.set("timeRange", `${appliedTimeRange.value}days`);
      }
    } else {
      params.delete("timeRange");
    }

    // Search
    if (appliedSearchValue) {
      params.set("search", appliedSearchValue);
    } else {
      params.delete("search");
    }

    setSearchParams(params, { replace: true });
  }

  const fetchChatsPage = useCallback(
    async (pageNumber) => {
      try {
        let matchingClientIds = null;

        // If there is filtering by funnelStatuses
        if (appliedFunnelStatuses && appliedFunnelStatuses.length > 0) {
          const { data: clientsData, error: clientsError } = await supabase
            .from("clients")
            .select("client_id")
            .in(
              "funnel_status",
              appliedFunnelStatuses.map((status) => status.value)
            )
            //we only take the first 500 customers to avoid generating too long IN(...)
            .range(0, 499);

          if (clientsError) throw clientsError;

          matchingClientIds = clientsData.map((client) => client.client_id);

          // If there are no customers at all, then return an empty list
          if (matchingClientIds.length === 0) {
            return { data: [], count: 0 };
          }
        }

        // Forming a request to "chats"
        let query = supabase
          .from("chats")
          .select("*", { count: "exact" })
          .eq("account_id", user.account_id);

        if (activeTab === 1) {
          query = query.eq("needs_operator", true);
        }

        // Search
        if (appliedSearchValue && appliedSearchValue.trim() !== "") {
          query = query.or(
            `client_name.ilike.*${appliedSearchValue}*,client_username.ilike.*${appliedSearchValue}*,page_id.ilike.*${appliedSearchValue}*`
          );
        }

        // Assistants
        if (appliedAssistants && appliedAssistants.length > 0) {
          query = query.in(
            "assistant_id",
            appliedAssistants.map((a) => a.value)
          );
        }

        // Channels
        if (appliedChannels && appliedChannels.length > 0) {
          query = query.in(
            "communication_channel",
            appliedChannels.map((c) => c.value)
          );
        }

        // funnel_status --> if we get matchingClientIds, we do .in(...)
        if (matchingClientIds) {
          query = query.in("client_id", matchingClientIds);
        }

        // Time range
        if (appliedTimeRange?.value && appliedTimeRange.value !== "custom") {
          const days = parseInt(appliedTimeRange.value, 10);
          if (!isNaN(days)) {
            const cutoffDate = new Date();
            cutoffDate.setDate(cutoffDate.getDate() - days);
            const isoCutoffDate = cutoffDate.toISOString();

            if (appliedTimeRange.type === "inactive") {
              query = query.lte("last_active", isoCutoffDate);
            } else {
              query = query.gte("last_active", isoCutoffDate);
            }
          }
        }

        // Pagination
        query = query
          .order("last_active", { ascending: false })
          .range(
            (pageNumber - 1) * chatsPerPage,
            pageNumber * chatsPerPage - 1
          );

        const { data: chatsData, error: chatsError, count } = await query;

        if (chatsError) {
          if (chatsError.code === "PGRST103") {
            setHasMore(false);
            toast.info("No more chats");
            return { data: [], count: 0 };
          }
          throw chatsError;
        }

        if (!chatsData || chatsData.length === 0) {
          return { data: [], count: 0 };
        }

        // If there are chats, pull up their funnel_status from clients
        const uniqueClientIds = [...new Set(chatsData.map((c) => c.client_id))];
        const { data: cData, error: cError } = await supabase
          .from("clients")
          .select("client_id, funnel_status")
          .in("client_id", uniqueClientIds);

        if (cError) throw cError;

        const funnelStatusMap = cData.reduce((acc, client) => {
          acc[client.client_id] = client.funnel_status;
          return acc;
        }, {});

        const enrichedChats = chatsData.map((chat) => ({
          ...chat,
          funnel_status: funnelStatusMap[chat.client_id] || null,
        }));

        setHasMore(enrichedChats.length === chatsPerPage);
        return { data: enrichedChats, count };
      } catch (error) {
        toast.error("Error fetching chats: " + error.message);
        return { data: [], count: 0 };
      }
    },
    [
      user.account_id,
      activeTab,
      appliedSearchValue,
      appliedAssistants,
      appliedChannels,
      appliedFunnelStatuses,
      appliedTimeRange,
      chatsPerPage,
    ]
  );

  const loadChats = useCallback(async () => {
    const {
      data: fetchedChats,
      count: totalCount,
      hasMore,
    } = await fetchChatsPage(1);

    if (!fetchedChats) {
      return;
    }

    const newChats = fetchedChats
      .filter(
        (fetchedChat) =>
          !chatsRef.current.some(
            (chat) =>
              chat.client_id === fetchedChat.client_id &&
              chat.page_id === fetchedChat.page_id
          )
      )
      .map((chat) => ({
        ...chat,
        funnel_status: clientFunnelStatusMap[chat.client_id] || null,
      }));

    if (newChats.length > 0) {
      // setChats((prevChats) => [...newChats, ...prevChats]);
      setChats(fetchedChats);

      setHasMore(hasMore);
      setUnreadCount(
        newChats.reduce((acc, { unread_count }) => acc + unread_count, 0)
      );
      setTotalChatCount(totalCount);
    }

    if (shouldNavigateToFirstChat && newChats.length > 0) {
      const firstChat = newChats[0];

      if (firstChat.page_id) {
        navigate(`/chats/${firstChat.client_id}/${firstChat.page_id}`, {
          replace: true,
        });
      } else {
        navigate(`/chats/${firstChat.client_id}`, { replace: true });
      }
      setShouldNavigateToFirstChat(false);

      setIsShowClientCard(true);
    }
  }, [
    fetchChatsPage,
    clientFunnelStatusMap,
    shouldNavigateToFirstChat,
    navigate,
  ]);

  useEffect(() => {
    // If the filters from the URL have not yet been restored, we do not load
    if (!didRestore) return;

    // Now we call loadChats() — with exactly the right applied* states.
    setPage(1);
    setChats([]);
    loadChats();
  }, [
    didRestore,
    activeTab,
    appliedAssistants,
    appliedChannels,
    appliedFunnelStatuses,
    appliedTimeRange,
    appliedSearchValue,
  ]);

  useEffect(() => {
    const fetchClientsAndFunnelStatuses = async () => {
      try {
        const { data: clientsData, error } = await supabase
          .from("clients")
          .select("client_id, funnel_status")
          .eq("account_id", user.account_id);

        if (error) throw error;

        const funnelStatusMap = {};
        clientsData.forEach((client) => {
          funnelStatusMap[client.client_id] = client.funnel_status;
        });
        setClientFunnelStatusMap(funnelStatusMap);

        const uniqueStatuses = [
          ...new Set(clientsData.map((c) => c.funnel_status).filter(Boolean)),
        ];

        setFunnelStatuses(
          uniqueStatuses.map((status) => ({
            value: status,
            label: status,
          }))
        );
      } catch (error) {
        toast.error("Error fetching clients: " + error.message);
      }
    };

    fetchClientsAndFunnelStatuses();
  }, [user.account_id]);

  // Update funnel_status for existing chats when changing clientFunnelStatusMap
  useEffect(() => {
    setChats((prevChats) =>
      prevChats.map((chat) => ({
        ...chat,
        funnel_status:
          clientFunnelStatusMap[chat.client_id] || chat.funnel_status,
      }))
    );
  }, [clientFunnelStatusMap]);

  // Refresh chats every 60 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      loadChats();
    }, 60000); // 60 seconds

    return () => clearInterval(intervalId);
  }, [loadChats]);

  const handleClickChat = async (chatPartner) => {
    try {
      if (chatPartner.unread_count) {
        const { error } = await supabase
          .from("chats")
          .update({ unread_count: 0 })
          .match({ id: chatPartner.id });

        setChats((prevChats) =>
          prevChats.map((chat) =>
            chat.id === chatPartner.id ? { ...chat, unread_count: 0 } : chat
          )
        );

        setUnreadCount((prevCount) => prevCount - chatPartner.unread_count);
      }

      setIsShowChatsList(false);

      if (chatPartner.page_id) {
        navigate(`/chats/${chatPartner.client_id}/${chatPartner.page_id}`);
      } else {
        navigate(`/chats/${chatPartner.client_id}`);
      }

      // setIsShowClientCard(true);
    } catch (error) {
      toast.error("Unexpected error: " + error.message);
    }
  };

  const handleLoadMore = useCallback(async () => {
    const nextPage = page + 1;
    const { data: moreChats, count: totalCount } = await fetchChatsPage(
      nextPage
    );

    if (moreChats.length > 0) {
      const updatedMoreChats = moreChats.map((chat) => ({
        ...chat,
        funnel_status: clientFunnelStatusMap[chat.client_id] || null,
      }));

      setChats((prev) => [...prev, ...updatedMoreChats]);
      setPage(nextPage);
      setUnreadCount(
        (prevCount) =>
          prevCount +
          updatedMoreChats.reduce(
            (acc, { unread_count }) => acc + unread_count,
            0
          )
      );
      setTotalChatCount(totalCount);
    }
  }, [fetchChatsPage, page, clientFunnelStatusMap]);

  const handleScroll = useCallback(
    debounce(() => {
      if (
        chatListRef.current &&
        chatListRef.current.scrollTop + chatListRef.current.clientHeight >=
          chatListRef.current.scrollHeight - 50
      ) {
        handleLoadMore(); // Upload new data
      }
      saveScrollPosition();
    }, 200),
    [handleLoadMore, saveScrollPosition]
  );

  useEffect(() => {
    if (chatListRef.current && !hasRestoredScroll.current) {
      restoreScrollPosition();
      hasRestoredScroll.current = true;
    }
  }, [chats, restoreScrollPosition]);

  const getColor = (id) => {
    return updateListOfAssistants.find(({ value }) => value === id)?.bgColor;
  };

  const unread_needs_operator = useMemo(
    () => chats?.filter((el) => el.needs_operator && el.unread_count).length,
    [chats]
  );

  useEffect(() => {
    if (chats && chats.length > 0) {
      setUnreadCount(
        chats.reduce((acc, { unread_count }) => acc + unread_count, 0)
      );

      if (shouldNavigateToFirstChat) {
        const firstChat = chats[0];

        if (firstChat.page_id) {
          navigate(`/chats/${firstChat.client_id}/${firstChat.page_id}`, {
            replace: true,
          });
        } else {
          navigate(`/chats/${firstChat.client_id}`, { replace: true });
        }
        setShouldNavigateToFirstChat(false);
      } else if (!clientId && firstRender.current && pathname === "/chats") {
        const firstChat = chats[0];

        if (firstChat.page_id) {
          navigate(`/chats/${firstChat.client_id}/${firstChat.page_id}`);
        } else {
          navigate(`/chats/${firstChat.client_id}`);
        }
        firstRender.current = false;
      }
    }
  }, [
    chats,
    clientId,
    normalizedPageId,
    pathname,
    selectedAssistantId,
    navigate,
    shouldNavigateToFirstChat,
  ]);

  useEffect(() => {
    dispatch(fetchInstructions(user.account_id));
  }, [dispatch, user.account_id]);

  const handleMarkAllRead = async () => {
    try {
      const { error } = await supabase
        .from("chats")
        .update({ unread_count: 0 })
        .match({ account_id: user.account_id });

      if (error) {
        toast.error("Error marking all as read: " + error.message);
        return;
      }

      setChats((prevChats) =>
        prevChats.map((chat) => ({ ...chat, unread_count: 0 }))
      );
      setUnreadCount(0);
    } catch (error) {
      console.log("Unexpected error: " + error.message);
    }
  };

  const showMoreButton =
    !appliedSearchValue &&
    appliedFunnelStatuses.length === 0 &&
    chats.length > 0 &&
    chats.length < totalChatCount;

  const isCurrentChatInList = useMemo(() => {
    if (!clientId) return false;
    return chats.some((chat) => {
      if (normalizedPageId) {
        return (
          String(chat.client_id) === String(clientId) &&
          String(chat.page_id) === String(normalizedPageId)
        );
      } else {
        return String(chat.client_id) === String(clientId) && !chat.page_id;
      }
    });
  }, [chats, clientId, normalizedPageId]);

  useEffect(() => {
    onHasChatsChange(chats.length > 0);
    onIsCurrentChatInListChange(isCurrentChatInList);
  }, [
    chats,
    onHasChatsChange,
    onIsCurrentChatInListChange,
    isCurrentChatInList,
  ]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const renderIcon = () => {
    if (windowWidth >= 667) {
      return <FaArrowLeft size={20} />;
    } else {
      return <FaChevronRight size={30} />;
    }
  };

  const filterState = {
    appliedAssistants,
    appliedChannels,
    appliedFunnelStatuses,
    appliedTimeRange,
    selectedAssistants,
    selectedChannels,
    selectedFunnelStatuses,
    selectedTimeRange,
    funnelStatuses,
    updateListOfAssistants,
    isAssistantMenuOpen,
    isFunnelMenuOpen,
    isTimeRangeMenuOpen,
  };

  const filterActions = {
    setSelectedAssistants,
    setSelectedChannels,
    setSelectedFunnelStatuses,
    setSelectedTimeRange,
    setIsAssistantMenuOpen,
    setIsFunnelMenuOpen,
    setIsTimeRangeMenuOpen,
    handleApplyAssistants,
    handleCleanAssistants,
    handleApplyChannels,
    handleCleanChannels,
    handleApplyFunnelStatus,
    handleCleanFunnelStatuses,
    handleApplyTimeRange,
    handleCleanTimeRange,
    dispatch,
  };

  useEffect(() => {
    setPage(1);
    setChats([]);
    loadChats();
  }, [
    activeTab,
    appliedSearchValue,
    appliedAssistants,
    appliedChannels,
    appliedFunnelStatuses,
    appliedTimeRange,
  ]);

  return (
    <div className={styles.chatContainer}>
      <div
        className={classNames(
          styles.accountChats,
          isShowChatsList && styles.rotated
        )}
      >
        <Title title="Active chats" />

        <div className={styles.chatHeader}>
          <div className={styles.searchBarContainer}>
            <SearchBar
              searchValue={searchValue}
              setSearchValue={handleSearchChange}
              placeholder="Search chats"
            />
            <button
              className={classNames(styles.filtersToggle, {
                [styles.filterOpened]: !isFiltersVisible,
              })}
              onClick={toggleFilters}
            >
              <img
                src={filterToggle}
                alt="FilterToggle"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = filterToggle;
                }}
              />
            </button>
          </div>
          <div
            className={classNames(styles.filtersWrapper, {
              [styles.hidden]: !isFiltersVisible,
            })}
          >
            <div className={styles.filters}>
              <Typography>Select options for quick search</Typography>
              <button
                className={styles.cleanAllBtn}
                onClick={handleCleanAll}
                disabled={!activeCleanAll}
              >
                Clear all
              </button>
            </div>

            <FiltersPanel
              filterState={filterState}
              filterActions={filterActions}
            />
          </div>
        </div>

        <ChatTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          unreadCount={unreadCount}
          handleMarkAllRead={handleMarkAllRead}
          unreadNeedsOperator={unread_needs_operator}
        />
        <div
          className={styles.chatList}
          ref={chatListRef}
          onScroll={handleScroll}
        >
          <ChatListItems
            chats={chats}
            handleClickChat={handleClickChat}
            getColor={getColor}
            showMoreButton={showMoreButton}
            handleLoadMore={handleLoadMore}
            clientId={clientId}
            pageId={normalizedPageId}
            chatListRef={chatListRef}
          />
        </div>
      </div>

      {chats.length > 0 && (
        <div className={styles.toggle}>
          <button
            className={classNames(
              styles.btn,
              isShowChatsList && styles.rotated
            )}
            onClick={() => setIsShowChatsList(!isShowChatsList)}
          >
            {renderIcon()}
          </button>
        </div>
      )}

      {(!clientId || !isCurrentChatInList) && chats?.length > 0 && (
        <div className={styles.preloadMessageList}>
          <p>Please choose any chat</p>
        </div>
      )}

      {!isCurrentChatInList && chats?.length === 0 && (
        <div className={styles.preloadMessageList}>
          <p>Please clear all filters to continue</p>
        </div>
      )}
    </div>
  );
};

export default ChatList;
