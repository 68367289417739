import React from "react";
import { useSelector } from "react-redux";
import styles from "./MessageItem.styles.module.scss";
import { processMessageContent } from "../../../../helpers";
import { Manager, Viber } from "../../../../../../../assets/icons";
import { FaBookOpen } from "react-icons/fa";
import { formatDate } from "../../../../../../../utils";
import Knowledge from "../Knowledge";
import classNames from "classnames";
import { MdToHTML } from "../../../../../../../components/MdToHTML";
import { ReplyMessage } from "../ReplyMessage/ReplyMessage";
import { MediaRenderer } from "../MediaRenderer/MediaRenderer";
import "video.js/dist/video-js.css";
import assistant from "../../../../../../../assets/img/assis.png";
import { MessageRenderer } from "../../../../../../../components/MessageRenderer/MessageRenderer";
import defaultImage from "../../../../../../../assets/img/defaultImg.png";

const MessageItem = ({
  knowledgeHints,
  message,
  isSending,
  handleClickSpecMessageSave,
  handleChangeSpecMessageTextarea,
  handleClickSpecMessageIcon,
  collapseStates,
  onToggleCollapse,
  bottomListRef,
}) => {
  const { content, images, instagramStoryLink, media, postContent } =
    processMessageContent(message);

  const isDebugMode = useSelector((state) => state.debug.isDebugMode);

  // Define regex patterns
  const managerPrefixPattern = /^Manager\[(.*?)\]:\s*/;
  const viberPrefix = "Viber: ";

  // Match manager prefix
  const managerMatch = content.match(managerPrefixPattern);
  const isManagerMessage = managerMatch !== null;
  const managerFullName = isManagerMessage ? managerMatch[1] : null;

  // Check for Viber prefix
  const isViberStr = content.startsWith(viberPrefix);

  // Clean up the content by removing prefixes
  let cleanedContent = content;
  if (isManagerMessage) {
    cleanedContent = cleanedContent.replace(managerPrefixPattern, "").trim();
  } else if (isViberStr) {
    cleanedContent = cleanedContent.substring(viberPrefix.length).trim();
  }

  // Extract reply message if present
  let replyToMessage = null;
  if (message?.MultiContent) {
    const replyItem = message.MultiContent.find(
      (item) =>
        item.type === "text" && item.text && item.text.includes("Reply to:")
    );
    if (replyItem) {
      const replyText = replyItem.text.split("Reply to: ")[1].trim();
      replyToMessage = replyText;
      cleanedContent = cleanedContent.replace(replyItem.text, "").trim();
    }
  } else {
    const replyToIndex = cleanedContent.indexOf("Reply to:");
    if (replyToIndex !== -1) {
      replyToMessage = cleanedContent.substring(replyToIndex + 9).trim();
      cleanedContent = cleanedContent.substring(0, replyToIndex).trim();
    }
  }

  const playerRef = React.useRef(null);

  const handlePlayerReady = (player) => {
    playerRef.current = player;
  };

  return (
    <div className={styles.containerMessage}>
      <div className={`${styles.message} ${styles[message.role]}`}>
        {/* If this is a reply to another message */}
        {replyToMessage && <ReplyMessage originalMessage={replyToMessage} />}

        {/* Display Viber icon if message is from Viber */}
        {isViberStr && (
          <div className={styles.sentVia}>
            <Viber />
          </div>
        )}

        {/* Actual text content */}
        {
          <div className={`${styles.contentWrapper} ${styles[message.role]}`}>
            {message.role === "assistant" && (
              <div className={isDebugMode ? styles.isDebugModeOn : ""}>
                {/* Display Manager icon and full name if message is from Manager */}
                {isManagerMessage && (
                  <div className={styles.managerNameWrapper}>
                    <Manager />
                    <span className={styles.managerName}>
                      {managerFullName}
                    </span>
                  </div>
                )}

                {!message.function_call && (
                  <img
                    src={assistant}
                    className={styles.assastantAvatar}
                    alt="assistant"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = assistant;
                    }}
                  />
                )}

                <MessageRenderer
                  message={{
                    ...message,
                    content: cleanedContent,
                  }}
                  index={message.id}
                  collapseStates={collapseStates}
                  onToggleCollapse={onToggleCollapse}
                  bottomListRef={bottomListRef}
                />
              </div>
            )}
            {message.role === "function" && (
              <div className={isDebugMode ? styles.isDebugModeOn : ""}>
                <MessageRenderer
                  message={{
                    ...message,
                    content: cleanedContent,
                  }}
                  index={message.id}
                  collapseStates={collapseStates}
                  onToggleCollapse={onToggleCollapse}
                  bottomListRef={bottomListRef}
                />
              </div>
            )}
            {message.role === "user" && <MdToHTML content={cleanedContent} />}
          </div>
        }

        {/* Instagram story or "postContent" if any */}
        {instagramStoryLink && (
          <iframe
            alt=""
            src={instagramStoryLink}
            style={{ height: "300px" }}
            frameBorder="0"
            rel="instagram story"
            title="instagram story"
          ></iframe>
        )}
        {postContent && (
          <div className={styles.postContent}>
            <p>{postContent}</p>
          </div>
        )}

        {/* Possibly images or media attachments */}
        {images?.length > 0 &&
          images?.map((image, index) => (
            <img
              key={index}
              alt=""
              src={image}
              onLoad={(e) => {
                const { naturalWidth, naturalHeight } = e.target;
                if (naturalWidth === 1 && naturalHeight === 1) {
                  e.target.style.display = "none";
                }
              }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping

                currentTarget.src = defaultImage;
              }}
              style={{
                marginTop: "3px",
                maxHeight: "450px",
                maxWidth: "415px",
                objectFit: "contain",
                background: "#e5e5ea",
              }}
              target="_blank"
              rel="image"
            />
          ))}

        {media && media.length > 0 && (
          <div className={styles.mediaContainer}>
            {media.map((mediaUrl, index) => {
              const videoJsOptions = {
                autoplay: false,
                controls: true,
                responsive: true,
                fluid: true,
                sources: [
                  {
                    src: mediaUrl,
                    type: "video/mp4",
                  },
                ],
              };

              return (
                <div key={index} className={styles.mediaWrapper}>
                  <MediaRenderer
                    options={videoJsOptions}
                    onReady={handlePlayerReady}
                  />
                </div>
              );
            })}
          </div>
        )}

        {/* Book icon to "improve knowledge" */}
        <FaBookOpen
          size={24}
          className={styles.specMessageIcon}
          title="Click to improve answer"
          onClick={() => handleClickSpecMessageIcon(message.id)}
        />

        {/* Date/time */}
        {!message.name && !message.function_call && (
          <div className={classNames(styles.messageDate, styles[message.role])}>
            {formatDate(message?.created_at)}
          </div>
        )}
      </div>

      {/* If user wants to add knowledge about the message */}
      <Knowledge
        knowledgeHints={knowledgeHints}
        message={message}
        isSending={isSending}
        handleClickSpecMessageSave={handleClickSpecMessageSave}
        handleChangeSpecMessageTextarea={handleChangeSpecMessageTextarea}
      />
    </div>
  );
};

export default MessageItem;
