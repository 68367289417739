export const Channels = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 10.5V7.5M12 10.5C13.6568 10.5 15 11.8431 15 13.5C15 14.2456 14.728 14.9277 14.2778 15.4524M12 10.5C10.3431 10.5 8.99998 11.8431 8.99998 13.5C8.99998 14.2456 9.27199 14.9277 9.72217 15.4524M12 7.5C13.1046 7.5 14 6.60457 14 5.5C14 4.39543 13.1046 3.5 12 3.5C10.8954 3.5 9.99998 4.39543 9.99998 5.5C9.99998 6.60457 10.8954 7.5 12 7.5ZM14.2778 15.4524L17.4814 18.1984M14.2778 15.4524C13.7276 16.0937 12.9112 16.5 12 16.5C11.0887 16.5 10.2724 16.0937 9.72217 15.4524M17.4814 18.1984C17.8482 17.7709 18.3925 17.5 19 17.5C20.1046 17.5 21 18.3954 21 19.5C21 20.6046 20.1046 21.5 19 21.5C17.8954 21.5 17 20.6046 17 19.5C17 19.0029 17.1813 18.5482 17.4814 18.1984ZM9.72217 15.4524L6.51853 18.1984M6.51853 18.1984C6.81865 18.5482 6.99998 19.0029 6.99998 19.5C6.99998 20.6046 6.10455 21.5 4.99998 21.5C3.89542 21.5 2.99998 20.6046 2.99998 19.5C2.99998 18.3954 3.89542 17.5 4.99998 17.5C5.60748 17.5 6.15172 17.7709 6.51853 18.1984Z"
        stroke="#384157"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};
