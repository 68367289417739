// LogOutPopup.js
import React from "react";
import Popup from "reactjs-popup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearAltegioToken } from "../../../store/slices/integrationSlice.js";
import { ROUTES } from "../../../constants/routes";
import { contentStyle } from "../../../constants/popups.js";
import dangerIcon from "../../../assets/img/danger.png";
import supabase from "../../../supabase";
import "../../../assets/scss/modal.scss";

export const LogOutPopup = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      dispatch(clearAltegioToken());
      navigate(ROUTES.loginPage);
    } catch (e) {
      toast.error("Error signing out:", e.message);
    }
  };

  return (
    <Popup
      open={isOpen}
      closeOnDocumentClick
      onClose={closeModal}
      {...{ contentStyle }}
    >
      <div className="modal">
        <span className="close" onClick={closeModal}>
          &times;
        </span>
        <img src={dangerIcon} alt="danger icon" className="dangerIcon" />
        <h2 className="modalTitle">Are you sure you want to log out?</h2>

        <div className="btns">
          <button
            type="button"
            className="modalBtn saveBtn"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="modalBtn btnDanger"
            onClick={handleLogout}
          >
            Log out
          </button>
        </div>
      </div>
    </Popup>
  );
};
