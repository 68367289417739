import React from "react";
import Popup from "reactjs-popup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import supabase from "../../../supabase";
import { contentStyle } from "../../../constants/popups.js";
import island from "../../../assets/img/island.png";
import "../../../assets/scss/modal.scss";

export const RemoveProfileAvatar = ({
  isOpen,
  setIsOpen,
  handleRemoveAvatar,
}) => {
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <Popup
      open={isOpen}
      closeOnDocumentClick
      onClose={closeModal}
      {...{ contentStyle }}
    >
      <div className="modal">
        <span className="close" onClick={closeModal}>
          &times;
        </span>

        <img src={island} alt="island icon" className="removeProfilePicture" />
        <h2 className="modalTitle">
          Are you sure you want to remove your profile picture?
        </h2>

        <div className="btns">
          <button
            type="button"
            className="modalBtn saveBtn"
            onClick={closeModal}
          >
            Cancel
          </button>

          <button
            type="button"
            className="modalBtn btnDanger"
            onClick={() => {
              handleRemoveAvatar();
              closeModal();
            }}
          >
            Yes, Delete
          </button>
        </div>
      </div>
    </Popup>
  );
};
