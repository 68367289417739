import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import supabase from "../../../../supabase";

import { Input } from "../../../../components/Input";
import { Button } from "../../../../components";
import { FiPlus, FiMinus } from "react-icons/fi";

import styles from "./ReplayDelayInput.module.scss";

export const ReplayDelayInput = ({
  channelName,
  channel,
  user,
  assistantId,
  onUpdate,
  fetchChannels,
  disabled = false,
}) => {
  const MIN_VALUE = 5;
  const MAX_VALUE = 180;

  const [isLoading, setIsLoading] = useState(false);
  const [replyDelay, setReplayDelay] = useState("");
  const [replyDelayError, setReplayDelayError] = useState("");

  const handleUpdateValue = (e) => {
    e.preventDefault();
    const value = Number.parseInt(e.target.value);
    if (Number.isNaN()) {
      setReplayDelayError("Should be number");

      return;
    }

    if (value < MIN_VALUE && value > MAX_VALUE) {
      setReplayDelay(replyDelay);
      return;
    }

    setReplayDelay(value);
  };

  const handleIncrease = () => {
    console.log(replyDelay);
    if (replyDelay + 1 > MAX_VALUE) {
      return;
    }

    setReplayDelay(replyDelay + 1);
  };

  const handleDecrease = () => {
    console.log(replyDelay);
    if (replyDelay - 1 < MIN_VALUE) {
      return;
    }

    setReplayDelay(replyDelay - 1);
  };

  const handleChangeReplayDelay = useCallback(async () => {
    try {
      setIsLoading(true);
      setReplayDelayError("");

      if (Number.isNaN(replyDelay)) {
        setReplayDelayError("Must be a number");
        return;
      }

      if (replyDelay < MIN_VALUE && replyDelay > MAX_VALUE) {
        return;
      }

      const { error } = await supabase
        .from("channels")
        .update({
          metadata: {
            ...channel?.metadata, // Keep existing metadata values
            reply_delay: replyDelay, // Set the new reply_delay value
          },
        })
        .match({
          account_id: user.account_id,
          communication_channel: channelName,
          ...(assistantId && { assistant_id: assistantId }),
        });

      if (error) {
        setReplayDelayError("Failed to turn off channel");
      }

      if (onUpdate) onUpdate(replyDelay);

      toast.success("Saved successful");
    } catch (error) {
      toast.error(error);
    } finally {
      if (fetchChannels) {
        fetchChannels();
      }

      setIsLoading(false);
    }
  }, [
    channelName,
    fetchChannels,
    channel,
    user,
    assistantId,
    onUpdate,
    fetchChannels,
    replyDelay,
    replyDelayError,
  ]);

  useEffect(() => {
    setReplayDelay(channel?.metadata?.reply_delay ?? 30);
    setReplayDelayError("");
  }, [channel, user]);

  return (
    <div className={styles.replayDelayInputWrapper}>
      <label
        htmlFor="replyDelayControl"
        className={styles.replayDelayInputLabel}
      >
        Reply Delay (s)
      </label>

      <div className={styles.replayDelayInputControlWrapper}>
        <Button
          variant="contained"
          disabled={disabled || isLoading}
          className={styles.replayDelayInputControlBtn}
          title={<FiMinus size={18} />}
          onClick={handleDecrease}
        />

        <Input
          id="replyDelayControl"
          disabled={disabled || isLoading}
          type="number"
          value={replyDelay}
          onChange={handleUpdateValue}
          error={!!replyDelayError}
          errorText={replyDelayError}
          step={1}
          customClass={styles.replayDelayInput}
        />

        <Button
          variant="contained"
          disabled={disabled || isLoading}
          className={styles.replayDelayInputControlBtn}
          title={<FiPlus size={18} />}
          onClick={handleIncrease}
        />
      </div>

      <Button
        disabled={
          disabled ||
          replyDelay < MIN_VALUE ||
          replyDelay > MAX_VALUE ||
          isLoading ||
          channel?.metadata?.reply_delay === replyDelay
        }
        variant="contained"
        className={styles.replayDelayInputSaveBtn}
        onClick={handleChangeReplayDelay}
        title={isLoading ? "Uploading" : "Save"}
      />
    </div>
  );
};
