import {
  Channels,
  Chats,
  Configurations,
  Dashboard,
  Helpdesk,
  Integrations,
  Subscription,
  Support,
  KnowledgeBase,
  FollowUp,
  ReferralProgram,
} from "../assets/icons";
import { ROUTES } from "../constants/routes";
import Notifications from "../routes/components/Navigation/Notifications/Notifications";

export const navigationTabs = [
  {
    to: ROUTES.dashboardPage,
    icon: <Dashboard />,
    label: "Dashboard",
    roles: ["admin"],
  },
  {
    to: ROUTES.aiConfigurationPage,
    icon: <Configurations />,
    label: "AI Configurations",
    roles: ["admin"],
  },
  {
    to: ROUTES.chatsPage,
    icon: <Chats />,
    label: "Active chats",
    roles: ["admin", "moderator"],
  },
  {
    to: ROUTES.channelsPage,
    icon: <Channels />,
    label: "Channels",
    roles: ["admin"],
  },
  {
    to: ROUTES.integrationPage,
    icon: <Integrations />,
    label: "Integrations",
    roles: ["admin"],
  },
  {
    to: ROUTES.knowledgeBasePage,
    icon: <KnowledgeBase />,
    label: "Knowledge base",
    roles: ["admin"],
  },
  {
    to: ROUTES.followUpPage,
    icon: <FollowUp />,
    label: "Follow ups",
    roles: ["admin"],
  },
  {
    to: ROUTES.notifications,
    icon: <Notifications />,
    label: "",
    roles: ["admin", "moderator"],
  },
];

export const navigationTabsBottom = [
  {
    to: ROUTES.subscriptionPage,
    icon: <Subscription />,
    label: "Subscription",
    roles: ["admin"],
  },
  {
    to: ROUTES.referralProgramPage,
    icon: <ReferralProgram />,
    label: "Referral program",
    roles: ["admin"],
  },
];
