export const TOOL_TYPE = {
  CMS: "cms",
  CRM: "crm",
};

export const customSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    boxSizing: "border-box",
    padding: "0px",
    background: "transparent",
    border: "transparent",
    borderRadius: "none",
    outline: "none",
    border: state.isFocused ? "none" : "none",
    boxShadow: state.isFocused ? "none" : "none",
    cursor: "pointer",

    "&:hover": {
      border: "none",
    },
  }),
  container: (provided) => ({
    width: "100%",
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: "1.25rem",
    top: "92%",
    width: "100%",
    right: "0",
    padding: "1.5rem",
    borderRadius: "16px",
    border: "1px solid #E3E6EE",
    boxShadow: "2px 2px 25px 0px rgba(0, 0, 0, 0.05)",
  }),
  option: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    width: "auto",
    padding: "5px",
    marginBottom: "1.75rem",
    marginTop: "1.75rem",
    backgroundColor: "#ffffff",
    color: "#384157",
    fontFamily: "Montserrat",

    "&:first-child": {
      marginTop: "0rem",
    },

    "&[aria-disabled='true']": {
      opacity: "0.5",
      pointerEvents: "none",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: window.innerWidth < 768 ? "1.2rem" : "1.3rem",
    fontWeight: "500",
    color: "#384157",
    fontFamily: "Montserrat",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: "1.5rem",
  }),
  multiValue: (provided) => ({
    ...provided,
    fontSize: "1.5rem",
    backgroundColor: "#a7abf9",
    borderRadius: "8px",
    marginRight: "4px",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#ffffff",
    cursor: "pointer",
    "&:hover": {
      color: "#ff0000",
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "5px",
  }),
  indicatorSeparator: () => null,
};

export const LOCALES = {
  EN: "en",
  UA: "ua",
  RU: "ru",
};
