import { IMAGE_URL_REGEX } from "../../../pages/ReferralProgramPage/const";
import styles from "./MessageImage.module.css";
import defaultImage from "../../../assets/img/defaultImg.png";

const MessageImage = ({ message }) => {
  const images = message.match(IMAGE_URL_REGEX);

  if (!images) return null;

  return (
    <div className={styles.imageContainer}>
      {images.map((image, index) => (
        <img
          key={index}
          alt=""
          src={image}
          onLoad={(e) => {
            const { naturalWidth, naturalHeight } = e.target;
            if (naturalWidth === 1 && naturalHeight === 1) {
              e.target.style.display = "none";
            }
          }}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = defaultImage;
          }}
          className={styles.messageImage}
        />
      ))}
    </div>
  );
};

export default MessageImage;
