import { useState, useEffect } from "react";
import { toast } from "react-toastify";

import TimePicker from "../../TimePicker/ClockFace/TimePicker.jsx";

import { useDispatch, useSelector } from "react-redux";
import {
  toggleStartClockOpen,
  toggleEndClockOpen,
  selectStartClockOpen,
  selectEndClockOpen,
} from "../../../store/slices/timePickerSlice.js";
import { toggleDisableBody } from "../../../store/slices/disableBodySlice.js";

import { useTimezoneSelect, allTimezones } from "react-timezone-select";
import Popup from "reactjs-popup";
import { Input } from "../../Input";
import { DAY_OF_THE_WEEK } from "../../../constants/configuration.js";
import { getData, updateData } from "../../../service/supabase.js";
import { timeOfTimezone } from "../../../constants/configuration.js";
import { contentStyle } from "../../../constants/popups.js";

import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import "../../../assets/scss/modal.scss";
import "./PickADay.modale.scss";

const labelStyle = "original";
const timezones = {
  ...allTimezones,
  "Europe/Kiev": "Kiev",
};

const EMPTY_WEEK_SCHEDULE = {
  days: {
    Monday: { start: null, end: null },
    Tuesday: { start: null, end: null },
    Wednesday: { start: null, end: null },
    Thursday: { start: null, end: null },
    Friday: { start: null, end: null },
    Saturday: { start: null, end: null },
    Sunday: { start: null, end: null },
  },
  timezone: "Europe/Helsinki",
};

const DEFAULT_TIMEZONE = {
  value: "Europe/Helsinki",
  label: "(GMT+3:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
  offset: 3,
  abbrev: "EEST",
  altName: "Eastern European Summer Time",
};

export const PickADay = ({
  setPickADay,
  userId,
  isPickADay,
  assistantId,
  onSubmit = null,
  defaultWeekSchedule,
} = {}) => {
  const dispatch = useDispatch();

  const isStartCLockOpen = useSelector(selectStartClockOpen);
  const isEndCLockOpen = useSelector(selectEndClockOpen);

  const openStartClock = () => {
    dispatch(toggleStartClockOpen(true));
  };
  const openEndClock = () => {
    dispatch(toggleEndClockOpen(true));
  };

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const [selectedDay, setSelectedDay] = useState({ value: 1, label: "Monday" });
  const [weekSchedule, setWeekSchedule] = useState(
    defaultWeekSchedule ?? EMPTY_WEEK_SCHEDULE
  );
  const [startWeekSchedule, setStartWeekSchedule] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const [timezone, setTimezone] = useState(
    defaultWeekSchedule?.timezone
      ? {
          value: defaultWeekSchedule.timezone,
          label: timezones[defaultWeekSchedule.timezone],
        }
      : DEFAULT_TIMEZONE
  );

  const { options } = useTimezoneSelect({
    labelStyle,
    timezones,
  });

  useEffect(() => {
    if (!assistantId || !userId) return;

    getData("assistants", "*", "account_id", userId)
      .then(({ data }) => {
        if (!data || !data.length) return;

        const botData = data?.find(({ id }) => id === assistantId);

        if (!botData) return;

        const botTime = botData?.bot_time;

        if (!botTime) {
          setWeekSchedule(EMPTY_WEEK_SCHEDULE);
          setStartWeekSchedule(EMPTY_WEEK_SCHEDULE);

          setStartTime(null);
          setEndTime(null);
        } else {
          if (typeof botTime.days[selectedDay.label] === "undefined") return;
          setWeekSchedule(botTime);
          setStartWeekSchedule(botTime);
          setStartTime(botTime.days[selectedDay.label]?.start);
          setEndTime(botTime.days[selectedDay.label]?.end);

          if (botTime.timezone) {
            const r = options.find(({ value }) => value === botTime.timezone);
            if (!r) return;
            setTimezone(r);
          }
        }
      })
      .catch((r) => {
        toast.error(r);
      });
  }, [assistantId, userId]);

  useEffect(() => {
    const flag =
      JSON.stringify(startWeekSchedule) === JSON.stringify(weekSchedule);
    setDisabled(flag);
  }, [weekSchedule, startWeekSchedule]);

  const handleDayChange = (day) => {
    setSelectedDay(day);

    const dayData = weekSchedule.days[day.label];
    if (!dayData) {
      setStartTime(null);
      setEndTime(null);
    } else {
      setStartTime(dayData.start);
      setEndTime(dayData.end);
    }
  };

  const updateWeekSchedule = (key, value) => {
    setWeekSchedule((prevSchedule) => ({
      ...prevSchedule,
      days: {
        ...prevSchedule.days,
        [selectedDay.label]: {
          ...prevSchedule.days[selectedDay.label],
          [key]: value,
        },
      },
    }));
  };

  const handleStartTimeChange = (value) => {
    setStartTime(value);
    updateWeekSchedule("start", value);
  };

  const handleEndTimeChange = (value) => {
    setEndTime(value);
    updateWeekSchedule("end", value);
  };

  const getTimezone = (selectedOption) => {
    setTimezone(selectedOption);
    setWeekSchedule((prevSchedule) => ({
      ...prevSchedule,
      timezone: selectedOption.value,
    }));
  };

  const closeModal = () => {
    dispatch(toggleDisableBody(false));
    dispatch(toggleStartClockOpen(false));
    dispatch(toggleEndClockOpen(false));
    setPickADay(false);
  };

  const closeButtonStart = () => {
    dispatch(toggleStartClockOpen(false));
  };

  const closeButtonEnd = () => {
    dispatch(toggleEndClockOpen(false));
  };

  const onClick = async () => {
    if (onSubmit) {
      onSubmit(weekSchedule);
      closeModal();
      return;
    }
    try {
      await updateData(
        "assistants",
        { bot_time: weekSchedule },
        { account_id: userId, id: assistantId }
      );
      setStartWeekSchedule(weekSchedule);
    } catch (error) {
      console.log(error);
    }
    dispatch(toggleDisableBody(false));
    dispatch(toggleStartClockOpen(false));
    dispatch(toggleEndClockOpen(false));
  };

  return (
    <Popup
      open={isPickADay}
      closeOnDocumentClick={false}
      onClose={closeModal}
      {...{
        contentStyle,
      }}
    >
      <div className="modal">
        <div className="row">
          <Input
            typeInput={"select"}
            placeholder={"Select day"}
            options={DAY_OF_THE_WEEK}
            value={selectedDay}
            onChange={(selectedOption) => handleDayChange(selectedOption)}
          />
        </div>
        <div className="row" style={{ alignItems: "center" }}>
          <TimePicker
            time={startTime}
            onClick={openStartClock}
            isOpen={isStartCLockOpen}
            onChange={handleStartTimeChange}
            closeButton={closeButtonStart}
            type="startClock"
          />
          <TimePicker
            time={endTime}
            onClick={openEndClock}
            isOpen={isEndCLockOpen}
            onChange={handleEndTimeChange}
            closeButton={closeButtonEnd}
            type="endClock"
          />
        </div>
        <div className="row">
          <Input
            typeInput={"select"}
            placeholder={"Select timezone"}
            options={options}
            value={timezone}
            onChange={(selectedOption) => getTimezone(selectedOption)}
          />
        </div>
        <div className="row">
          <button type="button" className="modalBtn" onClick={closeModal}>
            Close
          </button>
          <button
            type="button"
            onClick={onClick}
            className="modalBtn saveBtn"
            disabled={disabled}
          >
            Save
          </button>
        </div>
      </div>
    </Popup>
  );
};
