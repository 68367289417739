export const FollowUp = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 15.5H7.55573C10.5859 15.5 13.356 13.788 14.7111 11.0777L15.5 9.5M15.5 9.5L17 12M15.5 9.5H12.5M7 3.5H17C19.2091 3.5 21 5.29086 21 7.5V17.5C21 19.7091 19.2091 21.5 17 21.5H7C4.79086 21.5 3 19.7091 3 17.5V7.5C3 5.29086 4.79086 3.5 7 3.5Z"
        stroke="#384157"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
