import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const aiConfigurationApi = createApi({
  reducerPath: "aiConfigurationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: "include",
  }),
  // turn on automatic re-fetch
  refetchOnFocus: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    optimize: builder.mutation({
      query: (body) => ({
        url: "/optimizeprompt",
        method: "POST",
        credentials: "include",
        body,
      }),
    }),
    messages: builder.query({
      query: ({ id, assistantId }) => ({
        url: `/testchat?account_id=${id}&assistant_id=${assistantId}`,
        method: "GET",
        credentials: "include",
      }),
    }),
    sendMessage: builder.mutation({
      query: (body) => ({
        url: "/testchat",
        method: "POST",
        credentials: "include",
        body,
      }),
    }),
    clearChat: builder.mutation({
      query: ({ id, assistantId }) => ({
        url: `/testchat?account_id=${id}&assistant_id=${assistantId}`,
        method: "DELETE",
        credentials: "include",
      }),
    }),
  }),
});

export const {
  useOptimizeMutation,
  useMessagesQuery,
  useSendMessageMutation,
  useClearChatMutation,
} = aiConfigurationApi;
