import React, { useRef, useEffect } from "react";
import styles from "./QuickCommentReplyInput.module.scss";

export const QuickCommentReplyInput = ({ value, onChange, placeholder }) => {
  const textareaRef = useRef(null);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [value]);

  return (
    <div className={styles.quickCommentReply}>
      <label htmlFor="quickCommentReply">Quick comment reply</label>
      <textarea
        id="quickCommentReply"
        ref={textareaRef}
        value={value}
        onChange={(e) => {
          onChange(e);
          adjustTextareaHeight();
        }}
        placeholder={placeholder}
        rows={2}
      />
    </div>
  );
};
