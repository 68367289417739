import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { FaRegEdit } from "react-icons/fa";
import { IoShieldOutline } from "react-icons/io5";
import { Title } from "../../../components/Title";
import { Button } from "../../../components/Button";
import { Typography } from "../../../components/Typography/Typography";
import { PersonalInfoBlock } from "./PersonalInfoBlock";
import { RenderSecurityBlock } from "./RenderSecurityBlock";
import { DeleteAccount } from "../../../components/popups/DeleteAccount";
import { RemoveProfileAvatar } from "../../../components/popups/RemoveProfileAvatar";
import profileWoman from "../../../assets/img/profile.png";
import { toast } from "react-toastify";
import styles from "../Profile.module.scss";
import supabase from "../../../supabase";

import {
  setIsRemoveAvatarPopupOpen,
  setIsEditMode,
  setFullName,
  setPhoneValue,
  setEmailValue,
  setIsChangePassword,
  setCurrentPassword,
  setNewPassword,
  setConfirmNewPassword,
  setPasswordErrors,
  uploadAvatar,
  setIsDeleteAccountPopupOpen,
  setAvatarUrl,
  updateUserProfile,
} from "../../../store/slices/profileSlice";

import { useWindowWidth } from "../../../hooks/useWindowWidth";

export const RenderMyProfile = ({ fileInputRef, showDeleteAccountPopup }) => {
  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();
  const {
    isRemoveAvatarPopupOpen,
    isEditMode,
    fullName,
    phoneValue,
    emailValue,
    isChangePassword,
    currentPassword,
    newPassword,
    confirmNewPassword,
    passwordErrors,
    isUploadingAvatar,
    uploadAvatarError,
    isDeleteAccountPopupOpen,
  } = useSelector((state) => state.profile);

  const user = useSelector((state) => state.auth.user);
  const { avatarUrl } = useSelector((state) => state.profile);
  const { role, account_id, full_name, phone, email } = user || {};

  useEffect(() => {
    if (!isEditMode && user) {
      dispatch(setFullName(full_name || ""));
      dispatch(setPhoneValue(phone || ""));
      dispatch(setEmailValue(email || ""));
      dispatch(setIsChangePassword(false));
    }
  }, [isEditMode, user, dispatch]);

  useEffect(() => {
    // Reset form fields when changing user (e.g. after logging in)
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setPasswordErrors({});
    setIsChangePassword(false);
  }, [user]);

  const handleClickUpload = () => {
    fileInputRef?.current?.click();
  };

  const handleEdit = () => {
    dispatch(setIsEditMode(true));
    dispatch(setIsChangePassword(false));
  };

  const handleCancelEdit = () => {
    dispatch(setIsEditMode(false));

    dispatch(setFullName(full_name || ""));
    dispatch(setPhoneValue(phone || ""));
    dispatch(setEmailValue(email || ""));
    dispatch(setNewPassword(""));
    dispatch(setConfirmNewPassword(""));
    dispatch(setPasswordErrors(""));
  };

  const handleSaveChanges = async () => {
    try {
      const accountId = user?.id;
      await dispatch(
        updateUserProfile({
          accountId,
          newFullName: fullName,
          newPhone: phoneValue,
        })
      ).unwrap();

      toast.success("Changes saved successfully!");
      dispatch(setIsEditMode(false));
    } catch (err) {
      toast.error(`Failed to save changes: ${err}`);
    }
  };

  const handleAvatarUpload = async (file) => {
    try {
      await dispatch(uploadAvatar({ clientId: user?.id, file })).unwrap();
      toast.success("Avatar uploaded successfully!");
    } catch (error) {
      toast.error(`Failed to upload avatar: ${error}`);
    }
  };

  const rowWidth = windowWidth < 667 ? "100%" : "49%";

  return (
    <>
      <div className={styles.titleWrapper}>
        <Title title="My Profile" />
      </div>

      <div className={styles.profileInfo}>
        {/* avatar block */}
        <div className={styles.infoWrapper}>
          <div className={styles.profilePicture}>
            <img
              src={avatarUrl || profileWoman}
              className={styles.profileAvatar}
              alt="profile"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = profileWoman;
              }}
            />
            <div className={styles.avatarInstruction}>
              <Typography variant="h3">Profile picture</Typography>
              <Typography variant="body4" color={"#7D8AAB;"}>
                PNG or JPG under 10MB
              </Typography>
            </div>
          </div>
          <div className={styles.avatarAction}>
            <Button
              variant="light"
              title="Delete"
              className={styles.borderBtn}
              onClick={() => dispatch(setIsRemoveAvatarPopupOpen(true))}
            />
            <Button
              title="Upload new picture"
              className={styles.uploadBtn}
              onClick={handleClickUpload}
              disabled={isUploadingAvatar}
            />
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (!file) return;

                if (file.size > 10 * 1024 * 1024) {
                  toast.error("File size should be less than 10MB");
                  return;
                }
                if (
                  !["image/png", "image/jpeg", "image/jpg"].includes(file.type)
                ) {
                  toast.error("Only PNG or JPG allowed");
                  return;
                }

                handleAvatarUpload(file);
              }}
            />
          </div>
        </div>

        {uploadAvatarError && (
          <div className={styles.uploadError}>
            <Typography variant="body4" color={"red"}>
              {uploadAvatarError}
            </Typography>
          </div>
        )}

        {/* personal info block */}
        <div className={styles.infoWrapper}>
          <div className={styles.title}>
            <FaRegEdit />
            Personal Information
          </div>
          <div className={styles.editButton}>
            {isEditMode ? (
              <div className={classNames(styles.field, styles.btnWrapper)}>
                <button className={styles.borderBtn} onClick={handleCancelEdit}>
                  Cancel
                </button>
                <button
                  className={styles.uploadBtn}
                  onClick={handleSaveChanges}
                >
                  Save changes
                </button>
              </div>
            ) : (
              <button className={styles.borderBtn} onClick={handleEdit}>
                Edit <FaRegEdit className={styles.icon} />
              </button>
            )}
          </div>
        </div>

        <PersonalInfoBlock
          fullName={fullName}
          setFullName={(val) => dispatch(setFullName(val))}
          phoneValue={phoneValue}
          setPhoneValue={(val) => dispatch(setPhoneValue(val))}
          emailValue={emailValue}
          setEmailValue={(val) => dispatch(setEmailValue(val))}
          isEditMode={isEditMode}
          isChangePassword={isChangePassword}
          isOperator={false}
          role={role}
        />

        {/* Security Block */}
        {user?.role !== "moderator" && (
          <>
            <div className={styles.infoWrapper}>
              <div className={styles.title}>
                <IoShieldOutline />
                Security
              </div>
            </div>
            <RenderSecurityBlock
              showCurrentPassword={true}
              isChangePassword={isChangePassword}
              currentPassword={currentPassword}
              setCurrentPassword={(val) => dispatch(setCurrentPassword(val))}
              newPassword={newPassword}
              setNewPassword={(val) => dispatch(setNewPassword(val))}
              confirmNewPassword={confirmNewPassword}
              setConfirmNewPassword={(val) =>
                dispatch(setConfirmNewPassword(val))
              }
              handleCancelChangePassword={() => {
                dispatch(setIsChangePassword(false));
                dispatch(setCurrentPassword(""));
                dispatch(setNewPassword(""));
                dispatch(setConfirmNewPassword(""));
              }}
              handleChangePasswordClick={() => {
                dispatch(setIsChangePassword(true));
                dispatch(setIsEditMode(false));
              }}
              passwordErrors={passwordErrors}
              isOperator={false}
            />
          </>
        )}
      </div>

      {/* Popups */}
      <DeleteAccount
        isOpen={isDeleteAccountPopupOpen}
        setIsOpen={(val) => dispatch(setIsDeleteAccountPopupOpen(val))}
        userId={account_id}
      />

      <RemoveProfileAvatar
        isOpen={isRemoveAvatarPopupOpen}
        setIsOpen={(val) => dispatch(setIsRemoveAvatarPopupOpen(val))}
        userId={account_id}
        handleRemoveAvatar={async () => {
          try {
            // upd avatar to default in Supabase
            const { error } = await supabase
              .from("users")
              .update({ avatar_url: "" }) // here can use path to default avatar
              .eq("id", user?.id)
              .single();

            if (error) {
              throw error;
            }

            dispatch(setAvatarUrl(profileWoman));
            toast.info("Avatar has been reset to default.");
          } catch (error) {
            toast.error(`Failed to remove avatar: ${error.message}`);
          }
        }}
      />

      {/* Delete Account Block */}
      {role !== "moderator" && (
        <>
          <div className={styles.infoWrapper}>
            <div className={styles.title}>Delete Account</div>
          </div>
          <div className={styles.details}>
            <div className={styles.row} style={{ width: rowWidth }}>
              <div className={styles.field}>
                <Typography variant="body4" color={"#7D8AAB;"}>
                  Permanently delete your account and all associated data. This
                  cannot be undone.
                </Typography>
                <button
                  type="button"
                  className={styles.btnDelete}
                  onClick={showDeleteAccountPopup}
                >
                  Delete Account
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
