export const Chats = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 16.4424H7.48063C7.17684 16.4424 6.88953 16.5799 6.69976 16.8161L3.89043 20.3122C3.59518 20.6796 3 20.4718 3 20.0012V5.49115C3 4.39147 3.89543 3.5 5 3.5H19C20.1046 3.5 21 4.39147 21 5.49115V10.5M14.5 14H19.5C20.3284 14 21 14.6657 21 15.4868V21.0036C21 21.3994 20.5549 21.6355 20.2227 21.4159L18.5038 20.2801C18.1753 20.0631 17.7893 19.9472 17.3944 19.9472H14.5C13.6716 19.9472 13 19.2815 13 18.4604V15.4868C13 14.6657 13.6716 14 14.5 14Z"
        stroke="#384157"
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
      />
    </svg>
  );
};
