export const ReferralProgram = ({ width = 22, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 14V19.5C3 20.6046 3.89543 21.5 5 21.5H19C20.1046 21.5 21 20.6046 21 19.5V14M3 14V11.5C3 10.3954 3.89543 9.5 5 9.5H7M3 14H21M21 14V11.5C21 10.3954 20.1046 9.5 19 9.5H17M12 9.5H7M12 9.5C12 5.5 9.5 3.5 7 3.5C5.5 3.5 4 4.5 4 6.5C4 8 5 9.5 7 9.5M12 9.5C12 5.5 14.5 3.5 17 3.5C18.5 3.5 20 4.5 20 6.5C20 8 19 9.5 17 9.5M12 9.5H17M12 9.5L10 11.5M12 9.5L14 11.5M6 18H7.5M11 18H15.5"
        stroke="#384157"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
