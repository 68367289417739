import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchOperators = createAsyncThunk(
  "operators/fetchOperators",
  async ({ accountId, adminUserId }, { rejectWithValue }) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/moderators/${accountId}?user=${adminUserId}`;
      const res = await fetch(url);
      const data = await res.json();

      if (!res.ok) {
        return rejectWithValue(data.error || "Failed to fetch moderators");
      }

      return data;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to fetch moderators");
    }
  }
);

export const addOperator = createAsyncThunk(
  "operators/addOperator",
  async (
    { accountId, adminUserId, full_name, phone, email },
    { rejectWithValue }
  ) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/moderators/${accountId}?user=${adminUserId}`;

      const body = {
        role: "moderator", // fixed role
        full_name,
        phone,
        email,
      };

      const res = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });

      const data = await res.json();

      if (!res.ok) {
        return rejectWithValue(data.error || "Failed to create moderator");
      }

      return data;
    } catch (err) {
      return rejectWithValue(err.message || "Failed to create moderator");
    }
  }
);

export const deleteOperator = createAsyncThunk(
  "operators/deleteOperator",
  async ({ accountId, moderator_id, userId }, { rejectWithValue }) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/moderators/${accountId}/${moderator_id}?user=${userId}`;
      const res = await fetch(url, {
        method: "DELETE",
      });

      if (res.status === 200) {
        return moderator_id;
      }

      const data = await res.json();
      return rejectWithValue(data.error || "Failed to delete moderator");
    } catch (error) {
      return rejectWithValue(error.message || "Failed to delete moderator");
    }
  }
);

export const updateOperator = createAsyncThunk(
  "operators/updateOperator",
  async (
    { accountId, moderator_id, full_name, phone, userId },
    { rejectWithValue }
  ) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/moderators/${accountId}/${moderator_id}?user=${userId}`;
      const body = {
        full_name,
        phone,
      };

      const res = await fetch(url, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });

      if (res.status === 200) {
        const data = await res.json();
        return data;
      }

      const data = await res.json();
      return rejectWithValue(data.error || "Failed to update moderator");
    } catch (error) {
      return rejectWithValue(error.message || "Failed to update moderator");
    }
  }
);

// Slice
const operatorsSlice = createSlice({
  name: "operators",
  initialState: {
    operators: [],
    selectedOperatorIndex: null,
    isEditMode: false,
    status: "idle",
    error: null,
    editForm: {
      full_name: "",
      phone: "",
      email: "",
    },
  },
  reducers: {
    setSelectedOperatorIndex(state, action) {
      state.selectedOperatorIndex = action.payload;
    },
    setIsEditMode(state, action) {
      state.isEditMode = action.payload;
      if (!action.payload) {
        state.editForm = { full_name: "", phone: "", email: "" };
      }
    },
    setEditFormField(state, action) {
      const { field, value } = action.payload;
      state.editForm[field] = value;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Operators
      .addCase(fetchOperators.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOperators.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.operators = action.payload;
      })
      .addCase(fetchOperators.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // Add Operator
      .addCase(addOperator.fulfilled, (state, action) => {
        state.operators.push(action.payload);
      })
      .addCase(addOperator.rejected, (state, action) => {
        state.error = action.payload;
      })
      // Delete Operator
      .addCase(deleteOperator.fulfilled, (state, action) => {
        state.operators = state.operators.filter(
          (op) => op.id !== action.payload
        );
        if (state.selectedOperatorIndex !== null) {
          if (state.selectedOperatorIndex >= state.operators.length) {
            state.selectedOperatorIndex = state.operators.length - 1;
          }
        }
      })
      .addCase(deleteOperator.rejected, (state, action) => {
        state.error = action.payload;
      })
      // Update Operator
      .addCase(updateOperator.fulfilled, (state, action) => {
        const updatedOperator = action.payload;
        state.operators = state.operators.map((op) =>
          op.id === updatedOperator.id ? updatedOperator : op
        );
      })
      .addCase(updateOperator.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const { setSelectedOperatorIndex, setIsEditMode, setEditFormField } =
  operatorsSlice.actions;
export default operatorsSlice.reducer;
