import profileWoman from "../../../../../assets/img/profile.png";
import { FaRegEdit } from "react-icons/fa";
import styles from "../Operators.module.scss";

export const DetailAvatarBlock = ({
  selectedOperator,
  onEditOperator,
  isEditMode,
}) => {
  const { avatarUrl, role, full_name } = selectedOperator;
  return (
    <div className={styles.detailAvatarBlock}>
      <div className={styles.operatorItem}>
        <img
          src={avatarUrl || profileWoman}
          alt="operator avatar"
          width={40}
          height={40}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = profileWoman;
          }}
        />
        <div className={styles.operatorInfo}>
          <span className={styles.operatorName}>{full_name}</span>
          <span className={styles.operatorRole}>{role}</span>
        </div>
      </div>

      {!isEditMode && (
        <button className={styles.editButton} onClick={onEditOperator}>
          Edit <FaRegEdit className={styles.icon} />
        </button>
      )}
    </div>
  );
};
