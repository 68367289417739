import React from "react";
import { components } from "react-select";
import { FaCheck } from "react-icons/fa";
import styles from "./CustomStyles.module.scss";

export const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <div className={styles.customOption}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        <label>{props.label}</label>
      </div>
    </components.Option>
  );
};
