export const Bell = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.10113 6.27523C9.03516 6.02794 9 5.76808 9 5.5C9 3.84315 10.3431 2.5 12 2.5C13.6569 2.5 15 3.84315 15 5.5C15 5.76808 14.9648 6.02794 14.8989 6.27523M9.10113 6.27523C9.93938 5.78429 10.9337 5.5 12 5.5C13.0663 5.5 14.0606 5.78429 14.8989 6.27523M9.10113 6.27523C9.03243 6.31546 8.96478 6.35708 8.89823 6.40005C7.49804 7.30399 6.58234 8.8033 6.58234 10.5V11.1393C6.58234 12.6918 6.19069 14.2229 5.43842 15.6115L4.11579 18.0528C3.75557 18.7177 4.27945 19.5 5.08493 19.5H9M14.8989 6.27523C14.9676 6.31546 15.0352 6.35708 15.1018 6.40005C16.502 7.30399 17.4177 8.8033 17.4177 10.5V11.1393C17.4177 12.6918 17.8093 14.2229 18.5616 15.6115L19.8842 18.0528C20.2444 18.7177 19.7205 19.5 18.9151 19.5H15M15 19.5C15 21.1569 13.6569 22.5 12 22.5C10.3431 22.5 9 21.1569 9 19.5M15 19.5H9"
        stroke="#384157"
        strokeWidth="2"
      />
    </svg>
  );
};
