// src/components/ChatList/ChatTabs.jsx
import React from "react";
import classNames from "classnames";
import styles from "./ChatTabs.module.scss";

export const ChatTabs = ({
  activeTab,
  setActiveTab,
  unreadCount,
  handleMarkAllRead,
  unreadNeedsOperator,
}) => {
  return (
    <div className={styles.tabs}>
      <div className={classNames(styles.tab, activeTab === 0 && styles.active)}>
        <button onClick={() => setActiveTab(0)}>All chats</button>
        {!!unreadCount && (
          <button className={styles.tabCount} onClick={handleMarkAllRead}>
            <span className={styles.unreadCount}>{unreadCount}</span>
            <span className={styles.tooltip}>Read All</span>
          </button>
        )}
      </div>
      <div className={styles.operator}>
        <button
          className={classNames(styles.tab, activeTab === 1 && styles.active)}
          onClick={() => setActiveTab(1)}
        >
          {!!unreadNeedsOperator && (
            <div className={styles.tabCount}>{unreadNeedsOperator}</div>
          )}
          Need operator
        </button>
      </div>
    </div>
  );
};
