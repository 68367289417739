import React from "react";
import styles from "./SearchBar.module.scss";
import search from "../../assets/img/search.png";

export const SearchBar = ({ searchValue, setSearchValue, placeholder }) => {
  return (
    <div className={styles.searchWrapper}>
      <input
        className={styles.searchInput}
        placeholder={placeholder}
        value={searchValue}
        onChange={({ target: { value } }) => setSearchValue(value)}
      />
      <div className={styles.searchIcon}>
        <img src={search} alt="Search Icon" />
      </div>
    </div>
  );
};
