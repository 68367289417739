import Popup from "reactjs-popup";
import { toast } from "react-toastify";
import { contentStyle } from "../../../constants/popups.js";
import dangerIcon from "../../../assets/img/danger.png";
import "../../../assets/scss/modal.scss";
import { HiOutlineTrash } from "react-icons/hi2";

export const DeleteOperatorAccount = ({ isOpen, setIsOpen, onDelete }) => {
  const closeModal = () => {
    setIsOpen(false);
  };

  const handleDeleteClick = async () => {
    try {
      await onDelete();
    } catch (error) {
      console.log("error ", error);
      toast.error(error);
    } finally {
      closeModal();
    }
  };

  return (
    <Popup
      open={isOpen}
      closeOnDocumentClick
      onClose={closeModal}
      {...{
        contentStyle,
      }}
    >
      <div className="modal">
        <span className="close" onClick={closeModal}>
          &times;
        </span>
        <img src={dangerIcon} alt="danger icon" className="dangerIcon" />
        <h2 className="modalTitle">Are you sure you want to delete account?</h2>
        <div className="btns">
          <button
            type="button"
            className="modalBtn saveBtn"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="modalBtn btnDanger"
            onClick={handleDeleteClick}
          >
            Delete
            <HiOutlineTrash className="icon" size={18} />
          </button>
        </div>
      </div>
    </Popup>
  );
};
