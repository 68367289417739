import React, { useRef, useState, useEffect } from "react";
import styles from "./AssistantFunctionCallMessage.module.scss";
import { TbFunction } from "react-icons/tb";

export const AssistantFunctionCallMessage = ({
  message,
  index,
  collapseStates,
  onToggleCollapse,
}) => {
  const callCollapseKey = `msgIndex_${index}_function_call`;
  const isExpanded = Boolean(collapseStates[callCollapseKey]);
  const { name, arguments: args } = message.function_call || {};
  const bodyRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState("0px");

  useEffect(() => {
    if (isExpanded) {
      requestAnimationFrame(() => {
        if (bodyRef.current) {
          setMaxHeight(`${bodyRef.current.scrollHeight}px`);
        }
      });
    } else {
      setMaxHeight("0px");
    }
  }, [isExpanded]);

  if (!message.function_call) return null;

  return (
    <div className={styles.functionCallContainer}>
      <div
        className={styles.functionCallHeader}
        onClick={() => onToggleCollapse(callCollapseKey)}
      >
        <span className={styles.functionCallLabel}>
          <TbFunction size={18} /> Call
        </span>
        <span className={styles.functionCallName}>{name}</span>
        <button className={styles.collapseButton}>
          {isExpanded ? <span>🔽</span> : <span>🔼</span>}
        </button>
      </div>
      <div
        className={styles.functionCallBody}
        style={{
          maxHeight: maxHeight,
        }}
        ref={bodyRef}
      >
        <p className={styles.jsonBox}>
          {JSON.stringify(JSON.parse(args), null, 2)}
        </p>
      </div>
    </div>
  );
};
