import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Typography } from "../../../../components";
import { setSelectedAssistantId } from "../../../../store/slices/integrationSlice";
import { selectSelectedAssistant } from "../../../../store/slices/integrationSlice";
import { selectListOfAssistants } from "../../../../store/slices/assistantSlice";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";

import styles from "./IntegrationHeader.module.scss";

const IntegrationHeader = ({ subscriptionPlan }) => {
  const dispatch = useDispatch();
  const listOfAssistants = useSelector(selectListOfAssistants);
  const selectedAssistant = useSelector(selectSelectedAssistant);

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const assistantIdParam = params.get("assistantId");

  const assistantsOptions = useMemo(() => {
    return listOfAssistants?.map(({ id, bot_name }) => ({
      value: id,
      label: bot_name,
    }));
  }, [listOfAssistants]);

  useEffect(() => {
    if (!selectedAssistant && listOfAssistants.length) {
      dispatch(setSelectedAssistantId(listOfAssistants[0]?.id));
    }
  }, [listOfAssistants, selectedAssistant, dispatch]);

  const onAssistantChange = useCallback(
    (selectedAssistant) => {
      dispatch(setSelectedAssistantId(selectedAssistant.value));
    },
    [listOfAssistants, dispatch]
  );

  const pageTitle = useMemo(() => {
    let baseTitle = "Integrations";
    if (selectedAssistant) {
      baseTitle = `${baseTitle} for ${selectedAssistant.bot_name}`;
    }
    return baseTitle;
  }, [selectedAssistant]);

  const selectedAssistantValue = useMemo(() => {
    return {
      value: selectedAssistant?.id,
      label: selectedAssistant?.bot_name,
    };
  }, [selectedAssistant]);

  const disabledAssistantSelect =
    subscriptionPlan === null ||
    subscriptionPlan === "standard" ||
    subscriptionPlan == "pro";

  useEffect(() => {
    if (
      subscriptionPlan &&
      subscriptionPlan !== "multipro" &&
      subscriptionPlan !== "corporate" &&
      subscriptionPlan !== "multipro-trial" &&
      listOfAssistants.length > 0
    ) {
      const firstAssistant = listOfAssistants[0];
      if (
        assistantIdParam &&
        parseInt(assistantIdParam) !== firstAssistant.id
      ) {
        const integration = params.get("integration") || "";
        // Redirect to the URL of the first assistant while preserving the integration parameter
        navigate(
          `/integration?assistantId=${firstAssistant.id}&integration=${integration}`,
          {
            replace: true,
          }
        );
      }
    }
  }, [subscriptionPlan, listOfAssistants, assistantIdParam, params]);

  return (
    <div className={styles.header}>
      <Typography variant="title" className={styles.title}>
        {pageTitle}
      </Typography>
      <div className={styles.selectWrapper}>
        <Input
          typeInput="select"
          options={assistantsOptions}
          placeholder="Select Assistant"
          value={selectedAssistantValue || ""}
          onChange={onAssistantChange}
          disabled={disabledAssistantSelect}
        />
      </div>
    </div>
  );
};

export default IntegrationHeader;
