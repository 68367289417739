import { useState, useEffect } from "react";
import styles from "./TelegramAccountItem.module.scss";
import { Typography } from "../../../../../components";
import { Input } from "../../../../../components";
import { Button } from "../../../../../components";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { FiEyeOff, FiEye } from "react-icons/fi";
import Switch from "react-switch";
import { toast } from "react-toastify";
import supabase from "../../../../../supabase";

export const TelegramAccountInfo = ({
  selectedAccount,
  fetchAccounts,
  userId,
  assistantId,
  onAccountUpdated,
  handleDelete,
  setupChannelWebhook,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOn, setIsOn] = useState(selectedAccount?.is_on || false);

  useEffect(() => {
    setIsOn(selectedAccount?.is_on || false);
  }, [selectedAccount]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const { phone = "", password = "" } = selectedAccount?.metadata || {};

  const handleToggle = async (checked) => {
    setLoading(true);

    try {
      if (checked) {
        // If the toggle is on, we send a request to /setup-webhook
        await setupChannelWebhook("TelegramAccount", selectedAccount);
      } else {
        // If the toggle is off, update is_on via Supabase
        const { error } = await supabase
          .from("channels")
          .update({ is_on: false })
          .match({
            account_id: userId,
            assistant_id: assistantId,
            communication_channel: "TelegramAccount",
            access_token: selectedAccount.access_token,
          });

        if (error) {
          throw new Error("Failed to turn off channel");
        }
      }

      const updatedAccount = { ...selectedAccount, is_on: checked };
      onAccountUpdated(updatedAccount);
    } catch (error) {
      console.error("Error updating toggle:", error);

      setIsOn(!checked);
      toast.error("Failed to update account status.");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    handleDelete();
  };

  return (
    <>
      <div className={styles.headerWrapper}>
        <Typography variant="h2" className={styles.title}>
          Connect your Telegram account
        </Typography>
        <Switch
          onChange={handleToggle}
          checked={isOn}
          uncheckedIcon={false}
          checkedIcon={false}
          height={27}
          offColor={"#E3E6EE"}
          onColor={"#777EF5"}
          disabled={loading}
        />
      </div>
      <form className={styles.telegramConnection}>
        <div className={styles.wrapper}>
          <label htmlFor="phone">Phone Number</label>
          <PhoneInput
            international
            defaultCountry="UA"
            value={phone}
            className={styles.phoneInput}
            disabled
          />
        </div>
        <div className={styles.wrapper}>
          <label htmlFor="password">Password</label>
          <div style={{ position: "relative" }} className={styles.wrapper}>
            <Input
              type={showPassword ? "text" : "password"}
              placeholder={"Password"}
              value={password}
              disabled
            />
            <i
              onClick={togglePasswordVisibility}
              style={{
                position: "absolute",
                right: "10px",
                top: "58%",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
            >
              {showPassword ? <FiEye size={18} /> : <FiEyeOff size={18} />}
            </i>
          </div>
        </div>
        <Button
          variant="contained"
          onClick={handleDeleteClick}
          disabled={loading}
          title="Revoke"
          className={styles.deleteButton}
        />
      </form>
    </>
  );
};
