import { createSlice } from "@reduxjs/toolkit";

const debugModeSlice = createSlice({
  name: "debug",
  initialState: {
    isDebugMode: false,
  },
  reducers: {
    setDebugMode: (state, action) => {
      state.isDebugMode = action.payload;
    },

    toggleDebugMode: (state) => {
      state.isDebugMode = !state.isDebugMode;
    },
  },
});

export const { setDebugMode, toggleDebugMode } = debugModeSlice.actions;
export default debugModeSlice.reducer;
