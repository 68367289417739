import React from "react";
import styles from "../Profile.module.scss";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import classNames from "classnames";

export const PersonalInfoBlock = ({
  fullName,
  setFullName,
  phoneValue,
  setPhoneValue,
  emailValue,
  setEmailValue,
  isEditMode = false,
  isChangePassword = false,
  isOperator = false,
  role,
  formErrors = {},
  isAdding = false,
}) => {
  return (
    <div
      className={classNames(styles.details, {
        [styles.operator]: isOperator,
      })}
    >
      <div className={styles.row}>
        <div className={styles.field}>
          <label>Full Name</label>
          <input
            type="text"
            value={fullName}
            disabled={!isEditMode || isChangePassword}
            onChange={(e) => setFullName(e.target.value)}
            placeholder="Enter full name"
          />

          {formErrors.fullName && (
            <p className={styles.errorText}>{formErrors.fullName}</p>
          )}
        </div>

        {/* The role is always displayed as read-only. */}
        <div className={styles.field}>
          <label>Your Role</label>
          <input
            type="text"
            value={role}
            disabled
            className={styles.readOnlyInput}
          />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.field}>
          <label htmlFor="phone">Enter Phone Number</label>
          <PhoneInput
            international
            defaultCountry="UA"
            value={phoneValue}
            disabled={!isEditMode || isChangePassword}
            placeholder="+38 099 12 345 6789"
            onChange={(phoneValue) => setPhoneValue(phoneValue)}
            className={styles.phoneInput}
          />

          {formErrors.phone && (
            <p className={styles.errorText}>{formErrors.phone}</p>
          )}
        </div>
        {/* Email is only editable when adding an operator */}
        <div className={styles.field}>
          <label>Email</label>
          <input
            type="email"
            value={emailValue}
            disabled={!isEditMode || !isOperator || !isAdding}
            onChange={(e) => setEmailValue(e.target.value)}
            placeholder="example@domain.com"
          />

          {formErrors.email && (
            <p className={styles.errorText}>{formErrors.email}</p>
          )}
        </div>
      </div>
    </div>
  );
};
