import React, { useRef } from "react";
import Select from "react-select";
import { FaRegUser } from "react-icons/fa";
import { IoCalendarClearOutline } from "react-icons/io5";
import { PiFunnel } from "react-icons/pi";
import classNames from "classnames";

import { CustomDropdownIndicator } from "../../../../../../components/CustomDropdownIndicator/CustomDropdownIndicator";
import { CustomValueContainer } from "../../../../../../components/CustomDropdownIndicator/CustomValueContainer";
import { customSelectStyles } from "../../../../../../constants/general";
import { CustomFilterMenuList } from "../CustomFilterMenuList/CustomFilterMenuList";
import { CustomOption } from "../../../../../../components/CustomDropdownIndicator/CustomOption";
import { SelectedValueWithRemoveCross } from "../../../../../../components/CustomDropdownIndicator/SelectedValueWithRemoveCross";
import { ChannelDropdown } from "../ChannelDropdown/ChannelDropdown";
import { timeRangeOptions } from "../../../../../../constants/channels";

import styles from "../ChatList.styles.module.scss";

export const FiltersPanel = ({ filterState, filterActions }) => {
  const {
    appliedFunnelStatuses,
    appliedTimeRange,
    selectedFunnelStatuses,
    selectedTimeRange,
    updateListOfAssistants,
    selectedAssistants,
    selectedChannels,
    funnelStatuses,
    isAssistantMenuOpen,
    isFunnelMenuOpen,
    isTimeRangeMenuOpen,
  } = filterState;

  const {
    setSelectedAssistants,
    setSelectedChannels,
    setSelectedFunnelStatuses,
    setSelectedTimeRange,
    setIsAssistantMenuOpen,
    setIsFunnelMenuOpen,
    setIsTimeRangeMenuOpen,
    handleApplyAssistants,
    handleCleanAssistants,
    handleApplyChannels,
    handleCleanChannels,
    handleApplyFunnelStatus,
    handleCleanFunnelStatuses,
    handleApplyTimeRange,
    handleCleanTimeRange,
    dispatch, // Додано
  } = filterActions;

  const assistantSelectRef = useRef();
  const funnelStatusSelectRef = useRef();
  const timeRangeSelectRef = useRef();

  const handleAssistantsChange = (selectedOption) => {
    dispatch(setSelectedAssistants(selectedOption));
  };

  const handleChannelsChange = (selectedOptions) => {
    setSelectedChannels(selectedOptions);
  };

  const handleFunnelStatusesChange = (selectedOption) => {
    setSelectedFunnelStatuses(selectedOption);
  };

  const handleTimeRangeChange = (selectedOption) => {
    setSelectedTimeRange(selectedOption);
  };

  return (
    <div className={styles.filtersContainer}>
      {/* Dropdown for Assistants */}
      <div className={classNames(styles.filterItem, styles.assistantItem)}>
        <FaRegUser size={16} />
        <Select
          ref={assistantSelectRef}
          isMulti={true}
          placeholder={"Assistant"}
          options={updateListOfAssistants}
          value={selectedAssistants}
          isSearchable={false}
          onChange={handleAssistantsChange}
          components={{
            ValueContainer: CustomValueContainer,
            DropdownIndicator: CustomDropdownIndicator,
            MenuList: (menuProps) => (
              <CustomFilterMenuList
                {...menuProps}
                onApply={handleApplyAssistants}
                onCleanAll={handleCleanAssistants}
                onCloseMenu={() => setIsAssistantMenuOpen(false)}
              />
            ),
            Option: CustomOption,
            MultiValue: SelectedValueWithRemoveCross,
          }}
          styles={customSelectStyles}
          menuIsOpen={isAssistantMenuOpen}
          onMenuOpen={() => setIsAssistantMenuOpen(true)}
          onMenuClose={() => setIsAssistantMenuOpen(false)}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isClearable={false}
          selectType="assistants"
        />
      </div>

      {/* Dropdown for Channels */}
      <div className={styles.filterItem}>
        <ChannelDropdown
          selectedChannels={selectedChannels}
          onSelectChannel={handleChannelsChange}
          onApply={handleApplyChannels}
          onCleanAll={handleCleanChannels}
        />
      </div>

      <div className={styles.filters}>
        {/* Dropdown for Funnel status */}
        <div
          className={classNames(styles.filterItem, {
            [styles.selectedFilters]: appliedFunnelStatuses?.length > 0,
          })}
        >
          <PiFunnel size={20} />
          <Select
            ref={funnelStatusSelectRef}
            isMulti={true}
            placeholder={"Funnel status"}
            options={funnelStatuses}
            value={selectedFunnelStatuses}
            isSearchable={false}
            onChange={handleFunnelStatusesChange}
            components={{
              ValueContainer: CustomValueContainer,
              DropdownIndicator: CustomDropdownIndicator,
              MenuList: (menuProps) => (
                <CustomFilterMenuList
                  {...menuProps}
                  onApply={handleApplyFunnelStatus}
                  onCleanAll={handleCleanFunnelStatuses}
                  onCloseMenu={() => setIsFunnelMenuOpen(false)}
                />
              ),
              Option: CustomOption,
            }}
            styles={customSelectStyles}
            menuIsOpen={isFunnelMenuOpen}
            onMenuOpen={() => setIsFunnelMenuOpen(true)}
            onMenuClose={() => setIsFunnelMenuOpen(false)}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            isClearable={false}
            selectType="funnelStatus"
          />
        </div>

        {/* Dropdown for Time range */}
        <div
          className={classNames(styles.filterItem, {
            [styles.selectedFilters]: appliedTimeRange !== null,
          })}
        >
          <IoCalendarClearOutline size={20} />
          <Select
            ref={timeRangeSelectRef}
            isMulti={false}
            placeholder={"Date"}
            options={timeRangeOptions}
            value={selectedTimeRange}
            isSearchable={false}
            onChange={handleTimeRangeChange}
            components={{
              ValueContainer: CustomValueContainer,
              DropdownIndicator: CustomDropdownIndicator,
              MenuList: (menuProps) => (
                <CustomFilterMenuList
                  {...menuProps}
                  onApply={handleApplyTimeRange}
                  onCleanAll={handleCleanTimeRange}
                  onCloseMenu={() => setIsTimeRangeMenuOpen(false)}
                />
              ),
              Option: CustomOption,
            }}
            styles={customSelectStyles}
            menuIsOpen={isTimeRangeMenuOpen}
            onMenuOpen={() => setIsTimeRangeMenuOpen(true)}
            onMenuClose={() => setIsTimeRangeMenuOpen(false)}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            selectType="date"
          />
        </div>
      </div>
    </div>
  );
};
