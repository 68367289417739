import React from "react";
import { components } from "react-select";

export const CustomDropdownIndicator = (props) => {
  const { hasValue, getValue } = props;
  const selectedOptions = getValue();

  // Hide the dropdown indicator if items are selected
  if (hasValue && selectedOptions.length > 0) {
    return null;
  }

  return <components.DropdownIndicator {...props} />;
};
