import styles from "./Typography.module.scss";
import classNames from "classnames";

const variantMapping = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  body1: "span",
  body2: "span",
  body3: "span",
  body4: "p",
  caption: "span",
  caption2: "span",
  title: "h2",
};

export function Typography({
  variant = "body1",
  children,
  className: customClassName,
  color: customColor,
  ...props
}) {
  const Component = variantMapping[variant];

  return (
    <Component
      className={classNames(
        styles.base,
        {
          [styles[variant]]: variant !== "span",
        },
        customClassName ? customClassName : "",
        customColor ? styles[customColor] : ""
      )}
      {...props}
    >
      {children}
    </Component>
  );
}
