import React, { useState, useRef, useEffect } from "react";
import styles from "./CollapsibleMessage.module.scss";

export const CollapsibleMessage = ({ content, maxLength = 400 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [maxHeight, setMaxHeight] = useState("0px");
  const contentRef = useRef(null);
  const truncatedRef = useRef(null);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const needsTruncate = content.length > maxLength;
  const displayedContent = isExpanded
    ? content
    : `${content.substring(0, maxLength)}...`;

  useEffect(() => {
    if (isExpanded) {
      if (contentRef.current) {
        setMaxHeight(`500vh`);
      }
    } else if (needsTruncate && truncatedRef.current) {
      setMaxHeight(`${truncatedRef.current.scrollHeight}px`);
    }
  }, [isExpanded, needsTruncate, content, maxLength]);

  return (
    <div className={styles.collapsibleMessage}>
      {!isExpanded && needsTruncate && (
        <div className={styles.hiddenTruncatedContent} ref={truncatedRef}>
          <p>{displayedContent}</p>
        </div>
      )}
      <div
        className={`${styles.contentWrapper} ${
          isExpanded ? styles.expanded : ""
        }`}
        style={{
          maxHeight: maxHeight,
        }}
      >
        <p ref={contentRef}>{displayedContent}</p>
      </div>
      {needsTruncate && (
        <button onClick={toggleExpand} className={styles.toggleButton}>
          {isExpanded ? "Show less 🔼" : "Show more 🔽"}
        </button>
      )}
    </div>
  );
};
