import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAvailableIntegrations,
  selectSelectedAssistant,
  selectSelectedIntegrationType,
  setSelectedIntegrationType,
} from "../../../../store/slices/integrationSlice";
import { Chip, Typography } from "../../../../components";
import {
  INTEGRATIONS,
  INTEGRATION_NAMES,
} from "../../../../constants/integrations";

import styles from "./AvailableIntegrations.module.scss";
import classNames from "classnames";
import { TOOL_TYPE } from "../../../../constants/general";

const AvailableIntegrations = ({ subscriptionPlan }) => {
  const availableIntegrations = useSelector(selectAvailableIntegrations);
  const selectedIntegrationType = useSelector(selectSelectedIntegrationType);
  const selectedAssistant = useSelector(selectSelectedAssistant);
  const dispatch = useDispatch();

  const getAvailableIntegrationsByName = useCallback(
    (name) => {
      return availableIntegrations
        ?.filter((integration) => integration.integration === name)
        .map((integration) => integration.id);
    },
    [availableIntegrations]
  );

  const updatedIntegrations = useMemo(() => {
    if (!availableIntegrations) return INTEGRATIONS;

    return INTEGRATIONS.map((i) => {
      const matchedChannel = availableIntegrations?.find(
        ({ integration }) => i.name === integration
      );

      const availableIntegrationsCurrentName = getAvailableIntegrationsByName(
        i.name
      );

      const isAssistantConnected = selectedAssistant?.integrations?.some(
        (integrationId) =>
          availableIntegrationsCurrentName?.includes(integrationId)
      );

      let isConnected = isAssistantConnected ?? false;

      if (matchedChannel && i.name === "Spreadsheet Orders") {
        // Check if sheet_id is not null
        isConnected = isConnected && matchedChannel.sheet_id != null;
      }
      return matchedChannel
        ? {
            ...i,
            connected: isConnected,
            id: matchedChannel.id,
          }
        : i;
    });
  }, [
    availableIntegrations,
    getAvailableIntegrationsByName,
    selectedAssistant,
  ]);

  const onIntegrationClick = (integration) => {
    const { authType, name, knowledge_base, scope } = integration;
    dispatch(
      setSelectedIntegrationType({ authType, name, knowledge_base, scope })
    );
  };

  const connectedCRMIntegration = useMemo(() => {
    return updatedIntegrations?.find(
      (integration) =>
        integration.type === TOOL_TYPE.CRM &&
        integration.connected &&
        // Exclude Wlaunch and Altegio from being considered as connected CRM integrations for this logic
        integration.name !== INTEGRATION_NAMES.WLAUNCH &&
        integration.name !== INTEGRATION_NAMES.ALTEGIO
    );
  }, [updatedIntegrations]);

  const connectedIntegrationsByName = useMemo(() => {
    return updatedIntegrations
      ?.filter((integration) => integration.connected)
      .reduce((acc, integration) => {
        acc[integration.name] = integration;
        return acc;
      }, {});
  }, [updatedIntegrations]);

  const checkIfIntegrationIsDisabled = useCallback(
    (integration) => {
      // Disable Altegio if Wlaunch is connected
      if (
        connectedIntegrationsByName[INTEGRATION_NAMES.WLAUNCH] &&
        integration.name === INTEGRATION_NAMES.ALTEGIO
      ) {
        return true;
      }

      // Disable Wlaunch if Altegio is connected
      if (
        connectedIntegrationsByName[INTEGRATION_NAMES.ALTEGIO] &&
        integration.name === INTEGRATION_NAMES.WLAUNCH
      ) {
        return true;
      }

      // Existing logic to disable other CRM integrations if a CRM is connected
      if (
        integration.type === TOOL_TYPE.CRM &&
        connectedCRMIntegration &&
        integration.id !== connectedCRMIntegration.id
      ) {
        return true;
      }

      return false;
    },
    [connectedIntegrationsByName, connectedCRMIntegration]
  );

  return (
    <div className={styles.container}>
      {updatedIntegrations?.map((integration) => {
        const isDisabled = checkIfIntegrationIsDisabled(integration);

        // define the label on the button
        let label;
        if (integration.connected) {
          label = "Connected";
        } else if (subscriptionPlan === "standard") {
          label = integration.name === "Spreadsheet Orders" ? "Connect" : "PRO";
        } else if (subscriptionPlan === null) {
          label =
            integration.name === "Spreadsheet Orders" ? "Standard" : "PRO";
        } else {
          label = "Connect";
        }

        const disabledBySubscription =
          integration.name !== "Spreadsheet Orders" &&
          !integration.connected &&
          (subscriptionPlan === "standard" || subscriptionPlan === null);

        return (
          <div
            key={integration.name}
            className={classNames(styles.integrationItem, {
              [styles.selected]:
                integration.name === selectedIntegrationType?.name,
              [styles.disabled]: isDisabled || disabledBySubscription,
            })}
            onClick={() => onIntegrationClick(integration)}
          >
            <div className={styles.integrationInfo}>
              <div className={styles.iconContainer}>{integration.icon}</div>
              <Typography variant="body1" className={styles.title}>
                {integration.name}
              </Typography>
            </div>
            <Chip
              status={
                integration.connected
                  ? "success"
                  : label === "PRO"
                  ? "multipro"
                  : label === "Standard"
                  ? "multipro"
                  : ""
              }
            >
              {label}
            </Chip>
          </div>
        );
      })}
    </div>
  );
};

export default AvailableIntegrations;
