export const Manager = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.3335 9.33333H4.66683C2.82588 9.33333 1.3335 10.8257 1.3335 12.6667V13.3333C1.3335 13.7015 1.63197 14 2.00016 14H10.0002C10.3684 14 10.6668 13.7015 10.6668 13.3333V12.6667C10.6668 10.8257 9.17445 9.33333 7.3335 9.33333Z"
        stroke="#777EF5"
        strokeWidth="1.5"
      />
      <path
        d="M6.00016 7.33333C7.47292 7.33333 8.66683 6.13943 8.66683 4.66667C8.66683 3.19391 7.47292 2 6.00016 2C4.5274 2 3.3335 3.19391 3.3335 4.66667C3.3335 6.13943 4.5274 7.33333 6.00016 7.33333Z"
        stroke="#777EF5"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6665 2.58203C10.7 2.58203 9.9165 3.36553 9.9165 4.33203V5.66536C9.9165 5.85201 9.94572 6.03183 9.99984 6.2005V8.16536C9.99984 8.44151 10.2377 8.65053 10.4527 8.47731C10.7592 8.23048 11.0294 7.77806 11.1871 7.34889C11.3395 7.39219 11.5003 7.41536 11.6665 7.41536H13.6665C14.633 7.41536 15.4165 6.63186 15.4165 5.66537V4.33203C15.4165 3.36553 14.633 2.58203 13.6665 2.58203H11.6665ZM11.4165 4.33203C11.4165 4.19396 11.5284 4.08203 11.6665 4.08203H13.6665C13.8046 4.08203 13.9165 4.19396 13.9165 4.33203V5.66537C13.9165 5.80344 13.8046 5.91536 13.6665 5.91536H11.6665C11.5284 5.91536 11.4165 5.80344 11.4165 5.66536V4.33203Z"
        fill="#777EF5"
      />
    </svg>
  );
};
