import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import profileWoman from "../../assets/img/profile.png";
import supabase from "../../supabase";
import { logout, login } from "./authSlice";
import { updateUser } from "./authSlice";

export const updateUserProfile = createAsyncThunk(
  "profile/updateUserProfile",
  async (
    { accountId, newFullName, newPhone },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { data, error } = await supabase
        .from("users")
        .update({
          full_name: newFullName,
          phone: newPhone,
        })
        .eq("id", accountId)
        .select()
        .single();

      if (error) {
        throw new Error(error.message);
      }

      dispatch(
        updateUser({
          full_name: data.full_name || "",
          phone: data.phone || "",
        })
      );

      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const uploadAvatar = createAsyncThunk(
  "profile/uploadAvatar",
  async ({ clientId, file }, { dispatch, rejectWithValue }) => {
    const formData = new FormData();
    formData.append("avatar", file);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/avatars/${clientId}`,
        {
          method: "POST",
          body: formData,
        }
      );

      const text = await response.text();
      let data;
      try {
        data = JSON.parse(text);
      } catch (err) {
        console.error("JSON parse error:", err);
        return rejectWithValue("Failed to parse JSON response");
      }

      if (!response.ok) {
        console.error("Error response:", data);
        return rejectWithValue(data.message || "Failed to upload avatar");
      }

      const newAvatarUrl = data.url;

      // Updating an avatar in the Supabase using .select()
      const { data: updatedUser, error } = await supabase
        .from("users")
        .update({ avatar_url: newAvatarUrl })
        .eq("id", clientId)
        .select()
        .single();

      console.log("updatedUser", updatedUser);

      if (error) {
        console.error("Error updating user avatar:", error);
        return rejectWithValue(error.message || "Failed to update user avatar");
      }

      dispatch(updateUser({ avatar_url: updatedUser?.avatar_url }));
      dispatch(setAvatarUrl(updatedUser.avatar_url || profileWoman));

      return updatedUser.avatar_url;
    } catch (error) {
      console.error("Error uploading avatar:", error);
      return rejectWithValue(error.message);
    }
  }
);

// password validation
export const validatePassword = createAsyncThunk(
  "profile/validatePassword",
  async (
    { currentPassword, newPassword, confirmNewPassword },
    { dispatch }
  ) => {
    const errors = {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    };
    let isValid = true;

    if (!currentPassword) {
      errors.currentPassword = "Current password is required.";
      isValid = false;
    }

    if (!newPassword) {
      errors.newPassword = "New password is required";
      isValid = false;
    } else if (newPassword.length < 8) {
      errors.newPassword = "Password must be at least 8 characters long";
      isValid = false;
    }

    if (!confirmNewPassword) {
      errors.confirmPassword = "Please confirm your new password";
      isValid = false;
    } else if (newPassword !== confirmNewPassword) {
      errors.confirmPassword = "Passwords do not match";
      isValid = false;
    }

    dispatch(setPasswordErrors(errors));

    return isValid;
  }
);

const initialState = {
  avatarUrl: profileWoman,
  isRemoveAvatarPopupOpen: false,
  isEditMode: false,
  fullName: "",
  phoneValue: "",
  emailValue: "",
  isChangePassword: false,
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: "",
  passwordErrors: {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  },

  // popups
  isDeleteAccountPopupOpen: false,
  isLogoutPopupOpen: false,

  // avatar uploading
  isUploadingAvatar: false,
  uploadAvatarError: null,
  error: null,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setAvatarUrl: (state, action) => {
      state.avatarUrl = action.payload || profileWoman;
    },
    setIsRemoveAvatarPopupOpen: (state, action) => {
      state.isRemoveAvatarPopupOpen = action.payload;
    },
    setIsEditMode: (state, action) => {
      state.isEditMode = action.payload;
    },
    setFullName: (state, action) => {
      state.fullName = action.payload;
    },
    setPhoneValue: (state, action) => {
      state.phoneValue = action.payload;
    },
    setEmailValue: (state, action) => {
      state.emailValue = action.payload;
    },
    setIsChangePassword: (state, action) => {
      state.isChangePassword = action.payload;
    },
    setCurrentPassword: (state, action) => {
      state.currentPassword = action.payload;
    },
    setNewPassword: (state, action) => {
      state.newPassword = action.payload;
    },
    setConfirmNewPassword: (state, action) => {
      state.confirmNewPassword = action.payload;
    },
    setPasswordErrors: (state, action) => {
      state.passwordErrors = action.payload;
    },

    setIsDeleteAccountPopupOpen: (state, action) => {
      state.isDeleteAccountPopupOpen = action.payload;
    },
    setIsLogoutPopupOpen: (state, action) => {
      state.isLogoutPopupOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      // Avatar update
      .addCase(uploadAvatar.pending, (state) => {
        state.isUploadingAvatar = true;
        state.uploadAvatarError = null;
      })
      .addCase(uploadAvatar.fulfilled, (state, action) => {
        state.isUploadingAvatar = false;
        state.avatarUrl = action.payload; // Update avatar URL
      })
      .addCase(uploadAvatar.rejected, (state, action) => {
        state.isUploadingAvatar = false;
        state.uploadAvatarError = action.payload;
      })

      // Profile update
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        const updatedUserData = action.payload;
        state.fullName = updatedUserData.full_name || "";
        state.phoneValue = updatedUserData.phone || "";
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.error = action.payload;
      })

      .addCase(validatePassword.rejected, (state, action) => {
        state.error = action.payload;
      })

      // Reset state when logging out
      .addCase(logout, () => initialState);
  },
});

export const {
  setAvatarUrl,
  setIsRemoveAvatarPopupOpen,
  setIsEditMode,
  setFullName,
  setPhoneValue,
  setEmailValue,
  setIsChangePassword,
  setCurrentPassword,
  setNewPassword,
  setConfirmNewPassword,
  setPasswordErrors,
  setIsDeleteAccountPopupOpen,
  setIsLogoutPopupOpen,
} = profileSlice.actions;

export default profileSlice.reducer;
