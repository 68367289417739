export const OpenCart = ({ width = 24, height = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 76 76"
  >
    <g transform="translate(-4934.404 17352.596)">
      <circle
        cx="38"
        cy="38"
        r="38"
        transform="translate(4934.404 -17352.596)"
        fill="#1fbbeb"
      ></circle>
      <g transform="translate(4942.564 -17334.164)">
        <g transform="translate(0 0)">
          <g transform="translate(0 25.187)">
            <path
              d="M29.622,98.4a2.269,2.269,0,0,0-.38-1.336,1.46,1.46,0,0,0-2.38,0,2.253,2.253,0,0,0-.383,1.336,2.325,2.325,0,0,0,.383,1.35,1.46,1.46,0,0,0,2.38,0A2.342,2.342,0,0,0,29.622,98.4Zm2.3,0a4.316,4.316,0,0,1-1.044,2.948,3.82,3.82,0,0,1-5.658,0,4.673,4.673,0,0,1,0-5.9,3.848,3.848,0,0,1,5.658,0A4.306,4.306,0,0,1,31.926,98.4Z"
              transform="translate(-24.176 -91.729)"
              fill="#fff"
              fillRule="evenodd"
            ></path>
            <path
              d="M56.32,98.4a2.272,2.272,0,0,0-.383-1.336,1.46,1.46,0,0,0-2.38,0,2.269,2.269,0,0,0-.38,1.336,2.342,2.342,0,0,0,.38,1.35,1.46,1.46,0,0,0,2.38,0A2.345,2.345,0,0,0,56.32,98.4Zm2.3,0a4.537,4.537,0,0,1-.945,2.86,3.132,3.132,0,0,1-2.6,1.342,2.876,2.876,0,0,1-1.9-.647c0,.1.011.339.033.722s.033.7.033.947q0,2.055-1.223,2.053a1.074,1.074,0,0,1-.824-.353,1.226,1.226,0,0,1-.323-.857V95.563a1.194,1.194,0,0,1,.331-.843,1.078,1.078,0,0,1,.818-.352A1.123,1.123,0,0,1,53.1,95.1a2.416,2.416,0,0,1,1.975-.884,3.141,3.141,0,0,1,2.606,1.325A4.568,4.568,0,0,1,58.624,98.4Z"
              transform="translate(-42.546 -91.729)"
              fill="#fff"
              fillRule="evenodd"
            ></path>
            <path
              d="M83.186,97.577a1.35,1.35,0,0,0-.488-.962,1.459,1.459,0,0,0-1-.432,1.476,1.476,0,0,0-1.017.432,1.288,1.288,0,0,0-.471.962Zm2.3.579a.984.984,0,0,1-.273.8,1.349,1.349,0,0,1-.848.22H80.211a1.115,1.115,0,0,0,.62,1.016,2.491,2.491,0,0,0,1.223.279,3.323,3.323,0,0,0,1.35-.369,3.433,3.433,0,0,1,1.011-.37.9.9,0,0,1,.68.295,1,1,0,0,1,.278.706q0,.946-1.413,1.474a6.316,6.316,0,0,1-2.2.4,3.792,3.792,0,0,1-2.912-1.2A4.238,4.238,0,0,1,77.74,98.4,4.159,4.159,0,0,1,78.9,95.448a3.766,3.766,0,0,1,2.865-1.232,3.456,3.456,0,0,1,2.659,1.165A3.977,3.977,0,0,1,85.49,98.156Z"
              transform="translate(-61.031 -91.729)"
              fill="#fff"
              fillRule="evenodd"
            ></path>
            <path
              d="M111.67,101.244a1.229,1.229,0,0,1-.322.857,1.075,1.075,0,0,1-.824.353q-1.207,0-1.207-1.915c0-.245.017-.631.05-1.163s.052-.917.052-1.163q0-2.029-1.311-2.03t-1.309,2.03c0,.245.014.634.038,1.163s.041.917.041,1.163q0,1.913-1.19,1.915a1.065,1.065,0,0,1-.821-.353,1.217,1.217,0,0,1-.325-.857V95.561a1.181,1.181,0,0,1,.325-.846,1.075,1.075,0,0,1,.824-.347,1.156,1.156,0,0,1,1.149.732,2.148,2.148,0,0,1,1.972-.884,2.989,2.989,0,0,1,2.077.691,2.666,2.666,0,0,1,.78,2.034Z"
              transform="translate(-79.473 -91.729)"
              fill="#fff"
              fillRule="evenodd"
            ></path>
            <g transform="translate(32.82)">
              <path
                d="M135.99,101q0,.781-1.016,1.237a4.089,4.089,0,0,1-1.711.369,3.578,3.578,0,0,1-2.829-1.253,4.667,4.667,0,0,1,.022-5.917,3.553,3.553,0,0,1,2.807-1.218,4.556,4.556,0,0,1,1.725.3,1.322,1.322,0,0,1,1,1.239,1.158,1.158,0,0,1-.265.752.839.839,0,0,1-.677.325,2.7,2.7,0,0,1-.81-.187,2.632,2.632,0,0,0-.84-.188,1.534,1.534,0,0,0-1.281.587,2.1,2.1,0,0,0-.427,1.364,2.247,2.247,0,0,0,.383,1.334,1.359,1.359,0,0,0,1.193.631,3.056,3.056,0,0,0,.928-.2,3.153,3.153,0,0,1,.854-.2.855.855,0,0,1,.664.325A1.069,1.069,0,0,1,135.99,101Z"
                transform="translate(-129.387 -91.729)"
                fill="#fff"
                fillRule="evenodd"
              ></path>
              <path
                d="M158,98.4a2.269,2.269,0,0,0-.38-1.336,1.39,1.39,0,0,0-1.19-.614,1.365,1.365,0,0,0-1.191.614,2.255,2.255,0,0,0-.382,1.336,2.4,2.4,0,0,0,.369,1.35,1.4,1.4,0,0,0,1.2.615,1.364,1.364,0,0,0,1.19-.615A2.342,2.342,0,0,0,158,98.4Zm2.3,2.84a1.227,1.227,0,0,1-.322.857,1.081,1.081,0,0,1-.827.353,1.1,1.1,0,0,1-1.074-.733,2.414,2.414,0,0,1-1.975.884,3.157,3.157,0,0,1-2.623-1.342,4.874,4.874,0,0,1,0-5.722,3.186,3.186,0,0,1,2.623-1.325,2.415,2.415,0,0,1,1.975.884,1.1,1.1,0,0,1,1.074-.732,1.079,1.079,0,0,1,.819.352,1.19,1.19,0,0,1,.33.84Z"
                transform="translate(-145.327 -91.729)"
                fill="#fff"
                fillRule="evenodd"
              ></path>
              <path
                d="M185.052,95.409a1.014,1.014,0,0,1-.719,1.016q-.665.224-1.325.457a1.61,1.61,0,0,0-.722,1.532v2.829a1.232,1.232,0,0,1-.325.857,1.083,1.083,0,0,1-.827.353,1.071,1.071,0,0,1-.826-.353,1.216,1.216,0,0,1-.325-.857V95.561a1.19,1.19,0,0,1,.322-.846,1.086,1.086,0,0,1,.827-.347,1.156,1.156,0,0,1,1.149.732,2.091,2.091,0,0,1,1.592-.884,1.133,1.133,0,0,1,.835.352A1.173,1.173,0,0,1,185.052,95.409Z"
                transform="translate(-164.2 -91.729)"
                fill="#fff"
                fillRule="evenodd"
              ></path>
              <path
                d="M203.109,90.027a.91.91,0,0,1-.6.973,4.575,4.575,0,0,1-1.232.1v4.653a1.231,1.231,0,0,1-.325.857,1.139,1.139,0,0,1-1.648,0,1.212,1.212,0,0,1-.33-.857V91.1a2.269,2.269,0,0,1-1.036-.179.937.937,0,0,1-.444-.9.9.9,0,0,1,.458-.884,2.212,2.212,0,0,1,1.023-.176V87.98q0-1.74,1.151-1.738c.766,0,1.151.578,1.151,1.738v.986a4.563,4.563,0,0,1,1.232.1A.9.9,0,0,1,203.109,90.027Z"
                transform="translate(-176.249 -86.242)"
                fill="#fff"
                fillRule="evenodd"
              ></path>
            </g>
          </g>
          <g transform="translate(28.468 21.925)">
            <path
              d="M116.812,76.493h-.317v.578h.317a.741.741,0,0,0,.372-.066.254.254,0,0,0,.1-.234.223.223,0,0,0-.116-.209A.708.708,0,0,0,116.812,76.493Zm.041-.152a.889.889,0,0,1,.493.108.366.366,0,0,1,.157.33.394.394,0,0,1-.1.279.421.421,0,0,1-.275.132l.353.747h-.243l-.342-.713h-.4v.713h-.217v-1.6Zm1.193.821a1.246,1.246,0,0,0-.358-.871,1.209,1.209,0,0,0-.405-.27,1.267,1.267,0,0,0-.477-.093,1.176,1.176,0,0,0-.471.093,1.239,1.239,0,0,0-.4.262,1.3,1.3,0,0,0-.273.41,1.22,1.22,0,0,0,0,.937,1.211,1.211,0,0,0,.27.4,1.225,1.225,0,0,0,.4.27,1.194,1.194,0,0,0,.468.1,1.244,1.244,0,0,0,.474-.1,1.294,1.294,0,0,0,.408-.273,1.189,1.189,0,0,0,.358-.865Zm-1.24-1.378a1.386,1.386,0,0,1,.532.1,1.352,1.352,0,0,1,.446.3,1.338,1.338,0,0,1,.295.444,1.375,1.375,0,0,1,.1.529,1.335,1.335,0,0,1-.4.964,1.461,1.461,0,0,1-.454.305,1.311,1.311,0,0,1-.524.105,1.284,1.284,0,0,1-.52-.105,1.424,1.424,0,0,1-.747-.746,1.353,1.353,0,0,1-.1-.523,1.329,1.329,0,0,1,.1-.527,1.371,1.371,0,0,1,.3-.451,1.308,1.308,0,0,1,.435-.3A1.4,1.4,0,0,1,116.806,75.785Z"
              transform="translate(-115.437 -75.785)"
              fill="#fff"
              fillRule="evenodd"
            ></path>
          </g>
          <g transform="translate(16.375 0)">
            <path
              d="M76.671,5.5c3.454,3.634,6.682,4.178,19.4,4.178s7.127,2.831-.728,9.842c2.49-4.331,11.049-8.164-2.981-7.948C78.919,11.775,78.124,7.682,76.671,5.5Z"
              transform="translate(-76.671 -5.5)"
              fill="#fff"
            ></path>
            <path
              d="M97.209,57.241a1.923,1.923,0,1,1-1.921-1.925A1.921,1.921,0,0,1,97.209,57.241Z"
              transform="translate(-88.156 -39.776)"
              fill="#fff"
            ></path>
            <path
              d="M125.294,57.241a1.923,1.923,0,1,1-1.921-1.925A1.921,1.921,0,0,1,125.294,57.241Z"
              transform="translate(-107.48 -39.776)"
              fill="#fff"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
