import React, { useCallback, useMemo, useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import classNames from "classnames";
import styles from "./Integration.module.scss";
import { Loader } from "../Loader";
import { Modal } from "../Modal";
import { Button } from "../Button";
import { Input } from "../Input";
import {
  PLUGIN_NAME_REGEX,
  SITE_URL_REGEX,
} from "../../constants/validationScheme";
import supabase from "../../supabase";
import {
  WORDPRESS_CMS,
  OPENCART_CMS,
} from "../../constants/knowledgeBaseItems";
import {
  useValidateIntegrationAssetsMutation,
  useRevokeIntegrationMutation,
} from "../../store/api";

// TODO: remove mock data after testing
// Mock data for testing
// const mockResponse = {
//   total: "313",
//   status: "success",
//   data: [
//     {
//       id: "1510",
//       name: {
//         "en-gb": "Bedside Table CMD-7300",
//         "uk-ua": "Столик надліжковий CMD-7300",
//         "ru-ru": "Столик надкроватный CMD-7300",
//       },
//     },
//   ],
// };

const fetchIntegrationData = async (id) => {
  try {
    const {
      data: [integrationData],
      error,
    } = await supabase.from("integrations").select().eq("id", id);

    if (error) throw new Error(error.message);

    return integrationData;
  } catch (error) {
    return error;
  }
};

export const IntegrationWithKeys = (props) => {
  const {
    id,
    title,
    metadata,
    icon,
    description,
    connected,
    fetchIntegrations,
    isLoadingRefreshData,
    children,
    knowledgeBase,
    isAnyCMSConnected,
    integrationInProgressId,
    docLink,
    accessToken: storedAccessToken,
    refreshToken: storedRefreshToken,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalSelectLanguageOpen, setIsModalSelectLanguageOpen] =
    useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [languagesOptions, setLanguagesOptions] = useState([]);
  const [domain, setDomain] = useState("");
  const [isDomainValid, setIsDomainValid] = useState(true);
  const [pluginName, setPluginName] = useState("feedv1");
  const [isPluginNameValid, setIsPluginNameValid] = useState(true);
  const [validateIntegrationAssets] = useValidateIntegrationAssetsMutation();
  const [inventoryName, setInventoryName] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [refreshToken, setRefreshToken] = useState("");
  const [loadingLanguages, setLoadingLanguages] = useState(false);
  const [integrationOpenCartCMSData, setIntegrationOpenCartCMSData] =
    useState(null);

  const isOpenCartCMS = title === OPENCART_CMS && !Number.isNaN(Number(id));

  useEffect(() => {
    if (isOpenCartCMS) {
      const fetchOpenCartCMSIntagration = async () => {
        try {
          const data = await fetchIntegrationData(id);

          setIntegrationOpenCartCMSData(data);
        } catch (error) {
          console.log("error", error);
        }
      };

      fetchOpenCartCMSIntagration();
    }
  }, [id, isOpenCartCMS, metadata?.oc_inventory_lang]);

  useEffect(() => {
    if (!integrationOpenCartCMSData || !isModalSelectLanguageOpen) return;

    const fetchLanguages = async () => {
      setLoadingLanguages(true);

      try {
        const {
          data: [details],
        } = await axios.get(integrationOpenCartCMSData.access_token);

        const options = (Object.keys(details?.name) || []).map((lang) => ({
          value: lang,
          label: lang,
        }));

        setLanguagesOptions(options);
      } catch (error) {
        toast.error(error.message || "Languages cannot be displayed");
      } finally {
        setLoadingLanguages(false);
      }
    };

    fetchLanguages();
  }, [
    integrationOpenCartCMSData,
    isModalSelectLanguageOpen,
    metadata?.oc_inventory_lang,
  ]);

  const user = useSelector((state) => state.auth.user);

  const [revokeIntegration] = useRevokeIntegrationMutation();

  useEffect(() => {
    // When the component mounts, set accessToken and refreshToken from props if connected
    if (connected) {
      setAccessToken(storedAccessToken);
      setRefreshToken(storedRefreshToken);
    }
  }, [connected, storedAccessToken, storedRefreshToken]);

  const handleDomainChange = useCallback((e) => {
    setDomain(e.target.value);
    if (SITE_URL_REGEX.test(e.target.value)) {
      setIsDomainValid(true);
    } else {
      setIsDomainValid(false);
    }
  }, []);

  const handlePluginNameChange = useCallback((e) => {
    if (e.target.value.trim() === "") {
      setIsPluginNameValid(false);
      setPluginName(e.target.value);
    } else if (PLUGIN_NAME_REGEX.test(e.target.value)) {
      setPluginName(e.target.value.replaceAll(" ", "-"));
      setIsPluginNameValid(true);
    }
  }, []);

  const updateIntegrationAssets = useCallback(async () => {
    try {
      if (title === "Site link") {
        const { data, error } = await supabase
          .from("integrations")
          .select("id")
          .eq("account_id", user.account_id)
          .eq("integration", "Site link");

        if (error) {
          toast.error("Failed to check existing Site link: " + error.message);
          return;
        }
        if (data && data.length > 0) {
          toast.error("You already have a 'Site link' integration connected.");
          return;
        }

        if (!SITE_URL_REGEX.test(domain)) {
          toast.error("Invalid URL for Site link");
          return;
        }

        const { error: insertErr } = await supabase
          .from("integrations")
          .insert({
            account_id: user.account_id,
            integration: "Site link",
            access_token: domain,
            type: "cms",
            is_knowledge_base: true,
            knowledge_base: knowledgeBase,
          });
        if (insertErr) {
          toast.error("Failed to insert Site link: " + insertErr.message);
          return;
        }

        toast.success("Site link has been connected!");
        setIsModalOpen(false);
        setIsLoading(false);
        return;
      }

      // if this another integration (WordPress, OpenCart, etc.)
      const parsedDomain = new URL(domain);
      const domainHostname = parsedDomain.hostname;
      const updatedPluginName = pluginName.trim().toLowerCase();

      let newAccessToken = "";
      if (title === WORDPRESS_CMS) {
        newAccessToken = `https://${domainHostname}/wp-json/mcb/v1/${user.account_id}-${updatedPluginName}`;
      } else if (title === OPENCART_CMS) {
        newAccessToken = `https://${domainHostname}/index.php?route=extension/module/mychatbot/products&app_id=${updatedPluginName}`;
      } else if (inventoryName === "xml-inventory") {
        newAccessToken = domain;
      } else {
        newAccessToken = domain;
      }

      const { error: insertErr } = await supabase.from("integrations").insert({
        account_id: user.account_id,
        integration: title,
        access_token: newAccessToken,
        type: "cms",
        is_knowledge_base: true,
        knowledge_base: knowledgeBase,
      });

      if (insertErr) {
        toast.error("Failed to insert record: " + insertErr.message);
        return;
      }
      toast.success("Integration assets updated successfully");

      // if this is no XML => open link for plugin uploading
      if (inventoryName !== "xml-inventory") {
        window.open(
          `${process.env.REACT_APP_API_URL}/downloads/${inventoryName}/${user.account_id}/${updatedPluginName}`
        );
      } else {
        const resp = await validateIntegrationAssets({
          account_id: user.account_id,
          integration: title,
          access_token: newAccessToken,
        });
        if (resp.error) {
          toast.error(resp.error);
        }
      }
    } catch (err) {
      toast.error("Error in updateIntegrationAssets: " + err.message);
    } finally {
      setIsModalOpen(false);
      setIsLoading(false);
    }
  }, [
    domain,
    inventoryName,
    knowledgeBase,
    pluginName,
    title,
    user.account_id,
    validateIntegrationAssets,
  ]);

  const handleOnConnect = useCallback(async () => {
    if (isDomainValid && isPluginNameValid) {
      setIsLoading(true);
      try {
        await updateIntegrationAssets();
      } catch ({ message }) {
        toast.error(message);
      }
      fetchIntegrations();
    }
  }, [
    fetchIntegrations,
    isDomainValid,
    isPluginNameValid,
    updateIntegrationAssets,
  ]);

  const handleOnRevoke = useCallback(async () => {
    setIsLoading(true);
    try {
      // Prepare the payload
      const payload = {
        account_id: user.account_id,
        integration: title,
        access_token: accessToken,
        refresh_token: refreshToken,
        //sheet_id: sheetId,
      };

      // Send POST request to /revoke-integration
      try {
        await revokeIntegration(payload).unwrap();
      } catch (error) {
        toast.error("Failed to revoke integration");
      }

      // Proceed to delete the record from Supabase
      const { error: errorSupabase } = await supabase
        .from("integrations")
        .delete()
        .eq("account_id", user.account_id)
        .eq("id", id)
        .eq("integration", title);

      if (errorSupabase) {
        toast.error(errorSupabase.message || errorSupabase);
      }

      setDomain("");
      setPluginName("");
      setInventoryName("");
      setAccessToken("");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
      fetchIntegrations();
    }
  }, [fetchIntegrations, id, title, user.account_id, accessToken]);

  const isIntegrationDisabled = useMemo(() => {
    if (integrationInProgressId && integrationInProgressId !== id) {
      return true;
    }
    return isAnyCMSConnected && !connected;
  }, [connected, isAnyCMSConnected, integrationInProgressId, id]);

  const handleSetOCLang = useCallback(async () => {
    if (!selectedLanguage) return;

    try {
      const { error } = await supabase
        .from("integrations")
        .update({
          metadata: {
            oc_inventory_lang: selectedLanguage.value,
          },
        })
        .match({
          account_id: user.account_id,
          integration: title,
        });

      if (error) {
        toast.error(`Failed to update language: ${error.message}`);
        return;
      }

      toast.success("Language updated successfully");
      setSelectedLanguage(null);
      setIsModalSelectLanguageOpen(false);
      fetchIntegrations(); // Refresh the integrations list
      setIsModalSelectLanguageOpen(false);
    } catch (err) {
      toast.error(`Error updating language: ${err.message}`);
    }
  }, [fetchIntegrations, selectedLanguage, title, user.account_id]);

  const integrationContent = useMemo(
    () => (
      <>
        <div className={styles.iconContainer}>
          <div className={styles.iconBox}>{icon ? icon : children}</div>
          {docLink && (
            <a
              target="_blank"
              href={docLink}
              className={styles.link}
              rel="noreferrer"
            >
              link
            </a>
          )}
          {!!title && <div className={styles.title}>{title}</div>}
          {!!description && (
            <div className={styles.description}>{description}</div>
          )}
        </div>

        {isOpenCartCMS && (
          <Button
            onClick={() => setIsModalSelectLanguageOpen(true)}
            className={classNames(styles.button, styles.updateLangBtn)}
            title={
              isOpenCartCMS && metadata?.oc_inventory_lang
                ? metadata?.oc_inventory_lang
                : "Setup language"
            }
            disabled={Boolean(!isOpenCartCMS)}
          />
        )}

        <Button
          onClick={() => {
            connected ? handleOnRevoke() : setIsModalOpen(true);
          }}
          className={classNames(styles.button, {
            [styles.connected]: connected,
          })}
          title={connected ? "revoke" : "connect"}
          disabled={isIntegrationDisabled}
        />
      </>
    ),
    [
      children,
      connected,
      description,
      docLink,
      handleOnRevoke,
      icon,
      isIntegrationDisabled,
      isOpenCartCMS,
      metadata?.oc_inventory_lang,
      title,
    ]
  );

  const modalContent = useMemo(
    () => (
      <div className={styles.modalContent}>
        <div>
          <Input
            inputTitle={
              title === "Site link"
                ? "Website URL"
                : inventoryName && inventoryName === "xml-inventory"
                ? "XML file URL"
                : inventoryName === "yml-inventory"
                ? "YML file URL"
                : "Website URL"
            }
            customClass={styles.input}
            onChange={handleDomainChange}
            error={!isDomainValid}
            errorText={isDomainValid ? "" : "Invalid URL"}
            placeholder={
              inventoryName === "yml-inventory"
                ? "https://yourwebsite.com/var/files/1/yml/prices.yml"
                : title === "Site link"
                ? "https://yourwebsite.com"
                : "https://example.com/feed.xml"
            }
          />
        </div>
        {inventoryName &&
          inventoryName !== "xml-inventory" &&
          inventoryName !== "yml-inventory" && (
            <div>
              <Input
                inputTitle="Plugin Name (Alphanumeric characters and spaces/hyphens only)"
                customClass={styles.input}
                onChange={
                  inventoryName !== "xml-inventory"
                    ? handlePluginNameChange
                    : () => {}
                }
                errorText={isPluginNameValid ? "" : "Plugin name is required"}
                placeholder="Plugin for My Site"
                value={pluginName}
              />
            </div>
          )}
      </div>
    ),
    [
      handleDomainChange,
      handlePluginNameChange,
      inventoryName,
      isDomainValid,
      isPluginNameValid,
      pluginName,
      title,
    ]
  );

  const modalActions = useMemo(() => {
    const isDisabled =
      !domain || !pluginName || !isDomainValid || !isPluginNameValid;
    return (
      <Button
        variant="contained"
        title="connect"
        onClick={handleOnConnect}
        className={styles.connectButton}
        disabled={isDisabled}
      />
    );
  }, [domain, handleOnConnect, isDomainValid, isPluginNameValid, pluginName]);

  const selectLanguageModalContent = useMemo(() => {
    if (loadingLanguages) {
      return (
        <div className={styles.loadingLanguagesWrapper}>
          <Loader height={40} width={40} />
        </div>
      );
    }

    if (!languagesOptions?.length) {
      return (
        <div className={styles.selectLanguagesModalContent}>
          <p>Languages cannot be displayed</p>
        </div>
      );
    }

    return (
      <div className={styles.selectLanguagesModalContent}>
        <Input
          typeInput="select"
          inputTitle="Select language"
          options={languagesOptions}
          onChange={setSelectedLanguage}
          value={selectedLanguage}
        />
      </div>
    );
  }, [languagesOptions, loadingLanguages, selectedLanguage]);

  const selectLanguageModalActions = useMemo(() => {
    const isDisabled = !selectedLanguage;

    return (
      <Button
        variant="contained"
        title="Ok"
        onClick={handleSetOCLang}
        className={styles.connectButton}
        disabled={isDisabled}
      />
    );
  }, [handleSetOCLang, selectedLanguage]);

  useEffect(() => {
    const inventoryName =
      title === WORDPRESS_CMS
        ? "wp-inventory"
        : title === OPENCART_CMS
        ? "oc-inventory"
        : title === "YML Product Feed"
        ? "yml-inventory"
        : "xml-inventory";
    setInventoryName(inventoryName);
  }, [title]);

  return (
    <div className={styles.container}>
      {isLoading || isLoadingRefreshData ? (
        <div className={styles.loaderContainer}>
          <Loader width={40} height={40} />
        </div>
      ) : (
        integrationContent
      )}
      <Modal
        isShowModal={isModalOpen}
        setIsShowModal={setIsModalOpen}
        title={`Connect ${title}`}
        actions={modalActions}
      >
        {modalContent}
      </Modal>

      <Modal
        isShowModal={isModalSelectLanguageOpen}
        setIsShowModal={(isOpenStatus) => {
          setIsModalSelectLanguageOpen(isOpenStatus);
        }}
        title={"Select language"}
        actions={selectLanguageModalActions}
      >
        {selectLanguageModalContent}
      </Modal>
    </div>
  );
};
