import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import Switch from "react-switch";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { contentStyle } from "../../../constants/popups.js";
import "../../../assets/scss/modal.scss";
import styles from "./SetAssistantMetadataModal.module.css";

import supabase from "../../../supabase";
import { updateData } from "../../../service/supabase";
import { fetchInstructions } from "../../../store/slices/assistantSlice";
import { selectUser } from "../../../store/slices/authSlice";

export const SetAssistantMetadataModal = ({
  isOpen,
  onClose,
  metadata,
  assistantId,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const [switchStopWord, setSwitchStopWord] = useState("");
  const [silentSwitchToOperator, setSilentSwitchToOperator] = useState(false);

  useEffect(() => {
    setSwitchStopWord(metadata?.switch_stop_word || "");
    setSilentSwitchToOperator(metadata?.silent_switch_to_operator || false);
  }, [metadata]);

  const handleSave = async () => {
    try {
      // get old metadata
      const { data, error } = await supabase
        .from("assistants")
        .select("metadata")
        .eq("id", assistantId)
        .eq("account_id", user.account_id)
        .single();
      if (error) {
        throw new Error(error.message);
      }
      const assistant = data;
      if (!assistant) {
        throw new Error("No assistant found");
      }

      const oldMetadata = assistant.metadata || {};

      // 2) new metadata
      const newMetadata = {
        ...oldMetadata,
        switch_stop_word: switchStopWord,
        silent_switch_to_operator: silentSwitchToOperator,
      };

      // 3) update metadata
      await updateData(
        "assistants",
        { metadata: newMetadata },
        { id: assistantId, account_id: user.account_id }
      );

      dispatch(fetchInstructions(user.account_id));
      toast.success(
        "The settings for switching to the operator have been successfully saved!"
      );
      onClose();
    } catch (error) {
      toast.error(
        "Failed to save settings for switching to the operator. " + error
      );
    }
  };

  return (
    <Popup
      open={isOpen}
      closeOnDocumentClick
      onClose={onClose}
      {...{ contentStyle }}
    >
      <div className="modal">
        <h2 className="modalTitle">Switch to Operator</h2>
        <label htmlFor="switchStopWords" className="modalLabel">
          Keyword to switch to operator
        </label>
        <input
          type="text"
          className="modalInput"
          id="switchStopWords"
          placeholder="Enter switch stop word"
          value={switchStopWord}
          onChange={(e) => setSwitchStopWord(e.target.value)}
        />
        <div className={styles.modalToggle}>
          <label htmlFor="silentSwitchToOperator" className="modalLabel">
            Silent switching to the operator
          </label>
          <Switch
            id="silentSwitchToOperator"
            onChange={(checked) => setSilentSwitchToOperator(checked)}
            checked={silentSwitchToOperator}
            uncheckedIcon={false}
            checkedIcon={false}
            height={27}
            offColor="#F5777D"
            onColor="#3db44d"
          />
        </div>
        <div className="btns">
          <button type="button" className="modalBtn" onClick={onClose}>
            Close
          </button>
          <button
            type="button"
            onClick={handleSave}
            className="modalBtn saveBtn"
          >
            Save
          </button>
        </div>
      </div>
    </Popup>
  );
};
