import React, { useCallback, useEffect, useState } from "react";
import Switch from "react-switch";

import supabase from "../../../../supabase";

import styles from "./EnableBotForm.module.scss";
import { toast } from "react-toastify";

export const EnableBotForm = ({
  channel,
  matchConfig,
  onUpdate = () => {},
  onError = () => {},
  fetchChannels,
  setupChannelWebhook = () => {},
  children,
  setIsLoading = () => {},
  disabled,
}) => {
  const [isCheck, setIsCheck] = useState(false);

  const handleChange = useCallback(async () => {
    try {
      setIsLoading(true);
      if (!isCheck) {
        if (setupChannelWebhook) await setupChannelWebhook();
      } else {
        const { error } = await supabase
          .from("channels")
          .update({ is_on: false })
          .match(matchConfig);
        if (error) {
          throw new Error("Failed to turn off channel");
        }
      }
      setIsCheck(!isCheck);
      onUpdate();
    } catch (error) {
      toast.error(error.message);
      onError(error);
    } finally {
      await fetchChannels();
      setIsLoading(false);
    }
  }, [isCheck, setupChannelWebhook, channel, matchConfig, fetchChannels]);

  useEffect(() => {
    setIsCheck(channel?.is_on ?? false);
  }, [channel]);

  return (
    <div className={styles.enableBotWrapper}>
      <div className={styles.enableBot}>
        <Switch
          disabled={disabled}
          onChange={handleChange}
          checked={isCheck}
          uncheckedIcon={false}
          checkedIcon={false}
          height={27}
          offColor={"#E3E6EE"}
          onColor={"#3588E9"}
        />
        <div className={styles.enableBotText}>
          Connect assistant to this channel
        </div>
      </div>

      {children}
    </div>
  );
};
