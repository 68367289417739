import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NoOperators } from "../../../../components/NoOperators";
import { Title } from "../../../../components/Title";
import { Button } from "../../../../components/Button";
import { AddNewOperator } from "./AddNewOperator";
import profileWoman from "../../../../assets/img/profile.png";
import styles from "./Operators.module.scss";
import profileStyles from "../../Profile.module.scss";
import { SearchBar } from "../../../../components/SearchBar/SearchBar";
import { SelectedOperatorInfo } from "./SelectedOperatorInfo/SelectedOperatorInfo";
import {
  setSelectedOperatorIndex,
  fetchOperators,
} from "../../../../store/slices/operatorsSlice";
import { selectUser } from "../../../../store/slices/authSlice";
import { Loader } from "../../../../components";

export const RenderOperators = () => {
  const dispatch = useDispatch();
  const { operators, selectedOperatorIndex, status, error } = useSelector(
    (state) => state.operators
  );
  const [isAddingOperator, setIsAddingOperator] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const user = useSelector(selectUser);

  const accountId = user.account_id;
  const adminUserId = user.id;

  useEffect(() => {
    if (accountId && adminUserId) {
      dispatch(fetchOperators({ accountId, adminUserId }));
    }
  }, [dispatch, accountId, adminUserId]);

  const filteredOperators = operators?.filter((op) => {
    return op.full_name?.toLowerCase().includes(searchTerm?.toLowerCase());
  });

  const handleSelectOperator = (index) => {
    dispatch(setSelectedOperatorIndex(index));
  };

  const selectedOperator =
    selectedOperatorIndex !== null ? operators[selectedOperatorIndex] : null;

  if (isAddingOperator && user?.role !== "moderator") {
    return (
      <AddNewOperator
        onCancel={() => setIsAddingOperator(false)}
        accountId={accountId}
        adminUserId={adminUserId}
      />
    );
  }

  return (
    <>
      <div className={profileStyles.titleWrapper}>
        <Title title="Operators" />
        <Button
          variant="contained"
          title="Add Operator"
          onClick={() => setIsAddingOperator(true)}
        />
      </div>

      <div className={styles.operatorsInfo}>
        {status === "loading" ? (
          <div className={styles.loaderContainer}>
            <Loader height={40} width={40} />
          </div>
        ) : operators.length === 0 ? (
          <NoOperators
            title="No Operators Added Yet"
            description="Start by adding team members to manage chats efficiently"
            needAddOperatorBtn={true}
            onOperatorAdd={() => setIsAddingOperator(true)}
          />
        ) : (
          <div className={styles.operatorsContent}>
            <div className={styles.operatorsListWrapper}>
              <SearchBar
                searchValue={searchTerm}
                setSearchValue={(val) => setSearchTerm(val)}
                placeholder="Search operators"
              />
              <div className={styles.operatorsList}>
                {filteredOperators.map((op, index) => (
                  <div
                    key={op.id}
                    className={`${styles.operatorItem} ${
                      selectedOperatorIndex === index ? styles.selected : ""
                    }`}
                    onClick={() => handleSelectOperator(index)}
                  >
                    <img
                      src={op.avatarUrl || profileWoman}
                      alt="operator avatar"
                      width={40}
                      height={40}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = profileWoman;
                      }}
                    />
                    <div className={styles.operatorInfo}>
                      <span className={styles.operatorName}>
                        {op.full_name}
                      </span>
                      <span className={styles.operatorRole}>{op.role}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <SelectedOperatorInfo userId={adminUserId} />
          </div>
        )}
      </div>
    </>
  );
};
