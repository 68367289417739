import supabase from "../supabase";
import { toast } from "react-toastify";

// Function to initiate Telegram authentication
export const initiateTelegramAuth = async (params) => {
  try {
    const response = await fetch(
      "https://api.mychatbot.app/telegram/user-auth",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      toast.error(errorData.error || "Failed to initiate authentication");
    }

    return response;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
};

//  Polls Supabase to check if wait_code is true for the channel
export const pollForWaitCode = async (
  userId,
  assistantId,
  phone,
  password,
  tgAccountName
) => {
  try {
    const { data, error } = await supabase
      .from("channels")
      .select("*")
      .eq("account_id", userId)
      .eq("assistant_id", assistantId)
      .eq("communication_channel", "TelegramAccount")
      .filter("metadata->>phone", "eq", phone)
      .filter("metadata->>password", "eq", password)
      .filter("metadata->>tg_account_name", "eq", tgAccountName);

    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
};

// Updates the channel's metadata with the verification code and removes wait_code
export const submitVerificationCode = async (
  userId,
  assistantId,
  accessToken,
  updatedMetadata
) => {
  try {
    const { error } = await supabase
      .from("channels")
      .update({ metadata: updatedMetadata })
      .eq("account_id", userId)
      .eq("assistant_id", assistantId)
      .eq("access_token", accessToken)
      .eq("communication_channel", "TelegramAccount");

    if (error) {
      throw error;
    }
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
};

// Checks if the authorization process is complete by verifying if channel.is_on is not null
export const checkAuthorizationStatus = async (
  userId,
  assistantId,
  phone,
  tgAccountName
) => {
  try {
    const { data, error } = await supabase
      .from("channels")
      .select("*")
      .eq("account_id", userId)
      .eq("assistant_id", assistantId)
      .eq("communication_channel", "TelegramAccount")
      .filter("metadata->>phone", "eq", phone)
      .filter("metadata->>tg_account_name", "eq", tgAccountName);

    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
};

// Function to get existing channel
export const getExistingChannel = async (
  userId,
  assistantId,
  phone,
  password,
  tgAccountName
) => {
  try {
    const { data, error } = await supabase
      .from("channels")
      .select("*")
      .eq("account_id", userId)
      .eq("assistant_id", assistantId)
      .eq("communication_channel", "TelegramAccount")
      .filter("metadata->>phone", "eq", phone)
      .filter("metadata->>password", "eq", password)
      .filter("metadata->>tg_account_name", "eq", tgAccountName)
      .single();

    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    toast.error(error.message);
    throw error;
  }
};

// Function to delete a Telegram account
export const deleteTelegramAccount = async (
  userId,
  assistantId,
  accessToken
) => {
  try {
    const { error } = await supabase
      .from("channels")
      .delete()
      .eq("account_id", userId)
      .eq("assistant_id", assistantId)
      .eq("access_token", accessToken)
      .eq("communication_channel", "TelegramAccount");

    if (error) {
      throw error;
    }

    toast.success("Telegram account deleted successfully");
  } catch (error) {
    toast.error("Failed to delete Telegram account");
    console.error("Error deleting Telegram account:", error);
  }
};
