import { useState, useEffect } from "react";
import styles from "./IntegrationsPage.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { fetchInstructions as fetchAssistants } from "../../store/slices/assistantSlice";
import {
  IntegrationHeader,
  AvailableIntegrations,
  IntegrationSettings,
} from "./components";
import { fetchIntegrations } from "../../store/slices/integrationSlice";
import { LoadingProvider } from "./useLoadingContext";
import { useSubscription } from "../SubscriptionPage/useSubscription";
import { selectUser } from "../../store/slices/authSlice";
import { Loader } from "../../components";
import { normalizeSubscriptionPlan } from "../../helper";

export const IntegrationsPage = () => {
  const [subscriptionLoaded, setSubscriptionLoaded] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { subscriptionPlan, isFetchingSubscription } = useSubscription(user);
  const normalizedPlan = normalizeSubscriptionPlan(subscriptionPlan);

  useEffect(() => {
    // Checking if subscriptionPlan is loaded
    if (!isFetchingSubscription && normalizedPlan !== undefined) {
      setSubscriptionLoaded(true);
    }
  }, [normalizedPlan, isFetchingSubscription]);

  useEffect(() => {
    if (subscriptionLoaded) {
      dispatch(fetchAssistants());
      dispatch(fetchIntegrations());
    }
  }, [dispatch, subscriptionLoaded, subscriptionPlan]);

  if (!subscriptionLoaded || isFetchingSubscription) {
    return (
      <div className={styles.loaderContainer}>
        <Loader height={40} width={40} />
      </div>
    );
  }

  return (
    <LoadingProvider>
      <div className={styles.container}>
        <IntegrationHeader subscriptionPlan={normalizedPlan} />
        <div className={styles.contentWrapper}>
          <AvailableIntegrations subscriptionPlan={normalizedPlan} />
          <IntegrationSettings />
        </div>
      </div>
    </LoadingProvider>
  );
};
