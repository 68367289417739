export const Configurations = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 21.5C13.5275 21.5 16.4456 18.8908 16.9295 15.497C17.0075 14.9503 16.5523 14.5 16 14.5H11C10.4477 14.5 10 14.0523 10 13.5V8.5C10 7.94772 9.54972 7.4925 9.00297 7.57046C5.60923 8.05442 3 10.9725 3 14.5C3 18.366 6.13401 21.5 10 21.5Z"
        stroke="#384157"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M20 10.5C20.5523 10.5 21.0075 10.0497 20.9295 9.50297C20.492 6.43489 18.0651 4.00798 14.997 3.57046C14.4503 3.4925 14 3.94772 14 4.5V9.5C14 10.0523 14.4477 10.5 15 10.5H20Z"
        stroke="#384157"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};
