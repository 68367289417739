import { Input } from "../../../../../../components";
import React from "react";
import styles from "./WpWidgetInputs.module.scss";
import useCMSWidgetDataContext from "../../useCMSWidgetDataContext";

import {
  languageOptions,
  assistantAvatarOptions,
} from "../../../../../../../src/constants/channels";
import { sanitizeValue } from "../../../../../../utils";

const isOnlyNumbers = (input) => {
  if (!input) return false;

  // Allows both integers and decimal numbers
  return /^\d*\.?\d*$/.test(input?.trim());
};

const WpWidgetInputs = () => {
  const {
    widgetName,
    setWidgetName,
    greetingText,
    setGreetingText,
    buttonText,
    setButtonText,
    isWidgetNameValid,
    setIsWidgetNameValid,
    isDownloaded,
    welcomeText,
    setWelcomeText,
    buttonMargin,
    setButtonMargin,
    language,
    setLanguage,
    logoUrl,
    setLogoUrl,
    assistant,
    setAssistant,
    scale,
    setScale,
  } = useCMSWidgetDataContext();

  const handleWidgetNameChange = (e) => {
    const value = sanitizeValue(e.target.value);

    // Remove non-ASCII characters and spaces
    const filteredValue = value.replace(/[^!-~]+/g, "");

    setWidgetName(filteredValue);

    // Update validity state
    if (filteredValue !== value) {
      setIsWidgetNameValid(false);
    } else {
      setIsWidgetNameValid(true);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key.length === 1) {
      const asciiNoSpaceRegex = /^[\x21-\x7E]$/;
      if (!asciiNoSpaceRegex.test(e.key)) {
        e.preventDefault();
      }
    }
  };

  const handleInputWithNumbers =
    (setValue) =>
    ({ target }) => {
      if (target.value === "") setValue("");

      if (isOnlyNumbers(target.value)) {
        setValue(target.value);
      }
    };

  return (
    <div className={styles.inputsContainer}>
      <div>
        <Input
          inputTitle="Widget name"
          placeholder="Plugin for My Site (no spaces)"
          customClass={styles.input}
          value={widgetName}
          onChange={handleWidgetNameChange}
          onKeyPress={handleKeyPress}
          errorText={
            isWidgetNameValid
              ? ""
              : "Please enter only letters and numbers, without any spaces or special characters"
          }
          disabled={isDownloaded}
        />
      </div>
      {/*<div>*/}
      {/*  <Input*/}
      {/*    inputTitle="First greeting message"*/}
      {/*    placeholder="Hello! Let's talk :)"*/}
      {/*    customClass={styles.input}*/}
      {/*    value={greetingText}*/}
      {/*    onChange={(e) => setGreetingText(e.target.value)}*/}
      {/*    disabled={isDownloaded}*/}
      {/*  />*/}
      {/*</div>*/}
      <div>
        <Input
          inputTitle="Widget element pop-up text"
          placeholder="Сontact a manager"
          customClass={styles.input}
          value={buttonText}
          onChange={(e) => setButtonText(e.target.value)}
          disabled={isDownloaded}
        />
      </div>
      <div>
        <Input
          disabled={isDownloaded}
          inputTitle="Welcome text"
          placeholder="Write a welcome text"
          customClass={styles.input}
          value={welcomeText}
          onChange={(e) => setWelcomeText(e.target.value)}
        />
      </div>
      <div>
        <Input
          disabled={isDownloaded}
          inputTitle="Logo link"
          placeholder="Logo link"
          customClass={styles.input}
          value={logoUrl}
          onChange={(e) => setLogoUrl(e.target.value)}
        />
      </div>
      <div className={styles.groupInputs}>
        <div>
          <Input
            disabled={isDownloaded}
            inputTitle="Button margin"
            placeholder="Button margin"
            customClass={styles.input}
            value={buttonMargin}
            onChange={handleInputWithNumbers(setButtonMargin)}
          />
        </div>
        <div>
          <Input
            disabled={isDownloaded}
            inputTitle="Scale"
            placeholder={"Scale"}
            customClass={styles.input}
            value={scale}
            onChange={handleInputWithNumbers(setScale)}
          />
        </div>
        <div>
          <Input
            disabled={isDownloaded}
            inputTitle="Language"
            placeholder="Select a language"
            customClass={styles.input}
            typeInput="select"
            value={language}
            options={languageOptions}
            onChange={setLanguage}
          />
        </div>
        <div>
          <Input
            disabled={isDownloaded}
            inputTitle="Assistant avatar"
            placeholder="Select a assistant avatar"
            customClass={styles.input}
            typeInput="select"
            options={assistantAvatarOptions}
            value={assistant}
            onChange={setAssistant}
          />
        </div>
      </div>
    </div>
  );
};

export default WpWidgetInputs;
