import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { FiEye, FiEyeOff } from "react-icons/fi";
import styles from "../Profile.module.scss";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { logout } from "../../../store/slices/authSlice";
import { validatePassword } from "../../../store/slices/profileSlice";
import { useWindowWidth } from "../../../hooks/useWindowWidth";

export const RenderSecurityBlock = ({
  showCurrentPassword = true,
  isChangePassword,
  currentPassword,
  setCurrentPassword,
  newPassword,
  setNewPassword,
  confirmNewPassword,
  setConfirmNewPassword,
  handleCancelChangePassword,
  handleChangePasswordClick,
  passwordErrors = {},
  isOperator = false,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const windowWidth = useWindowWidth();
  const navigate = useNavigate();

  const [showCurrentPasswordState, setShowCurrentPasswordState] =
    useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleShowCurrentPassword = () =>
    setShowCurrentPasswordState(!showCurrentPasswordState);
  const toggleShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const handleSaveNewPasswordInternal = async () => {
    const isValid = await dispatch(
      validatePassword({
        currentPassword,
        newPassword,
        confirmNewPassword,
      })
    ).unwrap();

    if (!isValid) {
      toast.error("Please fix the passwords errors!");
      return;
    }

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/change-password`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            user_id: user.id,
            current_password: currentPassword,
            new_password: newPassword,
          }),
        }
      );
      console.log(res);
      const data = await res.json();
      if (res.ok) {
        toast.success("Password changed successfully!");
        dispatch(logout());
        navigate(ROUTES.loginPage);
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      toast.error(`${error.message || error}`);
    }
  };

  if (!isChangePassword) {
    const rowWidth = windowWidth < 667 || isOperator ? "100%" : "49%";

    return (
      <div className={classNames(styles.details, styles.passwordWrapper)}>
        <div className={styles.row} style={{ width: rowWidth }}>
          <div className={styles.field}>
            <label>Password</label>
            <input
              type="password"
              value="********"
              disabled
              className={styles.passwordInput}
            />
          </div>
          <button
            className={styles.borderBtn}
            onClick={handleChangePasswordClick}
          >
            Change Password
          </button>
        </div>
      </div>
    );
  }

  const rowWidth = windowWidth > 667 || isOperator ? "100%" : "49%";

  return (
    <div
      className={classNames(styles.details, styles.passwordWrapper, {
        [styles.operator]: isOperator,
      })}
    >
      {isOperator && (
        <div className={styles.titleChangePassword}>Change Password</div>
      )}
      <div className={styles.row} style={{ width: rowWidth }}>
        {showCurrentPassword && (
          <div className={styles.field}>
            <label>Current password</label>
            <div className={styles.passwordField}>
              <input
                type={showCurrentPasswordState ? "text" : "password"}
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                placeholder="Enter current password"
              />
              <span
                className={styles.eyeIcon}
                onClick={toggleShowCurrentPassword}
                aria-label="Toggle password visibility"
              >
                {showCurrentPasswordState ? (
                  <FiEye size={"2rem"} />
                ) : (
                  <FiEyeOff size={"2rem"} />
                )}
              </span>
            </div>
            {passwordErrors.currentPassword && (
              <p className={styles.errorText}>
                {passwordErrors.currentPassword}
              </p>
            )}
          </div>
        )}

        <div className={styles.field}>
          <label>New password</label>
          <div className={styles.passwordField}>
            <input
              type={showNewPassword ? "text" : "password"}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Enter new password"
            />
            <span
              className={styles.eyeIcon}
              onClick={toggleShowNewPassword}
              aria-label="Toggle password visibility"
            >
              {showNewPassword ? (
                <FiEye size={"2rem"} />
              ) : (
                <FiEyeOff size={"2rem"} />
              )}
            </span>
          </div>
          {passwordErrors.newPassword && (
            <p className={styles.errorText}>{passwordErrors.newPassword}</p>
          )}
        </div>
      </div>

      <div className={styles.row} style={{ width: rowWidth }}>
        <div className={styles.field}>
          <label>Confirm new password</label>
          <div className={styles.passwordField}>
            <input
              type={showConfirmPassword ? "text" : "password"}
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              placeholder="Confirm new password"
            />
            <span
              className={styles.eyeIcon}
              onClick={toggleShowConfirmPassword}
              aria-label="Toggle password visibility"
            >
              {showConfirmPassword ? (
                <FiEye size={"2rem"} />
              ) : (
                <FiEyeOff size={"2rem"} />
              )}
            </span>
          </div>
          {passwordErrors.confirmPassword && (
            <p className={styles.errorText}>{passwordErrors.confirmPassword}</p>
          )}
        </div>

        <div className={classNames(styles.field, styles.btnWrapper)}>
          <button
            className={styles.borderBtn}
            onClick={handleCancelChangePassword}
          >
            Cancel
          </button>
          <button
            className={styles.uploadBtn}
            onClick={handleSaveNewPasswordInternal}
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  );
};
