export const Integrations = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.4546 17.8429C20.7877 18.118 21 18.5342 21 19C21 19.8284 20.3284 20.5 19.5 20.5C19.1107 20.5 18.7561 20.3517 18.4895 20.1086C18.1887 19.8342 18 19.4392 18 19C18 18.1716 18.6716 17.5 19.5 17.5C19.8626 17.5 20.1952 17.6287 20.4546 17.8429ZM20.4546 17.8429C21.4333 16.2973 22 14.4648 22 12.5C22 6.97715 17.5228 2.5 12 2.5M3.54545 7.15713C3.8048 7.37132 4.13737 7.5 4.5 7.5C5.32843 7.5 6 6.82843 6 6C6 5.56084 5.81127 5.16576 5.5105 4.89144C5.2439 4.64828 4.88927 4.5 4.5 4.5C3.67157 4.5 3 5.17157 3 6C3 6.4658 3.21231 6.88201 3.54545 7.15713ZM3.54545 7.15713C2.56665 8.70274 2 10.5352 2 12.5C2 18.0228 6.47715 22.5 12 22.5M11.4543 8.68724L8.34316 11.107C8.12664 11.2754 8 11.5343 8 11.8086V15.6111C8 16.102 8.39797 16.5 8.88889 16.5H15.1111C15.602 16.5 16 16.102 16 15.6111V11.8086C16 11.5343 15.8734 11.2754 15.6568 11.107L12.5457 8.68724C12.3865 8.56337 12.1956 8.50096 12.0044 8.50001C11.8103 8.49904 11.616 8.56145 11.4543 8.68724Z"
        stroke="#384157"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
