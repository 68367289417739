import React, { useEffect, useState, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getData } from "../../service/supabase.js";
import supabase from "../../supabase";
import { toast } from "react-toastify";
import { NoConversation } from "../../components/NoConversation";
import { Title } from "../../components/Title";
import { Channel } from "../../components/Channel";
import { Button } from "../../components/Button";
import { Modal } from "../../components/Modal";
import {
  Instagram,
  Messenger,
  Telegram,
  TelegramAccounts,
  Viber,
  WhatsApp,
  WpWidget,
  OLXAccount,
  OcWidget,
  SwWidget,
} from "./components";

import { Loader } from "../../components/Loader";
import { Input } from "../../components/Input";
import channel from "../../assets/img/channel.png";

import styles from "./Channels.module.scss";
import "./fixStyle.scss";

import { ROUTES } from "../../constants/routes";
import { CHANNELS } from "../../constants/channels";
import {
  getDefaultStatus,
  getStatusColor,
  getOlxAccountStatus,
  getTelegramAccountStatus,
} from "./helpers";

export const ChannelsPage = () => {
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const windowWidth = window.innerWidth;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const assistantIdParam = searchParams.get("assistantId");
  const hasRedirected = useRef(false);

  const step = useSelector((state) => state.onboarding.step);
  const user = useSelector((state) => state.auth.user);

  const [checked, setChecked] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [isShowModal, setIsShowModal] = useState(false);
  const [modalError, setModalError] = useState("");
  const [modalErrorDescription, setModalErrorDescription] = useState("");
  const [channels, setChannels] = useState(CHANNELS);
  const [channelsData, setChannelsData] = useState([]);
  const [selectedChannelData, setSelectedChannelData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [listOfAssistants, setListOfAssistants] = useState([]);
  const [selectedAssistant, setSelectedAssistant] = useState({});

  const handleClick = () => {
    navigate(ROUTES.dashboardPage);
  };

  const fetchInstructions = async () => {
    const { data: instruction } = await getData(
      "assistants",
      "*",
      "account_id",
      user.account_id
    );

    if (!instruction?.length) return;
    setListOfAssistants(instruction?.sort((a, b) => a.id - b.id));

    let selectedAssistantData;

    if (assistantIdParam) {
      selectedAssistantData = instruction.find(
        (assistant) => assistant.id === parseInt(assistantIdParam)
      );
    }

    if (!selectedAssistantData) {
      selectedAssistantData = instruction[0];
    }

    const { bot_name, id } = selectedAssistantData;
    setSelectedAssistant({ label: bot_name, value: id });
  };

  useEffect(() => {
    if (assistantIdParam) {
      const assistant = listOfAssistants.find(
        (a) => a.id === parseInt(assistantIdParam)
      );
      if (assistant) {
        setSelectedAssistant({
          label: assistant.bot_name,
          value: assistant.id,
        });
      }
    }
  }, [assistantIdParam, listOfAssistants]);

  const fetchData = async () => {
    try {
      const { data, status } = await supabase
        .from("channels")
        .select("*")
        .eq("account_id", user.account_id)
        .eq("assistant_id", selectedAssistant.value);

      if (data?.length !== 0 && status === 200) {
        setChannelsData(data);
        const updatedChannels = CHANNELS.map((channel) => {
          const matchedChannel = data.find(
            ({ communication_channel }) =>
              channel.value === communication_channel
          );

          const olxAccounts = data.filter(
            ({ communication_channel, is_on }) =>
              communication_channel === "OLXAccount" && is_on
          );

          const telegramAccounts = data.filter(
            ({ communication_channel, is_on }) =>
              communication_channel === "TelegramAccount" && is_on
          );

          const statusName = matchedChannel
            ? channel.value === "OLXAccount"
              ? getOlxAccountStatus(olxAccounts)
              : channel.value === "TelegramAccount"
              ? getTelegramAccountStatus(telegramAccounts)
              : getDefaultStatus(matchedChannel, channel)
            : "Connect";

          const statusColor = getStatusColor(
            matchedChannel,
            olxAccounts,
            channel
          );

          return {
            ...channel,
            status: {
              color: statusColor,
              name: statusName,
            },
          };
        });

        setChannels(updatedChannels);
      } else {
        setChannels(CHANNELS);
        setChannelsData([]);
      }
    } catch (error) {
      console.error(error);
      // Set the error state to display in the modal
      setModalError("Error");
      setModalErrorDescription(error.message);
      setIsShowModal(true);
    }
  };

  const fetchSubscription = async () => {
    try {
      setIsLoading(true);
      const { data } = await supabase
        .from("subscriptions")
        .select("*")
        .eq("email", user.email);

      setChecked(!!data.length);

      setSubscriptionPlan(data[0]?.subscription_plan);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateListOfAssistants = useMemo(() => {
    return listOfAssistants
      .map(({ id, bot_name }) => ({
        value: id,
        label: bot_name,
      }))
      .toSorted((a, b) => a.value - b.value);
  }, [listOfAssistants]);

  useEffect(() => {
    fetchInstructions();
  }, [searchParams]);

  useEffect(() => {
    if (!selectedAssistant.value) return;

    fetchData();
  }, [selectedAssistant]);

  useEffect(() => {
    const tab = searchParams.get("tab");

    if (tab) {
      setActiveTab(tab);
    }
  }, []);

  useEffect(() => {
    fetchSubscription();
  }, []);

  useEffect(() => {
    setSelectedChannelData(
      channelsData.filter(
        (channel) => channel.communication_channel === activeTab
      )
    );
  }, [activeTab, channelsData]);

  const pageTitle = useMemo(() => {
    if (!selectedAssistant?.label) return "Channels";
    return `Channels for ${selectedAssistant.label}`;
  }, [selectedAssistant]);

  const renderPages = () => {
    const Component = {
      Telegram,
      TelegramAccount: TelegramAccounts,
      Viber,
      WhatsApp,
      Instagram,
      Messenger,
      WpWidget,
      OcWidget,
      SwWidget,
      OLXAccount,
    }[activeTab];

    if (Component)
      return (
        <Component
          key={selectedChannelData}
          channelData={selectedChannelData}
          fetchChannels={fetchData}
          assistantId={selectedAssistant.value}
        />
      );
    return (
      <div className={styles.emptyChannel}>
        <Title title={"Select channels to connect"} />
      </div>
    );
  };

  const disabledAssistantSelect =
    subscriptionPlan === null ||
    subscriptionPlan === "standard" ||
    subscriptionPlan == "pro";

  useEffect(() => {
    if (
      subscriptionPlan &&
      subscriptionPlan !== "multipro" &&
      subscriptionPlan !== "corporate" &&
      subscriptionPlan !== "multipro-trial" &&
      listOfAssistants.length > 0
    ) {
      const firstAssistant = listOfAssistants[0];
      if (
        selectedAssistant.value !== firstAssistant?.id &&
        !hasRedirected.current
      ) {
        hasRedirected.current = true;
        // Redirect to the first assistant URL
        navigate(`/channels?assistantId=${firstAssistant.id}`, {
          replace: true,
        });
        toast.info(
          `Your subscription plan does not allow using multiple assistants. Redirected to the first assistant ${firstAssistant.bot_name}.`
        );
      }
    }
  }, [subscriptionPlan, selectedAssistant, listOfAssistants, navigate]);

  return (
    <div className={styles.container}>
      <div className={`${styles.top} top`}>
        <Title title={pageTitle} />
        <Input
          typeInput={"select"}
          placeholder={"Select an assistant"}
          options={updateListOfAssistants}
          value={selectedAssistant}
          onChange={(selectedOption) => {
            setSelectedAssistant(selectedOption);
            navigate(
              `${ROUTES.channelsPage}?assistantId=${selectedOption.value}`
            );
          }}
          disabled={disabledAssistantSelect}
        />
      </div>
      {!checked ? (
        isLoading ? (
          <div
            className={styles.loaderContainer}
            style={{
              height:
                windowWidth >= 666
                  ? step === 6
                    ? "100vh"
                    : "calc(100vh - 18rem)"
                  : "",
              maxHeight:
                windowWidth >= 666
                  ? step === 6
                    ? ""
                    : "calc(100vh - 18rem)"
                  : "",
            }}
          >
            <Loader width={60} height={60} />
          </div>
        ) : (
          <div className={styles.wrapper}>
            <div className={styles.integrations}>
              <div className={`${styles.title} ${styles.active}`}>
                <Title title={"Channels"} />
              </div>
              <div className={styles.modalContainer}>
                <div className={styles.modal}>
                  <div className={styles.textModal}>
                    Start free trial to connect your bot to channels
                  </div>
                  <Button
                    onClick={handleClick}
                    className={styles.buttonModal}
                    title={"Start free trial"}
                  />
                </div>
              </div>
              <div className={`${styles.channel} ${styles.active}`}>
                {channels.map((i, index) => {
                  return (
                    <Channel
                      onClick={() => {
                        if (
                          subscriptionPlan !== null ||
                          i.value === "TelegramAccount"
                        ) {
                          setActiveTab(i.value);
                        }
                      }}
                      activeTab={activeTab}
                      key={index}
                      title={i.name}
                      icon={i.icon}
                      value={i.value}
                      status={i.status}
                      subscriptionPlan={subscriptionPlan}
                    />
                  );
                })}
              </div>
            </div>
            <div className={styles.emptyContainer}>
              <img src={channel} alt="" />
              <div className={styles.textModal}>
                Select to channel to connect
              </div>
            </div>

            <Modal
              isShowModal={isShowModal}
              title={modalError}
              description={modalErrorDescription}
              setIsShowModal={setIsShowModal}
            />
          </div>
        )
      ) : step >= 4 ? (
        isLoading ? (
          <div
            className={styles.loaderContainer}
            style={{
              height:
                windowWidth >= 666
                  ? step === 6
                    ? "100vh"
                    : "calc(100vh - 18rem)"
                  : "",
              maxHeight:
                windowWidth >= 666
                  ? step === 6
                    ? ""
                    : "calc(100vh - 18rem)"
                  : "",
            }}
          >
            <Loader width={60} height={60} />
          </div>
        ) : (
          <div className={styles.wrapper}>
            <div className={styles.integrations}>
              <div className={`${styles.channel}`}>
                {channels.map((i, index) => {
                  return (
                    <Channel
                      onClick={() => {
                        if (subscriptionPlan !== null) {
                          setActiveTab(i.value);
                        }
                      }}
                      activeTab={activeTab}
                      key={index}
                      title={i.name}
                      icon={i.icon}
                      value={i.value}
                      status={i.status}
                      subscriptionPlan={subscriptionPlan}
                    />
                  );
                })}
              </div>
            </div>
            <div className={styles.connectionTab}>{renderPages()}</div>

            {/* Display Modal if there is an error */}
            <Modal
              isShowModal={isShowModal}
              title={modalError}
              description={modalErrorDescription}
              setIsShowModal={setIsShowModal}
            />
          </div>
        )
      ) : (
        <NoConversation
          title={"No data"}
          description={
            "You’ll see your dashboard as soon as your bot starts messaging with customers"
          }
        />
      )}
    </div>
  );
};
