import React from "react";
import { MdToHTML } from "../MdToHTML";
import styles from "./FunctionResultMessage.module.scss";
import { TbFunction } from "react-icons/tb";
import { CollapsibleMessage } from "../CollapsibleMessage/CollapsibleMessage";

export const FunctionResultMessage = ({
  message,
  index,
  collapseStates,
  onToggleCollapse,
}) => {
  const resultCollapseKey = `msgIndex_${index}_function_result`;
  const isExpanded = Boolean(collapseStates[resultCollapseKey]);
  const isLongContent = message.content.length > 400;

  return (
    <div className={styles.functionResultContainer}>
      <div
        className={styles.functionCallHeader}
        onClick={() => onToggleCollapse(resultCollapseKey)}
      >
        <span className={styles.functionCallLabel}>
          <TbFunction size={18} /> Result
        </span>
        <span className={styles.functionCallName}>{message.name}</span>
        <button className={styles.collapseButton}>
          {isExpanded ? <span>🔽</span> : <span>🔼</span>}
        </button>
      </div>
      <div
        className={styles.functionCallBody}
        style={{
          maxHeight: isExpanded ? "700vh" : "0px",
          transition: "max-height 0.5s ease-in-out",
        }}
      >
        {isExpanded &&
          (isLongContent ? (
            <CollapsibleMessage content={message.content} maxLength={400} />
          ) : (
            <MdToHTML content={message.content} />
          ))}
      </div>
    </div>
  );
};
