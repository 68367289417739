export const Operator = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 14H7C4.23858 14 2 16.2386 2 19V20C2 20.5523 2.44772 21 3 21H15C15.5523 21 16 20.5523 16 20V19C16 16.2386 13.7614 14 11 14Z"
        stroke="#384157"
        strokeWidth="2"
      />
      <path
        d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z"
        stroke="#384157"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 4C15.8954 4 15 4.89543 15 6V9V12.5C15 12.7761 15.2347 12.9976 15.474 12.8598C16.0253 12.5423 16.5256 11.7379 16.7939 10.9895C16.8617 10.9964 16.9304 11 17 11H21C22.1046 11 23 10.1046 23 9V6C23 4.89543 22.1046 4 21 4H17ZM17 9H21V6H17V9Z"
        fill="#384157"
      />
    </svg>
  );
};
