import { useCallback, useRef, useEffect } from "react";

export const useScrollRestoration = (elementRef, storageKey) => {
  const restorationAttempts = useRef(0);
  const maxAttempts = 10;
  const attemptInterval = 200;
  const isRestoringScroll = useRef(false);
  const hasInitiallyRestored = useRef(false);
  const lastKnownScrollPosition = useRef(0);

  const saveScrollPosition = useCallback(() => {
    if (elementRef.current && !isRestoringScroll.current) {
      const scrollPosition = elementRef.current.scrollTop;

      if (Math.abs(scrollPosition - lastKnownScrollPosition.current) > 5) {
        const scrollData = {
          position: scrollPosition,
        };
        localStorage.setItem(storageKey, JSON.stringify(scrollData));
        lastKnownScrollPosition.current = scrollPosition;
      }
    }
  }, [elementRef, storageKey]);

  const restoreScrollPosition = useCallback(() => {
    if (!elementRef.current || isRestoringScroll.current) return;

    const savedScrollData = localStorage.getItem(storageKey);
    if (!savedScrollData) return;

    try {
      const { position } = JSON.parse(savedScrollData);

      const attemptRestore = () => {
        if (restorationAttempts.current >= maxAttempts) {
          isRestoringScroll.current = false;
          restorationAttempts.current = 0;
          return;
        }

        if (elementRef.current) {
          const currentHeight = elementRef.current.scrollHeight;

          if (currentHeight > position) {
            isRestoringScroll.current = true;
            elementRef.current.scrollTop = position;
            lastKnownScrollPosition.current = position;

            setTimeout(() => {
              if (
                elementRef.current &&
                Math.abs(elementRef.current.scrollTop - position) > 1
              ) {
                restorationAttempts.current++;
                setTimeout(attemptRestore, attemptInterval);
              } else {
                isRestoringScroll.current = false;
                restorationAttempts.current = 0;
                hasInitiallyRestored.current = true;
              }
            }, 50);
          } else {
            restorationAttempts.current++;
            setTimeout(attemptRestore, attemptInterval);
          }
        }
      };

      attemptRestore();
    } catch (error) {
      console.error("Error restoring scroll position:", error);
      localStorage.removeItem(storageKey);
    }
  }, [elementRef, storageKey, maxAttempts, attemptInterval]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      saveScrollPosition();
    };

    const saveInterval = setInterval(saveScrollPosition, 250);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      clearInterval(saveInterval);
      window.removeEventListener("beforeunload", handleBeforeUnload);
      saveScrollPosition();
    };
  }, [saveScrollPosition]);

  useEffect(() => {
    if (!elementRef.current) return;

    const observer = new MutationObserver((mutations) => {
      const hasContentChanges = mutations.some(
        (mutation) =>
          mutation.type === "childList" ||
          mutation.type === "subtree" ||
          (mutation.type === "attributes" && mutation.attributeName === "style")
      );

      if (hasContentChanges && !isRestoringScroll.current) {
        restoreScrollPosition();
      }
    });

    observer.observe(elementRef.current, {
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ["style"],
    });

    return () => observer.disconnect();
  }, [elementRef, restoreScrollPosition]);

  return { saveScrollPosition, restoreScrollPosition };
};
