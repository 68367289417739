import { toast } from "react-toastify";

export const formatDate = (isoDateString) => {
  let date = new Date(isoDateString);

  let hours = date.getHours();
  let minutes = String(date.getMinutes()).padStart(2, "0");

  let amOrPm = hours < 12 ? "AM" : "PM";

  if (hours > 12) {
    hours -= 12;
  } else if (hours === 0) {
    hours = 12;
  }

  hours = String(hours).padStart(2, "0");

  return `${hours}:${minutes} ${amOrPm}`;
};

export const handleCopyLink = (link) => {
  navigator.clipboard.writeText(link).then(
    () => {
      toast.success("Link copied to clipboard!");
    },
    (err) => {
      toast.error("Failed to copy the link.");
    }
  );
};

export const sanitizeDomain = (domain) => {
  if (!domain) {
    return "";
  }

  let sanitizedDomain = domain?.trim();
  // Remove any trailing slashes
  sanitizedDomain = sanitizedDomain.replace(/\/+$/, "");
  // If domain does not start with 'http://' or 'https://', add 'https://'
  if (!/^https?:\/\//i.test(sanitizedDomain)) {
    sanitizedDomain = "https://" + sanitizedDomain;
  }
  return sanitizedDomain;
};

export const formatDateSubscription = (isoDateString) => {
  // Parse the ISO date string into a JavaScript Date object
  const date = new Date(isoDateString);
  // Define an array of month names
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[date.getUTCMonth()];
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  return `${month} ${day}, ${year}`;
};

export const getSubscriptionAmount = (plan, period) => {
  const pricing = {
    standard: { monthly: 49, yearly: 45 * 12 },
    pro: { monthly: 99, yearly: 89 * 12 },
    multipro: { monthly: 199, yearly: 179 * 12 },
    corporate: { yearly: 2999 },
  };
  return pricing[plan.toLowerCase()][period];
};

export const normalizeSubscriptionPlan = (plan) => {
  if (plan === "multipro-trial") {
    return "multipro";
  }
  return plan;
};
