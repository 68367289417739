import React from "react";
import Switch from "react-switch";
import { Button } from "../../../../components";
import { QuickCommentReplyInput } from "../../../../components/QuickCommentReplyInput/QuickCommentReply";
import styles from "./ChannelMetadataSettings.module.scss";

export const ChannelMetadataSettings = ({
  toggles = [],
  inputs = [],
  onSave,
  isLoading,
}) => {
  return (
    <div className={styles.metadataSettings}>
      {toggles.map(({ label, checked, onChange, id }) => (
        <div key={id} className={styles.switchContainer}>
          <label htmlFor={id} className={styles.switchLabel}>
            {label}
          </label>
          <Switch
            id={id}
            onChange={onChange}
            checked={checked}
            offColor="#F5777D"
            onColor="#3db44d"
            uncheckedIcon={false}
            checkedIcon={false}
            height={20}
            width={48}
          />
        </div>
      ))}

      {inputs.map(({ label, value, onChange, id, placeholder }) => (
        <QuickCommentReplyInput
          key={id}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
      ))}
      <div className={styles.btnContainer}>
        <Button
          variant="contained"
          title={isLoading ? "Saving..." : "Save metadata"}
          onClick={onSave}
          disabled={isLoading}
        />
      </div>
    </div>
  );
};
