export const Dashboard = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 3.5H5C3.89543 3.5 3 4.39543 3 5.5V8.5C3 9.60457 3.89543 10.5 5 10.5H8C9.10457 10.5 10 9.60457 10 8.5V5.5C10 4.39543 9.10457 3.5 8 3.5Z"
        stroke="#384157"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 3.5H16C14.8954 3.5 14 4.39543 14 5.5V8.5C14 9.60457 14.8954 10.5 16 10.5H19C20.1046 10.5 21 9.60457 21 8.5V5.5C21 4.39543 20.1046 3.5 19 3.5Z"
        stroke="#384157"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 14.5H16C14.8954 14.5 14 15.3954 14 16.5V19.5C14 20.6046 14.8954 21.5 16 21.5H19C20.1046 21.5 21 20.6046 21 19.5V16.5C21 15.3954 20.1046 14.5 19 14.5Z"
        stroke="#384157"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 14.5H5C3.89543 14.5 3 15.3954 3 16.5V19.5C3 20.6046 3.89543 21.5 5 21.5H8C9.10457 21.5 10 20.6046 10 19.5V16.5C10 15.3954 9.10457 14.5 8 14.5Z"
        stroke="#384157"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
