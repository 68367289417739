import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import supabase from "../../../../supabase";
import {
  useRemoveChannelMutation,
  useSetupWebhookMutation,
} from "../../../../store/api";
import { Title } from "../../../../components/Title";
import { Button } from "../../../../components/Button";
import { Loader } from "../../../../components/Loader";
import styles from "./Messenger.module.scss";
import { ReplayDelayInput } from "../ReplyDelayInput";
import { EnableBotForm } from "../EnableBotForm";
import { toast } from "react-toastify";
import { ChannelMetadataSettings } from "../ChannelMetadataSettings/ChannelMetadataSettings";

export const Messenger = ({ channelData, fetchChannels, assistantId }) => {
  const channel = channelData[0];
  const user = useSelector((state) => state.auth.user);

  const [isLoading, setIsLoading] = useState(false);
  const [ignoreReactions, setIgnoreReactions] = useState(false);
  const [quickCommentReply, setQuickCommentReply] = useState("");

  const [removeChannel] = useRemoveChannelMutation();
  const [setupWebhook] = useSetupWebhookMutation();

  useEffect(() => {
    if (!channel) return;
    const metadata = channel.metadata || {};
    setIgnoreReactions(metadata.ignore_reactions ?? false);
    setQuickCommentReply(metadata.quick_comment_reply ?? "");
  }, [channel]);

  const updateChannelMetadata = useCallback(
    async (newMetadata) => {
      if (!channel) return;
      setIsLoading(true);
      try {
        const updatedMetadata = {
          ...(channel.metadata || {}),
          ...newMetadata,
        };

        const { error } = await supabase
          .from("channels")
          .update({ metadata: updatedMetadata })
          .match({
            account_id: user.account_id,
            assistant_id: assistantId,
            communication_channel: "Messenger",
          });

        if (error) {
          throw new Error(error.message);
        }

        if (fetchChannels) {
          await fetchChannels();
        }

        toast.success("Messenger metadata updated!");
      } catch (err) {
        toast.error(`Failed to update metadata: ${err.message}`);
      } finally {
        setIsLoading(false);
      }
    },
    [channel, user.account_id, assistantId, fetchChannels]
  );

  const handleSaveMetadata = async () => {
    await updateChannelMetadata({
      ignore_reactions: ignoreReactions,
      quick_comment_reply: quickCommentReply,
    });
  };

  const openMessengerAuth = () => {
    const state = `${user.account_id}|${assistantId}`;

    window.location.href = `https://www.facebook.com/v17.0/dialog/oauth?client_id=${
      process.env.REACT_APP_FACEBOOK_CLIENT_ID
    }&display=popup&redirect_uri=${encodeURIComponent(
      process.env.REACT_APP_DOMAIN + "/oauth/messenger"
    )}&response_type=code&config_id=559494326980040&state=${encodeURIComponent(
      state
    )}`;
  };

  const setupChannelWebhook = async (channelName) => {
    const { error } = await setupWebhook({
      channel: channelName,
      account_id: user.account_id,
      assistant_id: assistantId,
      ...(channel?.page_id && { page_id: channel?.page_id }),
    });
    if (error) {
      throw new Error("Failed to setup webhook");
    }
  };

  const revokeToken = async () => {
    try {
      setIsLoading(true);
      const { error: supabaseError } = await supabase
        .from("channels")
        .delete()
        .eq("account_id", user.account_id)
        .eq("communication_channel", "Messenger")
        .eq("assistant_id", assistantId);

      if (supabaseError) {
        throw new Error("Failed to remove channel");
      }
      await fetchChannels();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Title title={"Messenger"} />

      <EnableBotForm
        disabled={!channel?.access_token}
        channel={channel}
        matchConfig={{
          account_id: user.account_id,
          communication_channel: "Messenger",
          assistant_id: assistantId,
        }}
        setupChannelWebhook={async () => await setupChannelWebhook("Messenger")}
        setIsLoading={setIsLoading}
        fetchChannels={fetchChannels}
      >
        <ReplayDelayInput
          disabled={!channel?.access_token}
          channelName="Messenger"
          channel={channel}
          user={user}
          fetchChannels={fetchChannels}
          assistantId={assistantId}
        />
      </EnableBotForm>

      {channel?.access_token && (
        <div className={styles.msgMetadata}>
          <ChannelMetadataSettings
            toggles={[
              {
                label: "Ignore Reactions",
                checked: ignoreReactions,
                onChange: (checked) => setIgnoreReactions(checked),
                id: "ignoreReactionsMessenger",
              },
            ]}
            inputs={[
              {
                label: "Quick comment reply",
                value: quickCommentReply,
                onChange: (e) => setQuickCommentReply(e.target.value),
                id: "quickCommentReplyMessenger",
                placeholder: "Enter quick comment reply...",
              },
            ]}
            onSave={handleSaveMetadata}
            isLoading={isLoading}
          />
        </div>
      )}

      <div className={styles.connectionBot}>
        {isLoading ? (
          <div className={styles.loaderContainer}>
            <Loader height={40} width={40} />
          </div>
        ) : (
          <>
            {channel?.access_token && (
              <p className={styles.pageDescription}>
                Page: {channel.page_description}
              </p>
            )}
            {channel?.access_token ? (
              <Button
                onClick={revokeToken}
                className={styles.saveOptimize}
                title={"REVOKE TOKEN"}
              />
            ) : (
              <Button
                onClick={openMessengerAuth}
                className={styles.saveOptimize}
                title={"CONNECT MESSENGER"}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};
