import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { openInNewTab } from "../../../utils";
import { Burger, Helpdesk, Support } from "../../../assets/icons";
import logo from "../../../assets/img/logo.png";
import userAvatar from "../../../assets/img/user.png";

import styles from "./MobileNavigator.module.scss";

import { ROUTES } from "../../../constants/routes";
import { useSubscription } from "../../../pages/SubscriptionPage/useSubscription";
import { normalizeSubscriptionPlan } from "../../../helper";
import {
  navigationTabs,
  navigationTabsBottom,
} from "../../../constants/navigationMenu";

export const MobileNavigator = () => {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  const { subscriptionPlan } = useSubscription(user);
  const normalizedPlan = normalizeSubscriptionPlan(subscriptionPlan);

  const isSubscriptionActive = normalizedPlan !== null && normalizedPlan !== "";

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleBurgerMenuClick = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const getLinkClassName = (route, requiresSubscription) => {
    let className = `${styles.navigation}`;
    if (
      location.pathname === route ||
      (route !== "/" && location.pathname.startsWith(route))
    ) {
      className += ` ${styles.navigationActive}`;
    }
    if (!isSubscriptionActive && requiresSubscription) {
      className += ` ${styles.nonActive}`;
    }
    return className;
  };

  const userRole = user?.role;

  const filteredTabs = navigationTabs.filter((tab) =>
    tab.roles.includes(userRole)
  );

  const filteredTabsBottom = navigationTabsBottom.filter((tab) =>
    tab.roles.includes(userRole)
  );

  return (
    <header className={styles.mobileHeader}>
      <div className={styles.burgerMenu} onClick={handleBurgerMenuClick}>
        <Burger />
      </div>
      <div className={styles.logoContainer}>
        <img src={logo} alt="" loading={"lazy"} />
      </div>
      {isSidebarOpen && (
        <>
          <div
            className={styles.sidebarBackdrop}
            onClick={() => {
              setIsSidebarOpen(false);
            }}
          />
          <div className={styles.sidebar}>
            <div className={styles.header}>
              <img src={logo} alt="" loading={"lazy"} />
            </div>
            <div className={styles.container}>
              <div className={styles.navigationContainer}>
                <div>
                  {filteredTabs.map((tab) => (
                    <Link
                      key={tab.to}
                      to={tab.to}
                      className={getLinkClassName(tab.to, false)}
                      onClick={() => setIsSidebarOpen(false)}
                    >
                      {tab.icon}
                      {tab.label}
                    </Link>
                  ))}
                </div>
              </div>
              <div className={styles.support}>
                {filteredTabsBottom.map((tab) => (
                  <Link
                    key={tab.to}
                    to={tab.to}
                    className={getLinkClassName(tab.to, false)}
                  >
                    {tab.icon}
                    {tab.label}
                  </Link>
                ))}
                <div
                  className={styles.navigation}
                  onClick={() => {
                    openInNewTab(`https://docs.mychatbot.app/`);
                    setIsSidebarOpen(false);
                  }}
                >
                  <Support />
                  Support
                </div>
                {/* <div className={styles.navigation}>
                    <Helpdesk />
                    <a>Helpdesk</a>
                  </div> */}
              </div>
              <div className={styles.userCard}>
                {user.app_metadata.provider === "email" ? (
                  <Link
                    to={ROUTES.profilePage}
                    onClick={() => {
                      setIsSidebarOpen(false);
                    }}
                    className={`${styles.profile} ${
                      location.pathname === ROUTES.profilePage
                        ? styles.navigationActive
                        : ""
                    }`}
                  >
                    <img src={userAvatar} alt="avatar" />
                    <div className={styles.userCard__userInfo}>
                      <div className={styles.textInfo}>
                        <div className={styles.userCard__name}>
                          {user?.full_name}
                        </div>
                        <div className={styles.userCard__email}>
                          {user?.email}
                        </div>
                      </div>
                    </div>
                  </Link>
                ) : (
                  <Link
                    to={ROUTES.profilePage}
                    onClick={() => {
                      setIsSidebarOpen(false);
                    }}
                    className={`${styles.profile} ${styles.socials} ${
                      location.pathname === ROUTES.profilePage
                        ? styles.navigationActive
                        : ""
                    }`}
                  >
                    <img src={user?.user_metadata?.avatar_url} alt="avatar" />
                    <div className={styles.userCard__userInfo}>
                      <div className={styles.textInfo}>
                        <div className={styles.userCard__name}>
                          {user?.user_metadata?.full_name}
                        </div>
                        <div className={styles.userCard__email}>
                          {user?.user_metadata?.email}
                        </div>
                      </div>
                    </div>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </header>
  );
};
