import {
  GoogleSheets,
  Salesdrive,
  ddTuningCRM,
  altegio,
  keycrm,
  servio,
  bitrix,
  kommo,
  Zoho,
  Wlaunch,
} from "../assets/icons";

import { TOOL_TYPE } from "./general";

export const INTEGRATION_TYPES = {
  OAUTH: "oauth",
  API_KEY: "apikey",
  API_REFRESH_TOKEN: "api-refresh-token",
};

export const INTEGRATION_NAMES = {
  SPREADSHEET_ORDERS: "Spreadsheet Orders",
  DDTUNING_CRM: "DDTuning CRM",
  KEYCRM: "KeyCRM",
  ALTEGIO: "Altegio",
  SERVIO: "Servio HMS",
  SALES_DRIVE: "SalesDrive",
  BITRIX: "Bitrix",
  KOMMO: "Kommo",
  ZOHO: "Zoho",
  WLAUNCH: "Wlaunch",
};

export const INTEGRATIONS = [
  //{id: 1, name: 'Google Calendar', description: 'for managing appointments', icon: <GoogleCalendar/>, scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar', connected: false},
  //{id: 2, name: 'Google Sheets', description: 'for creating records and reading business information', icon: <GoogleSheets/>, scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/drive', connected: false},
  //{id: 3, name: 'Google Drive', description: 'for saving client information' , icon: <GoogleDrive/>, scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/drive', connected: false},
  //{id: 4, name: 'XML data source', description: 'for searching on business data' , icon: <XMLData/>, scope: '', connected: false},
  {
    id: 5,
    name: INTEGRATION_NAMES.SPREADSHEET_ORDERS,
    description: "for writing client orders",
    icon: <GoogleSheets />,
    scope:
      "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/drive.file",
    connected: false,
    isWorking: true,
    authType: INTEGRATION_TYPES.OAUTH,
    type: TOOL_TYPE.CRM,
  },
  {
    id: 7,
    name: INTEGRATION_NAMES.DDTUNING_CRM,
    description: "",
    icon: <img src={ddTuningCRM} alt="DDTuning CRM" />,
    scope: "",
    connected: false,
    isWorking: true,
    authType: INTEGRATION_TYPES.API_KEY,
    type: TOOL_TYPE.CRM,
  },
  {
    id: 8,
    name: INTEGRATION_NAMES.KEYCRM,
    description: "eCommerce automatization system",
    icon: <img src={keycrm} alt="KeyCRM" />,
    scope: "",
    connected: false,
    isWorking: true,
    authType: INTEGRATION_TYPES.API_KEY,
    type: TOOL_TYPE.CRM,
  },
  {
    id: 9,
    name: INTEGRATION_NAMES.ALTEGIO,
    description: "Online booking and appointment scheduling software",
    icon: <img src={altegio} alt="Altegio" />,
    scope: "",
    connected: false,
    isWorking: true,
    authType: INTEGRATION_TYPES.API_REFRESH_TOKEN,
    type: TOOL_TYPE.CRM,
  },
  {
    id: 10,
    name: INTEGRATION_NAMES.SERVIO,
    description: "Automation of hotels, hostels, boarding houses",
    icon: <img src={servio} alt="Servio" />,
    scope: "",
    connected: false,
    isWorking: true,
    authType: INTEGRATION_TYPES.API_KEY,
  },
  {
    id: 11,
    name: INTEGRATION_NAMES.SALES_DRIVE,
    description: "SalesDrive",
    icon: <Salesdrive />,
    scope: "",
    connected: false,
    isWorking: true,
    authType: INTEGRATION_TYPES.API_KEY,
    type: TOOL_TYPE.CRM,
  },
  {
    id: 12,
    name: INTEGRATION_NAMES.BITRIX,
    description: "Bitrix24",
    icon: <img src={bitrix} alt="Bitrix24" />,
    scope: "",
    connected: false,
    isWorking: true,
    authType: INTEGRATION_TYPES.API_KEY,
    type: TOOL_TYPE.CRM,
  },
  {
    id: 13,
    name: INTEGRATION_NAMES.KOMMO,
    description: "Kommo",
    icon: <img src={kommo} alt="Kommo" />,
    scope: "",
    connected: false,
    isWorking: true,
    authType: INTEGRATION_TYPES.API_KEY,
    type: TOOL_TYPE.CRM,
  },
  {
    id: 14,
    name: INTEGRATION_NAMES.ZOHO,
    description: "Zoho",
    icon: <img src={Zoho} alt="Zoho" />,
    scope: "",
    connected: false,
    isWorking: true,
    authType: INTEGRATION_TYPES.API_KEY,
    type: TOOL_TYPE.CRM,
  },
  {
    id: 15,
    name: INTEGRATION_NAMES.WLAUNCH,
    description: "Wlaunch",
    icon: <img src={Wlaunch} alt="Wlaunch" />,
    scope: "",
    connected: false,
    isWorking: true,
    authType: INTEGRATION_TYPES.API_KEY,
    type: TOOL_TYPE.CRM,
  },
];

export const REQUIRED_FIELDS = {
  [INTEGRATION_NAMES.KEYCRM]: ["apiKey", "crmPipelineId"],

  [INTEGRATION_NAMES.ALTEGIO]: [
    "partnerToken",
    "companyId",
    "chainId",
    "userAccessToken",
  ],

  [INTEGRATION_NAMES.BITRIX]: ["domain", "clientId", "clientSecret"],
  [INTEGRATION_NAMES.WLAUNCH]: ["apiKey"],
  [INTEGRATION_NAMES.KOMMO]: ["apiKey", "domain"],
  // add for another integrations here
};

export const INTEGRATION_CONFIG = {
  [INTEGRATION_NAMES.KEYCRM]: {
    fields: ["apiKey", "crmPipelineId"],
    // Field mapping function for forming requestData
    mapFields: (fields) => ({
      access_token: fields.apiKey,
      metadata: {
        crm_pipeline_id: parseInt(fields.crmPipelineId),
      },
    }),
  },
  [INTEGRATION_NAMES.ALTEGIO]: {
    fields: ["partnerToken", "companyId", "chainId", "userAccessToken"],
    mapFields: (fields) => ({
      access_token: fields.partnerToken,
      refresh_token: fields.userAccessToken,
      metadata: {
        company_id: parseInt(fields.companyId),
        chain_id: parseInt(fields.chainId),
      },
    }),
  },
  [INTEGRATION_NAMES.BITRIX]: {
    fields: ["domain", "clientId", "clientSecret"],
    mapFields: (fields) => ({
      access_token: "placeholder_access_token",
      refresh_token: "placeholder_refresh_token",
      metadata: {
        domain: fields.domain,
        client_id: fields.clientId,
        client_secret: fields.clientSecret,
      },
    }),
  },
  [INTEGRATION_NAMES.WLAUNCH]: {
    fields: ["apiKey"],
    mapFields: (fields) => ({
      access_token: fields.apiKey,
      refresh_token: "placeholder_refresh_token",
    }),
  },
  [INTEGRATION_NAMES.KOMMO]: {
    fields: ["apiKey", "domain"],
    mapFields: (fields) => ({
      access_token: fields.apiKey,
      metadata: {
        domain: fields.domain,
      },
    }),
  },

  // add for another integrations here
  default: {
    fields: ["apiKey"],
    mapFields: (fields) => ({
      access_token: fields.apiKey,
    }),
  },
};
