import React from "react";
import { Title } from "../Title";
import { Button } from "../Button";
import nooperators from "../../assets/img/nooperators.png";

import styles from "./NoOperators.module.scss";

export const NoOperators = ({
  title,
  description,
  onOperatorAdd,
  needAddOperatorBtn,
}) => {
  return (
    <div className={styles.wrapper}>
      <img src={nooperators} alt="No Operators" />
      <div>
        <Title title={title} className={styles.title} />
      </div>
      <div className={styles.description}>{description}</div>
      {needAddOperatorBtn && (
        <Button
          variant="contained"
          title={"Add operator"}
          className={styles.borderBtn}
          onClick={() => onOperatorAdd()}
        />
      )}
    </div>
  );
};
