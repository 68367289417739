export const Support = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 15.5V10.5C1 5.52944 5.02944 1.5 10 1.5V1.5C14.9706 1.5 19 5.52944 19 10.5V15.5M3.5 19.5V19.5C2.11929 19.5 1 18.3807 1 17V14C1 12.6193 2.11929 11.5 3.5 11.5V11.5C4.88071 11.5 6 12.6193 6 14V17C6 18.3807 4.88071 19.5 3.5 19.5ZM16.5 19.5V19.5C15.1193 19.5 14 18.3807 14 17V14C14 12.6193 15.1193 11.5 16.5 11.5V11.5C17.8807 11.5 19 12.6193 19 14V17C19 18.3807 17.8807 19.5 16.5 19.5Z"
        stroke="#384157"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
